import { Stack, Box } from '@mui/material'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import TableListEFaktur from './components/TableListEFaktur'



function EFakturPage() {

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="E-Faktur Page"
            />

           <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>E-Faktur Lists</h3>
                </Box> 
            </Stack>

           <Stack pt={3}>
                <TableListEFaktur/>
           </Stack>
        </Box>
    )
}

export default EFakturPage
