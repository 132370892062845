import React from 'react'; 
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../../components/TableData';

const TableItemProduct : React.FC<any> = ({ dataItem, state_invoice_name }) => {

    
    const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '60px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'Name',
            selector: row => row.name,
            width: '200px'

        },
        {
            name: 'Retail Price',
            cell: (row) => (
                <div>Rp. {row.retail_price.toLocaleString()}</div>
            )
        },
        {
            name: 'Discount',
            cell: (row) => (
                <div>{Math.floor(row.discount * 100)}%</div>
            )
        },
        {
            name: 'Final Price',
            cell: (row) => (
                <div>Rp. {row.discount_price.toLocaleString()}</div>
            )
        },
        // {
        //     name: 'Shipping Cost',
        //     cell: (row) => (
        //         <div>RP. {handleShippingCost()?.shipping_cost.toLocaleString()}</div>
        //     )
        // },
        {
            name: 'Qty',
            selector: row => row.quantity,
            width: '80px'
        },
        {
            name: 'Received',
            selector: row => row.received,
            width: '120px'
        },
        {
            name: 'Ppn',    
            selector: row => row.tax.title,
            width: '80px'
        },
        {
            name: 'Sub Total',
            cell: (row) => (
                <div>
                    <div>Rp. { row.sub_total_received.toLocaleString() }</div> 
                </div>
            )
        }
    ];

    return (
        <div>
            <DataTableBase 
                columns={columns}
                data={dataItem}
                pagination
                noDataComponent="Items is not found."
            />
        </div>
    )
}

export default TableItemProduct
