import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getDeliveryReceiptNote } from './reducers/deliveryNoteReducers';


const initialState: any = {
  delivery_note : [],
  loaded_delivery_note : false,
  loading_delivery_note : false,
};

export const getDeliveryNote = createSlice({
  name: 'delivery-note',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get data delivery note
      .addCase(getDeliveryReceiptNote.pending, (state) => {
        state.loading_delivery_note = true;
        state.loaded_delivery_note = false;
      })
      .addCase(getDeliveryReceiptNote.fulfilled, (state, action:any) => {
        state.loading_delivery_note = false;
        state.loaded_delivery_note = true
        state.delivery_note = action.payload.data;
      })
      .addCase(getDeliveryReceiptNote.rejected, (state, action : any) => {
        state.loading_delivery_note = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});

export default getDeliveryNote.reducer;
