import { useEffect, useState } from 'react';
import { 
    Button, 
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Tabs,
    Tab, 
    Typography,
    Stack,
 } from '@mui/material';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useHistory } from 'react-router-dom';
import { 
    getAllProduct, 
    uploadBulkProductUpdateAll, uploadBulkProductUpdatePrice, uploadBulkProductUpdateStock 
} from './reducers/productsReducers';
import IconButton from '@mui/material/IconButton';
import { getAllWarehouse } from '../warehouse/reducers/warehouseReducers';
import SearchData from './components/SearchData';
import swal from 'sweetalert';
import axios from 'axios';
import { userCredentials } from '../../../utilities/config';
import Select from 'react-select'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';


const download_excel = "https://mpindo.sgp1.digitaloceanspaces.com/vendor/bdd280a2-58d8-4d64-a9f6-4fb3a0c1e68bbulk-product-creation-228d660f-831e-4133-b16c-b509cc14ef15.xlsx"

const Input = styled('input')({
    display: 'none',
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) { 
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ProductPage = () => {
    const mobileDisplay = useMediaQuery('(min-width:480px)')
    const history = useHistory()
    const dispatch = useDispatch()
    const state_products = useSelector((state : RootState) => state.products)
    const state_warehouse = useSelector((state : RootState) => state.warehouse)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [optionsWarehouse, setOptionsWarehouse] = useState<any[]>([]);

    const [selectedWarehouse, setSelectedWarehouse] = useState<any>([]);

    const handleChangeWarehouse = (value: any) : void => {
        setSelectedWarehouse(value)
    }

    const [fileExcel, setFileExcel] = useState<any>(null);
    const [openUplaod, setOpenUplaod] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setFileExcel(null)
    };

    useEffect(() => {
        dispatch(getAllProduct())
        // eslint-disable-next-line
    }, []);


    const onClickUpdate = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,    
            search: `?page=edit`, 
            state : {
                product : data,
                slug : `${data.slug_product}`,
            }
        }) 
    }

    const onClickDetail = (data: any) => {
        history.push({
            pathname : `/dashboard/products`,
            search: `?page=detail`, 
            state : {
                product : data,
                slug : `${data.slug_product}`,
            }
        })
    }

    const onChangeValueFile = (e : any) => {
        setFileExcel(e.target.files)
    }

    const onClickUpdateBulkAll = (e : any) => {
        let data_send = {
            file : fileExcel,
            warehouse_id : selectedWarehouse.value
        }
        dispatch(uploadBulkProductUpdateAll(data_send))
    }

    const onClickUpdateBulkPrice = (e : any) => {
        let data_send = {
            file : fileExcel,
        }
        dispatch(uploadBulkProductUpdatePrice(data_send))
    }

    const onClickUpdateBulkStock = (e : any) => {
        let data_send = {
            file : fileExcel,
        }
        dispatch(uploadBulkProductUpdateStock(data_send))
    }

    const proceedOptionsWarehouse = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        setSelectedWarehouse(data_options[0])
        setOptionsWarehouse(data_options)
    }


    useEffect(() => {
        if(state_warehouse.data.length !== 0) {
            proceedOptionsWarehouse(state_warehouse.data)
        }
        // eslint-disable-next-line
    }, [state_warehouse.data]);

    useEffect(() => {
        if(state_products.bulk_product) {
            setOpenUplaod(false)
            setFileExcel(null)
            // dispatch(getAllProduct())
        }
        // eslint-disable-next-line
    }, [state_products.bulk_product]);

    useEffect(() => {
        if(state_products.bulk_product_update) {
            setOpenUplaod(false)
            setFileExcel(null)
            // dispatch(getAllProduct())
        }
        // eslint-disable-next-line
    }, [state_products.bulk_product_update]);

    useEffect(() => {
        if(state_products.bulk_update_price) {
            setOpenUplaod(false)
            setFileExcel(null)
            // dispatch(getAllProduct())
        }
        // eslint-disable-next-line
    }, [state_products.bulk_update_price]);

    useEffect(() => {
        if(state_products.bulk_update_stock) {
            setOpenUplaod(false)
            setFileExcel(null)
            // dispatch(getAllProduct())
        }
        // eslint-disable-next-line
    }, [state_products.bulk_update_stock]);

    useEffect(() => {
        dispatch(getAllWarehouse())
        // eslint-disable-next-line
    }, []);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [loading, setLoading] = useState(false);
    const [typeDownload, setTypeDownload] = useState("");

    const downloadBulkTemplate = async (type : string) => {
        setLoading(true)
        setTypeDownload(type)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/download/excel/${type}/${userCredentials.vendor_id}`, {
                headers: {
                    'Authorization' : `Bearer ${userCredentials.id_token}`
                }
            })
            if(response.data.errors === null) { 
                window.location.href = `${response.data.data}`
                setLoading(false)
            } else {
                swal("Error", `${response.data.messageor}`, 'error')
                setLoading(false)
            }
        } catch (error) {
            swal("Error", `${error}`, 'error')
            setLoading(false)
        }
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
       
        {
            name: 'PRICE',
            cell: (row) => (
                <p>Rp. {row.retail_price.toLocaleString()}</p>
            )
        },
        {
            name: 'DISCOUNT',
            selector: row => row.discount,
            cell: (row) => (
                <p>{row.discount * 100}%</p>
            )
        },
        {
            name: 'PRICE AFTER DISCOUNT',
            cell: (row) => (
                <p>Rp. {row.discount_price.toLocaleString()}</p>
            )
        },
        {
            name: 'STOCK',
            selector: row => row.stock,
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => onClickUpdate(row)}
                    >
                        Edit
                    </Button>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => onClickDetail(row)}
                    >
                        Detail
                    </Button>
                </Stack>
            ),
        },
    ];
    

    return (
        <Box sx={mobileDisplay?{pt:2, pl:3, pr:3} : {pt:0, pl:1, pr:1}}>
            <Box display='flex' flexDirection='column'>
                { mobileDisplay?
                <BreadCrumbs
                    isPage={false}
                    current="Product Page"
                />
                :
                <SearchData
                    data={state_products.data}
                    progressPending={state_products?.loading} 
                />
                }
            </Box>
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Stack flexDirection="row" alignItems="center">
                    <Button 
                        variant="outlined" color="success" 
                        size="small"
                        onClick={handleClick}
                        sx={mobileDisplay? {fontSize:undefined}: { fontSize:'12px'}}
                    >
                        Download Excel Template
                    </Button>
                    <>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{ mt: 2 }}
                            className="menu-pop-over"
                        >
                            <div className="box-menu">
                                <Box pt={1} pb={1}>
                                    <Box>
                                        <a href={download_excel}>
                                            <Box pb={1}>
                                                <Button
                                                    variant="contained"
                                                    color="success" 
                                                    size="small"
                                                    fullWidth
                                                >Product Bulk Creation</Button>
                                            </Box>
                                        </a>
                                    </Box>
                                    <Box>
                                        <Box pb={1}>
                                            <Button
                                                variant="contained"
                                                color="success" 
                                                size="small"
                                                fullWidth
                                                onClick={() => downloadBulkTemplate('all')}
                                                disabled={loading && typeDownload === "all" ? true : false}
                                            > { loading && typeDownload === "all" ? "Loading.." : "Product Bulk Update All" }</Button>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box pb={1}>
                                            <Button
                                                variant="contained"
                                                color="success" 
                                                size="small"
                                                fullWidth
                                                onClick={() => downloadBulkTemplate('stock')}
                                                disabled={loading && typeDownload === "stock" ? true : false}
                                            > { loading && typeDownload === "stock" ? "Loading.." : "Product Bulk Stock" }</Button>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box pb={1}>
                                            <Button
                                                variant="contained"
                                                color="success" 
                                                size="small"
                                                onClick={() => downloadBulkTemplate('price')}
                                                disabled={loading && typeDownload === "price" ? true : false}
                                                fullWidth
                                            > { loading && typeDownload === "price" ? "Loading.." : "Product Bulk Price" } </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </Popover>
                    </>
                    <Box mr={1} />
                    <Button 
                        variant="contained" color="success" 
                        onClick={() => setOpenUplaod(true)}
                        size="small"
                        sx={mobileDisplay?{ fontSize:undefined} : { fontSize:'12px'}}
                    >
                        Bulk Upload
                    </Button>
                    <Box mr={1} />
                    <Button 
                        variant="contained" color="primary"  
                        size="small"
                        sx={mobileDisplay?{ fontSize:undefined} : { fontSize:'12px'}}
                        onClick={() => {
                            history.push({
                                pathname: "/dashboard/products",
                                search: `?page=create`, 
                            })
                        }}
                    >
                        Add Single Product
                    </Button>
                </Stack>
            </Stack>
            { mobileDisplay?
            <Box pt={2}>
                <SearchData
                    data={state_products.data}
                    progressPending={state_products?.loading} 
                />
            </Box>
            :
            <Stack mt={3}>
                    <Typography variant='h6'>
                    Product List
                    </Typography>
            </Stack>
            }

            <Box sx={{pt:2}}>
                { mobileDisplay?
                <DataTableBase 
                    columns={columns}
                    data={state_products.data}
                    progressPending={state_products?.loading} 
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Product Not Found"
                />
                : null
                // <TableDataMobile
                // data={state_products.data}
                // onClickUpdate={onClickUpdate}
                // onClickDetail={onClickDetail}
                // />
                 }
            </Box>
 
            <Dialog open={openUplaod} maxWidth="lg" fullWidth={true}  >
                <DialogTitle>Please Upload Excel File below!</DialogTitle>
                <DialogContent>
                    <Stack>
                    <Box sx={{ width: '100%', minHeight: 400 }}>
                        { mobileDisplay?
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', fontSize: 13 }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="For Creation" {...a11yProps(0)} />
                            <Tab label="For Update Price" {...a11yProps(1)} />
                            <Tab label="For Update Stock" {...a11yProps(2)} />
                            <Tab label="For Update All" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        :
                        <Box sx={{ minWidth: '100%', bgcolor: 'background.paper', fontSize: 13 }}>
                                <Tabs 
                                    value={value} 
                                    onChange={handleChange} 
                                    variant="scrollable" 
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile 
                                    selectionFollowsFocus
                                    >
                                    <Tab label="For Creation" {...a11yProps(0)} />
                                    <Tab label="For Update Price" {...a11yProps(1)} />
                                    <Tab label="For Update Stock" {...a11yProps(2)} />
                                    <Tab label="For Update All" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                        }
                        <TabPanel value={value} index={0}>
                            <Box pb={2}>
                                <Box pb={1}>Select below to change Warehouse</Box>
                                <Select
                                    placeholder="Select Warehouse"
                                    value={selectedWarehouse}
                                    options={optionsWarehouse}
                                    onChange={handleChangeWarehouse}
                                    id={`select-style-searchby`}
                                />
                            </Box>
                            <Box sx={mobileDisplay? { display:undefined, justifyContent:undefined} : { display:'flex', justifyContent:'center'}}>
                                { fileExcel === null ? 
                                <label htmlFor="icon-button-file" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                    <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={(e) => onChangeValueFile(e)} />
                                    <IconButton size='small' color="primary" aria-label="upload picture" component='span' sx={mobileDisplay? {fontSize:undefined}:{ fontSize:'13'}}>
                                        Upload Excel Creation
                                    </IconButton>
                                </label> : 
                                <Stack>
                                    <Box> {fileExcel[0].name}</Box> 
                                    <Box onClick={() => setFileExcel(null)} sx={{ cursor: 'pointer', color : 'red' }}>Change </Box>
                                </Stack> }
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            { fileExcel === null ? 
                                <label htmlFor="icon-button-file" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                    <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={(e) => onChangeValueFile(e)} />
                                    { mobileDisplay?
                                    <IconButton size='small' color="primary" aria-label="upload picture" component="span">
                                    Upload Excel Price
                                    </IconButton>
                                    :
                                    <Button size='small' color="primary" aria-label="upload picture" variant='outlined' sx={{ marginTop:'50px', dsiplay:'flex', justifyContent:'center'}}>
                                        Upload Excel Price
                                    </Button>
                                    }
                                </label> : 
                                <Stack>
                                    <Box> {fileExcel[0].name}</Box> 
                                    <Box onClick={() => setFileExcel(null)} sx={{ cursor: 'pointer', color : 'red' }}>Change </Box>
                                </Stack> }
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box sx={mobileDisplay? {display:undefined, justifyContent:undefined}:{display:'flex', justifyContent:'center'}}>
                                { fileExcel === null ? 
                                <label htmlFor="icon-button-file" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                    <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={(e) => onChangeValueFile(e)} />
                                    <IconButton size='small' color="primary" aria-label="upload picture" component="span">
                                    <p>Upload Excel Stock</p>
                                    </IconButton>
                                </label> : 
                                <Stack>
                                    <Box> {fileExcel[0].name}</Box> 
                                    <Box onClick={() => setFileExcel(null)} sx={{ cursor: 'pointer', color : 'red' }}>Change </Box>
                                </Stack> }
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Box sx={mobileDisplay? {display:undefined, justifyContent:undefined}:{display:'flex', justifyContent:'center'}}>
                                { fileExcel === null ? 
                                <label htmlFor="icon-button-file" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                    <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={(e) => onChangeValueFile(e)} />
                                    <IconButton size='small' color="primary" aria-label="upload picture" component="span">
                                    Upload Excel All
                                    </IconButton>
                                </label> : 
                                <Stack>
                                    <Box> {fileExcel[0].name}</Box> 
                                    <Box onClick={() => setFileExcel(null)} sx={{ cursor: 'pointer', color : 'red' }}>Change </Box>
                                </Stack> }
                            </Box>
                        </TabPanel>
                    </Box>
                    
                    </Stack>
                </DialogContent>
                { value === 0 ? 
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenUplaod(false)
                            setFileExcel(null)
                        }} 
                        color="error" variant='outlined' 
                        disabled={state_products.loading_bulk_product_update}
                    >
                        Cancel
                    </Button>
                    
                    <Button onClick={onClickUpdateBulkAll} color="primary" variant='contained' disabled={fileExcel === null ? true : false}>
                        { state_products.loading_bulk_product_update ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Submit" } 
                    </Button>
                </DialogActions>
                : value === 2 ? 
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenUplaod(false)
                            setFileExcel(null)
                        }} 
                        color="error" variant='outlined' 
                        disabled={state_products.loading_bulk_update_stock}
                    >
                        Cancel
                    </Button>
                    
                    <Button onClick={onClickUpdateBulkStock} color="primary" variant='contained' disabled={fileExcel === null ? true : false}>
                        { state_products.loading_bulk_update_stock ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Update" } 
                    </Button>
                </DialogActions>
                : value === 3 ? 
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenUplaod(false)
                            setFileExcel(null)
                        }} 
                        color="error" variant='outlined' 
                        disabled={state_products.loading_bulk_product_update}
                    >
                        Cancel
                    </Button>
                    
                    <Button onClick={onClickUpdateBulkAll} color="primary" variant='contained' disabled={fileExcel === null ? true : false}>
                        { state_products.loading_bulk_product_update ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Update" } 
                    </Button>
                </DialogActions>
                :
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenUplaod(false)
                            setFileExcel(null)
                        }} 
                        color="error" variant='outlined' 
                        disabled={state_products.loading_bulk_update_price}
                    >
                        Cancel
                    </Button>
                    <Button onClick={onClickUpdateBulkPrice} color="primary" variant='contained' disabled={fileExcel === null ? true : false}>
                        { state_products.loading_bulk_update_price ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Update" } 
                    </Button> 
                </DialogActions> }
            </Dialog>
        </Box>
    )
}

export default ProductPage
