import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    Dialog,
    Button,
} from '@mui/material';
import { CSSProperties, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import  useMediaQuery  from '@mui/material/useMediaQuery'


const ProductDetail : React.FC<any> = ({ data }) => {
    const mobileDisplay = useMediaQuery('(min-width:480px)')
    const [detailImage, setDetailImage] = useState<any>({
        open : false,
        img : ""
    });
    
    const mobileImageStyle:CSSProperties = {
            width: '100px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            padding: '2px',
            cursor: 'pointer',
            marginRight: '5px'
    }

    const desktopImageStyle:CSSProperties = {
        width: '100px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        padding: '2px',
        cursor: 'pointer',
        marginRight: '5px'
}

    return (
        <Box>
                <Stack mt={4} mb={8}>
                    <Stack mb={2}>
                        <Paper elevation={2}>
                            <Stack p={4}>
                                <Box pb={2}><Typography variant="h6" fontWeight="600">Product Images</Typography></Box>
                                <Grid container spacing={4}>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Images</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        { data && data.images_product.map((itm:any, idx:any) => (
                                            <Box 
                                                key={idx}
                                                component="img"
                                                src={itm}
                                                sx={mobileDisplay? desktopImageStyle : mobileImageStyle}
                                                onClick={() => setDetailImage({...detailImage, open : true, img : itm})}
                                            />
                                        )) }
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Paper> 
                    </Stack>
                    <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                        <Paper elevation={2}>
                            <Stack p={4}>
                                <Box pb={2}><Typography variant="h6" fontWeight="600">Product Information</Typography></Box>
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Name</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.name}</Box>
                                    </Grid>
                                    
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">SKU Product</Typography>
                                    </Grid> 
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.SKU}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Category</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        { data.category_tree.length === 0 ? "-" : 
                                            <Box sx={{ display : 'flex' }}>
                                                { data.category_tree.map((item:any, i:any) => 
                                                    <Box sx={{ display : 'flex' }} key={i}>
                                                    <Box>
                                                        {item.name}
                                                    </Box> 
                                                    { i !== data.category_tree.length - 1 ? 
                                                        <Box pl={1} pr={1}>{">"}</Box>
                                                    : null }
                                                    </Box>
                                                )}
                                            </Box>
                                        }
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Brand</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.brand}</Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Paper> 
                    </Stack>
                    <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                        <Paper elevation={2}>
                            <Stack p={mobileDisplay? 4 : 2}>
                                <Box pb={2}><Typography variant={mobileDisplay? 'h6' : 'body1'} align={mobileDisplay? undefined : 'center'} fontWeight="600">Product Detail</Typography></Box>
                                { mobileDisplay?
                                <Grid container columnSpacing={3} rowSpacing={2}>
                                    
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Price</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>Rp. {data.retail_price.toLocaleString()}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Stock</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.stock}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Discount</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.discount * 100}%</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Minimum Order</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.minimum_order_quantity}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Tax</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.pajak.title}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Dimension</Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Width : {data.dimension.width}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Length : {data.dimension.length}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Heigh : {data.dimension.height}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Weight : {data.dimension.weight}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Warehouse</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.warehouse_detail.name}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Storage</Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Rack : {data.storage.rack}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Bin : {data.storage.bin}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}>
                                        <Box>Level : {data.storage.level}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}/>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Payment Terms</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.payment_term.name}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Product Country</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.country ? data.country : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Product Size</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.size ? data.size : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Product Volume</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.volume ? data.volume : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Special Handling</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.special_handling ? data.special_handling : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Short Description</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.short_description}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500">Long Description</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box> 
                                            { ReactHtmlParser(data?.description) }
                                        </Box>
                                    </Grid>
                                
                                    
                                </Grid>
                                :
                                <Grid container columnSpacing={3} rowSpacing={1} fontSize="12px"> 
                                    
                                    <Grid item xl={3} lg={4} xs={12} display='flex' justifyContent='space-between'>
                                        <Typography variant="body2" fontWeight="500">Price</Typography>
                                        <Box>Rp. {data.retail_price.toLocaleString()}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Stock</Typography>
                                        <Box>{data.stock}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} mt={1} display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant="body2" fontWeight="500">Discount</Typography>
                                        <Box>{data.discount * 100}%</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} mt={1} display='flex' justifyContent='space-between'>
                                        <Typography variant="body2" fontWeight="500">Minimum Order</Typography>
                                        <Box>{data.minimum_order_quantity}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} mt={1} display='flex' justifyContent='space-between'>
                                        <Typography variant="body2" fontWeight="500">Tax</Typography>
                                        <Box>{data.pajak.title}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} mt={1}>
                                        <Typography variant="body2" fontWeight="500">Dimension</Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12} display="flex" flexDirection="row" justifyContent="space-around">
                                        <Box>Width : {data.dimension.width}</Box>
                                        <Box>Length : {data.dimension.length}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12} display="flex" flexDirection="row" justifyContent="space-around">
                                        <Box>Heigh : {data.dimension.height}</Box>
                                        <Box>Weight : {data.dimension.weight}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} mt={1} display="flex" justifyContent="space-between" alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Warehouse</Typography>
                                        <Box>{data.warehouse_detail.name}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Storage</Typography>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12} display="flex" flexDirection="row" justifyContent="space-around">
                                        <Box>Rack : {data.storage.rack}</Box>
                                        <Box>Bin : {data.storage.bin}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12} display="flex" flexDirection="row" ml={5}>
                                        <Box>Level : {data.storage.level}</Box>
                                    </Grid>
                                    <Grid item xl={2} lg={2} xs={12}/>
                                    <Grid item xl={3} lg={4} xs={12} display="flex" justifyContent="space-between" alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Payment Terms</Typography>
                                        <Box>{data.payment_term.name}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} display="flex" justifyContent="space-between" alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Product Country</Typography>
                                        <Box>{data.country ? data.country : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} display="flex" justifyContent="space-between" alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Product Size</Typography>
                                        <Box>{data.size ? data.size : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} display="flex" justifyContent="space-between" alignItems='center'>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Product Volume</Typography>
                                        <Box>{data.volume ? data.volume : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12} display="flex" justifyContent="space-between" alignItems='center' >
                                        <Typography variant="body2" fontWeight="500" mt={1}>Special Handling</Typography>
                                        <Box>{data.special_handling ? data.special_handling : "-"}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Short Description</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>{data.short_description}</Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Typography variant="body2" fontWeight="500" mt={1}>Long Description</Typography>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box> 
                                            { ReactHtmlParser(data?.description) }
                                        </Box>
                                    </Grid>
                                </Grid>
                                }
                            </Stack>
                        </Paper> 
                    </Stack>
                </Stack> 
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={detailImage.open}
                    onClose={() => setDetailImage({...detailImage, open : false, img : ""})}
                >
                    { detailImage.img === "" ? null : 
                    <Box p={3}>
                        <img 
                            id="img-review-Image" 
                            alt="detail gambar" 
                            src={detailImage.img} 
                            width="100%" height="100%" 
                        />
                        <Box pt={2} pb={1} />
                        <Grid container flexDirection="row" justifyContent="center" >
                            <Box pr={1} pl={1}/>
                            <Button 
                                variant='contained' color="primary" 
                                onClick={() => setDetailImage({...detailImage, open : false, img : ""})}
                            >
                                OK
                            </Button>
                        </Grid>
                    </Box> }
                </Dialog>
        </Box>
    )
}

export default ProductDetail;
