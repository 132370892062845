import { Stack, Box, Grid, Button, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { RootState } from '../../../app/store';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { userCredentials } from '../../../utilities/config';
import { getDataSetting, getTokenByVendor, postTokenByVendor, updateSettingPlatform, updateTokenByVendor } from './reducers/settingReducers';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Select from 'react-select'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
        <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
        </Box>
        )}
    </div>
    );
}

function a11yProps(index: number) {
    return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
    
function SettingPage() {

    const dispatch = useDispatch()
    const store_setting = useSelector((state : RootState) => state.setting)

    const [update, setUpdate] = useState(false);
    const [updateToken, setUpdateToken] = useState(false);
    const [dataExist, setDataExist] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    
    const [initialSave, setInitialSave] = useState(false);
    const [initialToken, setInitialToken] = useState(false);
    const [valueToken, setValueToken] = useState("");
    const [valueIDToken, setValueIDToken] = useState("");

    const [copyTrue, setCopyTrue] = useState(false);

    const [dataSetting, setDataSetting] = useState({
        discount: "",
        payment_term : "",
        down_payment : ""
    });

    const optionsPackage = [
        { value: false, label: "Free Package" },
        { value: true, label: "Team Package" }
    ]
    const [selectedPackage, setSelectedPackage] = useState<any>([]);

    const handleChangePackage = (value: any) : void => {
       setSelectedPackage(value)
       setInitialToken(true)
       if(dataExist) {
           setUpdateToken(true)
       }
   }


    const onChangeValue = (e : any) => {
        const { name, value } = e.target
        let value_number = value === "" ? 0 : parseInt(value)
        if(name === "discount" && value_number < 101) {
            setDataSetting({...dataSetting, 
                [name] : value_number
            })
        } else if (name === "payment_term") {
            setDataSetting({...dataSetting, 
                [name] : value_number
            })
        } else if (name === "down_payment" && value_number < 101) {
            setDataSetting({...dataSetting, 
                [name] : value_number
            })
        }
        setInitialSave(true)
        
    }

    const onClickSave = (e : any) => {
        e.preventDefault()
        let send_data = {
            body : {
                vendor_id : userCredentials.vendor_id,
                setting : {
                    discount: dataSetting.discount,
                    payment_term : {
                        name : `${dataSetting.payment_term} Days`,
                        value : dataSetting.payment_term
                    },
                    down_payment : dataSetting.down_payment
                }
            },
            update : update
        }
        dispatch(updateSettingPlatform(send_data))
    }

    const onClickSaveToken = (e : any) => {
        e.preventDefault()
        let send_data = {
            companyId : userCredentials.vendor_id,
            token : valueToken,
            options: {
                system : selectedPackage.value,
                title: selectedPackage.label,
                description: `Online Pajak ${selectedPackage.label}`,
            }
        }
        let update_data = {
            id : valueIDToken,
            token : valueToken,
            options: {
                system : selectedPackage.value,
                title: selectedPackage.label,
                description: `Online Pajak ${selectedPackage.label}`,
            }
        }
        if(updateToken) {
            dispatch(updateTokenByVendor(update_data))
        } else {
            dispatch(postTokenByVendor(send_data))
        }
    } 


    useEffect(() => {
        dispatch(getDataSetting())
        dispatch(getTokenByVendor())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!store_setting.loading) {
            if(store_setting.data !== null) {
                setUpdate(true)
                setDataSetting({...dataSetting, 
                    discount: store_setting.data.setting[0].discount,
                    payment_term : store_setting.data.setting[0].payment_term.value,
                    down_payment : store_setting.data.setting[0].down_payment
                })
            }
        }
        // eslint-disable-next-line
    }, [store_setting.loading]);

    useEffect(() => {
        if(store_setting.token_loaded) {
            if(store_setting.token.length !== 0) {
                setUpdateToken(true)
                setValueToken(store_setting.token[0].token)
                setValueIDToken(store_setting.token[0]._id)
                setSelectedPackage({ value : store_setting.token[0].options[0].system, label : store_setting.token[0].options[0].title })
                setDataExist(true)
            } else {
                setSelectedPackage({ value : false, label : "Free Package" })
            }
        }
        // eslint-disable-next-line
    }, [store_setting.token_loaded]);

    useEffect(() => {
        if(store_setting.update) {
            swal("Success", "Success Update Setting Platform", 'success')
            dispatch(getDataSetting())
        }
        // eslint-disable-next-line
    }, [store_setting.update]);

    useEffect(() => {
        if(store_setting.update_token) {
            setTimeout(() => {
                dispatch(getTokenByVendor())
                setInitialToken(false)
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_setting.update_token]);

    useEffect(() => {
        if(store_setting.post_token) {
            setTimeout(() => {
                dispatch(getTokenByVendor())
                setInitialToken(false)
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_setting.post_token]);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Setting Page"
            />
           <Box sx={{pt:2}}>
                <h3>Setting Platform</h3>
           </Box>

            <Box sx={{ width: '100%', mt:2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Setting Platform" {...a11yProps(0)} />
                        <Tab label="Setting Third Party" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                        <form onSubmit={onClickSave}>
                            <Box>
                                <Box>
                                    <Grid container >
                                        <Grid item xl={3} lg={3} xs={6}>
                                            <Box pt={2}><h4>Default Discount</h4></Box>
                                        </Grid>
                                        <Grid item xl={4} lg={4} xs={6}>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                size="small"
                                                label='Type Discount in %'
                                                type="text"
                                                onChange={onChangeValue}
                                                value={dataSetting.discount}
                                                name="discount"
                                                required
                                            />
                                            {/* <Box pt={2}><h4>10%</h4></Box> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid item xl={3} lg={3} xs={6}>
                                            <Box pt={2}><h4>Default Payment Terms</h4></Box>
                                        </Grid>
                                        <Grid item xl={4} lg={4} xs={6}>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                size="small"
                                                label='Type Payment Term in days'
                                                type="text"
                                                onChange={onChangeValue}
                                                value={dataSetting.payment_term}
                                                name="payment_term"
                                                required
                                            />
                                            {/* <Box pt={2}><h4>-</h4></Box> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid item xl={3} lg={3} xs={6}>
                                            <Box pt={2}><h4>Default Down Payment</h4></Box>
                                        </Grid>
                                        <Grid item xl={4} lg={4} xs={6}>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                size="small"
                                                label='Type Down Payment in %'
                                                type="text"
                                                onChange={onChangeValue}
                                                value={dataSetting.down_payment}
                                                name="down_payment"
                                                required
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container >
                                        <Grid item xl={3} lg={3} xs={6}>
                                            <Box pt={2}><h4>Direct Payment</h4></Box>
                                        </Grid>
                                        <Grid item xl={4} lg={4} xs={6}>
                                            <Box 
                                                pt={2} sx={{cursor:'pointer'}} 
                                                onClick={() => swal('Warning',  `Please contact our Admin if you want to ${!userCredentials.company_detail.direct_payment ? "Enable" : "Disable"} direct payment method!`, "warning")}
                                            >
                                                <Grid container flexDirection="row">
                                                    <Box pr={1}>
                                                        { userCredentials.company_detail.direct_payment ?
                                                        <CheckCircleIcon/> :
                                                        <RemoveCircleIcon/> 
                                                        }
                                                    </Box>
                                                    <Box>{userCredentials.company_detail.direct_payment ? "Enable" : "Disable"} </Box>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={2}>
                                    <Button 
                                        variant="contained"
                                        disabled={initialSave ? false : true}
                                        type="submit"
                                    >
                                        { store_setting.loading_update ? "Loading.." : "Save Change" }
                                    </Button>
                                </Box> 
                            </Box>
                        </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                        <form onSubmit={onClickSaveToken}>
                            <Box>
                                {/* <Grid container >
                                    <Grid item xl={3} lg={3} xs={6}>
                                        <Box pt={2}><h4>Token</h4></Box>
                                    </Grid>
                                    <Grid item xl={4} lg={4} xs={6}>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            size="small"
                                            label='Token'
                                            type="text"
                                            onChange={(e) => {
                                                setInitialToken(true)
                                                setValueToken(e.target.value)
                                            }}
                                            value={valueToken}
                                            name="discount"
                                            required
                                        />
                                    </Grid>
                                </Grid> */}

                                <Grid container >
                                    <Grid item xl={3} lg={3} xs={6}>
                                        <Box pt={2}><h4>Online Pajak Package</h4></Box>
                                    </Grid>
                                    <Grid item xl={4} lg={4} xs={6}>
                                        <Box pt={1}>
                                        <Select
                                            placeholder="Select Package"
                                            value={selectedPackage}
                                            options={optionsPackage}
                                            onChange={handleChangePackage}
                                            id="select-style-cat"
                                        />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item xl={3} lg={3} xs={6}>
                                        <Box pt={2}><h4>Online Pajak Token</h4></Box>
                                    </Grid>
                                    <Grid item xl={4} lg={4} xs={6}>
                                        <Box pt={1}>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            size="small"
                                            label='Token'
                                            type="text"
                                            onChange={(e) => {
                                                setInitialToken(true)
                                                setValueToken(e.target.value)
                                            }}
                                            value={valueToken}
                                            name="discount"
                                            required
                                        />
                                        </Box>

                                    </Grid>
                                </Grid>
                                { valueIDToken === "" ? null : 
                                <Grid container >
                                    <Grid item xl={3} lg={3} xs={6}>
                                        <Box pt={2}><h4>URL WEBHOOK</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={4} xs={6}>
                                        <Stack flexDirection="row"  alignItems="center" pt={2}>
                                            <Box>
                                                <Box> {`${process.env.REACT_APP_API_SERVER}/tax-invoice/web-hook/vendor/${userCredentials.vendor_id}`}</Box>
                                            </Box>
                                            <Box pl={2}>
                                                <CopyToClipboard 
                                                    text={`${process.env.REACT_APP_API_SERVER}/tax-invoice/web-hook/vendor/${userCredentials.vendor_id}`}
                                                    onCopy={() => {
                                                        setCopyTrue(true)
                                                        setTimeout(() => {
                                                            setCopyTrue(false)
                                                        }, 2000);
                                                    }}
                                                >
                                                   <Box
                                                    sx={{ 
                                                        cursor: 'pointer', 
                                                        color: copyTrue ? 'green' : 'black'
                                                    }}
                                                   >
                                                        <FileCopyIcon />
                                                    </Box>
                                                </CopyToClipboard>
                                            </Box>
                                        </Stack>
                                        { copyTrue ? <Box sx={{color: 'green', pt: 1}}>Url Webhook Copied</Box> : null }
                                    </Grid>
                                </Grid> }
                            </Box>
                            <Box mt={3}>
                                { !updateToken ?  
                                <Button 
                                    variant="contained"
                                    disabled={initialToken ? false : true}
                                    type="submit"
                                >
                                    { store_setting.loading_post_token ? "Loading.." : "Submit Token" }
                                </Button>
                                : 
                                <Button 
                                    variant="contained"
                                    disabled={initialToken ? false : true}
                                    type="submit"
                                >
                                    { store_setting.loading_update_token ? "Loading.." : "Edit Token" }
                                </Button> }
                            </Box> 
                        </form>
                </TabPanel>
            </Box>

            
        </Box>
    )
}

export default SettingPage
