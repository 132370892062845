import { Box, Skeleton } from '@mui/material';
import { Line } from 'react-chartjs-2';

const SalesValues : React.FC<any> = ({ data_charts }) => {

    const data = {
        labels: data_charts.charts_sales.labels,
        datasets: [
            {
                label : "",
                data: data_charts.charts_sales.data,
                backgroundColor: 'rgb(0,145,214)',
                borderColor: 'rgb(0,145,214)',
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'Sales Value',
          },
        },
        scales: {
          yAxis: {
            min: 0,
          },
        },
      };

    return (
        <Box sx={{p:2}}>
          { data_charts.loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
            <div id="chart">
                <Line options={options} data={data} height={350}  />
            </div>
          }
        </Box>
    )
}

export default SalesValues
