import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features_app/auth/login/loginSlice';
import registerReducer from '../features_app/auth/register/registerSlice';
import stepFormReducer from '../features_app/dashboard/step_register/components/stepFormSlice';
import forgotReducer from '../features_app/auth/forgot/forgotSlice';
import purchaseOrdersReducer from '../features_app/dashboard/orders/purchaseOrdersSlice'
import stepRegisterReducer from '../features_app/dashboard/step_register/stepRegisterSlice'
import teamsReducers from '../features_app/dashboard/teams/teamsSlice'
import getProductsReducer from '../features_app/dashboard/products/productsSlice'
import getProfileReducer from '../features_app/dashboard/profile/profileSlice'
import getBuyerListsReducer from '../features_app/dashboard/buyer_lists/buyerListsSlice'
import getSettingCompany from '../features_app/dashboard/setting/settingSlice'
import getWarehouseReducer from '../features_app/dashboard/warehouse/warehouseSlice'
import getInvoiceReducer from '../features_app/dashboard/finance/invoice/invoiceSlice'
import customRolesReducer from '../features_app/dashboard/custom_role/customRolesSlice'
import homeDashboardReducer from '../features_app/dashboard/home/homeDashboardSlice'
import getAllVendorrReducers from '../features_app/dashboard/select_company/selectCompanySlice'
import getAllDeliveryNoteReducers from '../features_app/dashboard/delivery_note/deliveryNoteSlice'
import getDataEFakturReducers from '../features_app/dashboard/finance/efaktur/eFakturSlice'
import chatReducers from '../features_app/dashboard/chat/chatSlice'
import notificationsReducers from '../features_app/dashboard/home/notification/notifiicationSlice'
import chatReportsReducers from '../features_app/dashboard/reports/charts/chartsSlice'
import invitationReducers from '../features_app/dashboard/invitation/invitationSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    forgot : forgotReducer,
    register: registerReducer,
    step_state : stepFormReducer,
    purchase_orders : purchaseOrdersReducer,
    step_register : stepRegisterReducer,
    users_team : teamsReducers,
    products : getProductsReducer,
    profile : getProfileReducer,
    buyer_lists : getBuyerListsReducer,
    setting: getSettingCompany,
    warehouse: getWarehouseReducer,
    invoices: getInvoiceReducer,
    custom_roles : customRolesReducer,
    dashboard : homeDashboardReducer,
    all_vendor : getAllVendorrReducers,
    delivery_note : getAllDeliveryNoteReducers,
    efaktur : getDataEFakturReducers,
    chat : chatReducers,
    notification : notificationsReducers,
    chart_report : chatReportsReducers,
    invitation : invitationReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
