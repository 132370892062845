import React from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Stack
} from '@mui/material';
import { useHistory } from 'react-router-dom';


const styleTableRow = () => {
  return {fontSize: '12px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const TableItem : React.FC<any> = ({ data, status, arrData}) => {
  const history = useHistory()  
  function backgroundColorStyle (row : any) {

    if((status === "Pick" && row.statuses && row.statuses.length === 0) || row.lastStatus === "Approve") { //fix
      return "#1bc5bd"
    } else if((status === "Shipped" && row.statuses && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if((status === "Delivered" && row.statuses && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if((status === "Paid" && row.statuses && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if (status === "Pick" && row.statuses && row.lastStatus === "Waiting Approval") {
      return "#ffc866"
    } else if (status === "Cancel") {
      return "#ddd"
    } else {
      return ""
    }
  }

  function sumSubPrice (data:any) {    
    const totalPriceVendor = data.reduce((total : any, data : any) => {
      return status === 'Delivered'? total + data.sub_total_received : total + data.sub_total
    }, 0)
    return totalPriceVendor;
  }

  function subTotalTax(data:any){
    const totalPriceVendor = data.reduce((total : any, data : any) => {
      return total + data.sub_total_tax
    }, 0)
    return totalPriceVendor;
  }
  
  return (
    <Box>
        <TableContainer component={Paper} >
          <Table aria-label="simple table" > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700, width: "60px"}} sx={styleTableRow()}>No</TableCell>
                    <TableCell style={{fontWeight: 700, }} sx={styleTableRow()}>Name</TableCell>
                    <TableCell style={{fontWeight: 700,}} sx={styleTableRow()}>Retail Price</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Disc%</TableCell>
                    <TableCell style={{fontWeight: 700, }} sx={styleTableRow()}>Final Price</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Ppn</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Qty</TableCell>
                    { status === "Delivered" ?  
                    <>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Received</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Note</TableCell>
                    </>
                    : null }
                    <TableCell style={{fontWeight: 700,}} sx={styleTableRow()}>{ status === "Delivered" ? "Sub Total Received" : "Sub Total" }</TableCell>
                    { status === "Pick" ? 
                    <>
                      <TableCell width="150px" sx={styleTableRow()}>Note</TableCell> 
                      <TableCell width="150px" sx={styleTableRow()}>Status</TableCell> 
                    </> 
                    : 
                    <>
                      <TableCell></TableCell> 
                      <TableCell></TableCell> 
                    </> 
                    }
                </TableRow>
              </TableHead>
              { data?.map((row :any, i : any) => (
              <TableBody 
                style={{ backgroundColor: `${backgroundColorStyle(row)}`}}
                key={i}
              >
                <TableRow sx={styleTableColumn()}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    <Box 
                      sx={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        history.push({
                          pathname : `/dashboard/products`,
                          search: `?page=detail`, 
                          state : {
                              product : row,
                              slug : `${row.slug}`,
                          }
                        })
                      }}
                    >
                      {row.name}
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    { status === "Pick" && row.retail_price !== row.retail_price_original ? 
                      <Box sx={{ textDecoration: 'line-through', color: '#0a449b' }}>
                        Rp. {row.retail_price_original.toLocaleString()}
                      </Box> 
                    : null }
                    RP. {row.retail_price.toLocaleString()} <br/>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()} >
                      {Math.floor(row.discount * 100)}%
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    { status === "Pick" && row.discount_price !== row.discount_price_original ? 
                      <Box sx={{ textDecoration: 'line-through', color: '#0a449b' }}>
                        Rp. {row.discount_price_original.toLocaleString()}
                      </Box> 
                    : null }
                    RP. {Math.floor(row.discount_price).toLocaleString()}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    {row.tax.title}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    { status === "Pick" && row.quantity !== row.quantity_original  ? 
                      <Box sx={{ textDecoration: 'line-through', color: '#0a449b' }}>
                        {row.quantity_original}
                      </Box> 
                    : null }
                    {row.quantity}
                  </TableCell>
                  { status === "Delivered" ?
                  <>
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                      { row.received }
                  </TableCell> 
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                  {row.note ? row.note : "-"}
                  </TableCell> 
                  </>
                  : null }
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    { status === "Pick" && row.sub_total !== row.sub_total_original && arrData[0].shipping_cost < 0? 
                        <Box sx={{ textDecoration: 'line-through', color: '#0a449b' }}>
                        Rp. {row.sub_total_original.toLocaleString()}
                        </Box> 
                    : null }
                    <Box>
                        RP. { status === "Delivered" ?   row.sub_total_received.toLocaleString() : row.sub_total_before_tax.toLocaleString() }
                    </Box>
                  </TableCell>
                  { status === "Pick" ? 
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                      <Box>
                        { row.note ? row.note : "-" }
                    </Box> 
                  </TableCell>
                  : null }
                  <TableCell component="th" scope="row" sx={styleTableColumn()}>
                    { status === "Pick" ? 
                      <Box>
                        { row.lastStatus === "Waiting Approval" ? "Waiting Approval" : "OK"}
                      </Box> 
                    : null }
                  </TableCell>
                </TableRow>
              </TableBody>
              ))}
          </Table>
        </TableContainer>
        <Box>
          <Paper>
            <Stack flexDirection="row" sx={{ p: 2 }}>
              <Box fontSize="13px">
                Sub Total + PPN :
              </Box>
              <Box>
                <Box pl={2}><h5>Rp. { status === 'Delivered'?(sumSubPrice(data) + subTotalTax(data)).toLocaleString() : sumSubPrice(data).toLocaleString()}</h5></Box>
              </Box>
            </Stack>
          </Paper>
        </Box>
    </Box>
  );
}

export default TableItem