import { useState, useEffect } from 'react';
import { Button, Grid, Paper, Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllWarehouse, postWarehouse, updateWarehouse } from './reducers/warehouseReducers';
import ButtonLoading from '../../../components/ButtonLoading';
import AddressForm from '../../../components/AddressForm';
import CoverageItem from './components/CoverageItem';
import swal from 'sweetalert';
import PhoneInput from 'react-phone-number-input'
import axios from 'axios';


const default_item = [{ 
    province : "",
    province_id: "",
    selected_province : [],
    city : [],
    city_options : [],
    loading_city : false
}]
    
    
const Warehouse = () => {

    const dispatch = useDispatch()
    const store_warehouse = useSelector((state : RootState) => state.warehouse)

    // state for Province 
    const [optionsProvince, setOptionsProvince] = useState<any>([]);
    const [loadingProvince, setLoadingProvince] = useState(false);

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1); 

    const [open, setOpen] = useState({
        open : false,
        view : ""
    });

    const [update, setUpdate] = useState(false);

    const [idWarehouse, setIdWarehouse] = useState <any>(null);

    const [coverageItems, setCoverageItems] = useState<any>(default_item);
    const [defaultCoverage, setDefaultCoverage] = useState<any>([]);

    const [valueWarehouse, setValueWarehouse] = useState<any>({
        name : "",
        email : "",
        phone: "",
        street : "",
    });

    const [valueAddress, setValueAddress] = useState({
        country : "Indonesia",
        country_id : "1",
        error_country : false,
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        zipcode_id : "",
        error_zipcode : false,
        postcode : "",
        street : "",
    });

    
    const [loadingEdit, setLoadingEdit] = useState<any>({
        loading: false,
        index : null
    });


    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, view : name});
    };

    const handleClose = () => {
        setOpen({...open, open : false, view : ""});
        setCoverageItems(default_item)
        setValueWarehouse({...valueWarehouse, 
            name : "",
            email : "",
            phone: "",
        })
        setIdWarehouse(null)
        setValueAddress({...valueAddress, 
            country :'',
            country_id : '',
            province : '',
            province_id : '',
            city : '',
            city_id : '',
            subdistrict : '',
            subdistrict_id : '',
            village : '',
            village_id : '',
            zipcode : '',
            zipcode_id : '',
            street : '',
        })
        setUpdate(false)
        setDefaultCoverage([])
    };

    const onChangeValue = (e : any) => {
        const { name, value } = e.target 
        setValueWarehouse({...valueWarehouse, [name] : value })
    }

    function checkValidation() {
        let validate = false
        if(valueAddress.province === "") {
            setValueAddress({...valueAddress, error_province : true})
            validate = false
        } else if (valueAddress.city === "") {
            setValueAddress({...valueAddress, error_city : true})
            validate = false
        } else if (valueAddress.subdistrict === "") {
            setValueAddress({...valueAddress, error_subdistrict : true})
            validate = false
        } else if (valueAddress.village === "") {
            setValueAddress({...valueAddress, error_village : true})
            validate = false
        } else if(coverageItems.length === 0) {
            swal('Error', "Please at least pick one coverage area", "error")
            validate = false
        } else {
            validate = true
        }
        return validate
    }


    const onClickSubmit = (e: any) => {
        e.preventDefault()
        if(checkValidation()) {
            let coverage = []
            let coverage_all = []
            for(let i of coverageItems) {
                let cities = []
                for(let k of i.city) {
                    coverage.push({
                        id_provinsi : k.value,
                        nama : k.label,
                    })
                    cities.push({
                        id_provinsi : k.value,
                        nama : k.label,
                    })
                }
                coverage_all.push({
                    province : i.province,
                    province_id : i.province_id,
                    cities: cities
                })
            }
            let data_send = {
                vendor_id: userCredentials.vendor_id,
                name : valueWarehouse.name,
                phone: valueWarehouse.phone,
                email: valueWarehouse.email,
                country: valueAddress.country,
                country_id: valueAddress.country_id,
                province: valueAddress.province,
                province_id: valueAddress.province_id,
                city: valueAddress.city,
                city_id: valueAddress.city_id,
                subdistrict: valueAddress.subdistrict,
                subdistrict_id: valueAddress.subdistrict_id,
                village: valueAddress.village,
                village_id: valueAddress.village_id,
                postcode: valueAddress.zipcode,
                postcode_id: valueAddress.zipcode_id,
                street: valueAddress.street,
                coverage : coverage,
                coverage_all : coverage_all
            }
            let data_update = {
                _id : idWarehouse,
                vendor_id: userCredentials.vendor_id,
                name : valueWarehouse.name,
                phone: valueWarehouse.phone,
                email: valueWarehouse.email,
                country: valueAddress.country,
                country_id: valueAddress.country_id,
                province: valueAddress.province,
                province_id: valueAddress.province_id,
                city: valueAddress.city,
                city_id: valueAddress.city_id,
                subdistrict: valueAddress.subdistrict,
                subdistrict_id: valueAddress.subdistrict_id,
                village: valueAddress.village,
                village_id: valueAddress.village_id,
                postcode: valueAddress.zipcode,
                postcode_id: valueAddress.zipcode_id,
                street: valueAddress.street,
                coverage : coverage,
                coverage_all : coverage_all
            }
            if(update) {
                dispatch(updateWarehouse(data_update))
                // console.log(data_update, 'data update')
            } else {
                dispatch(postWarehouse(data_send))
                // console.log(data_send, 'data_send')
            }
        }
    }

    const proceedCity = (params : any) => {
        let city : any = []
        for(let prov of params) {
            city.push({
                value : prov.id_provinsi,
                label : prov.nama,
            })
        }
        return city
    }

    const getDataCity = async (value : any) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kota?id_provinsi=${value}`)
            if(response.data) {
                let city : any = []
                for(let prov of response.data) {
                    city.push({
                        value : prov.id,
                        label : prov.nama,
                    })
                }
                return city
            } else {
                swal('Error', `${response.error}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }

    const proceedProvince = (params : any) => {
        let province = []
        for(let prov of params) {
            province.push({
                value : prov.id,
                label : prov.nama,
            }) 
        }
        setOptionsProvince(province)
        setLoadingProvince(false)
    }

    const getDataProvince = async () => {
        setLoadingProvince(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/propinsi`)
            if(response.data) {
                proceedProvince(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }

    const onClickDetail = async (row : any, name : string) => {
        setValueWarehouse({...valueWarehouse, 
            name : row.name,
            email : row.email,
            phone: row.phone,
        })
        setIdWarehouse(row._id)
        setValueAddress({...valueAddress, 
            country : row.country,
            country_id : row.country_id,
            province : row.province,
            province_id : row.province_id,
            city : row.city,
            city_id : row.city_id,
            subdistrict : row.subdistrict,
            subdistrict_id : row.subdistrict_id,
            village : row.village,
            village_id : row.village_id,
            zipcode : row.postcode,
            zipcode_id : row.postcode_id,
            street : row.street,
        })
        setUpdate(true)
        setDefaultCoverage(row.coverage)
        setTimeout(() => {
            handleClickOpen(name)
        }, 100);
    }

    const onClickUpdate = async (row : any, name : string, index :any) => {
        setLoadingEdit({...loadingEdit, loading: true, index : index })
        setValueWarehouse({...valueWarehouse, 
            name : row.name,
            email : row.email,
            phone: row.phone,
        })
        setIdWarehouse(row._id)
        setValueAddress({...valueAddress, 
            country : row.country,
            country_id : row.country_id,
            province : row.province,
            province_id : row.province_id,
            city : row.city,
            city_id : row.city_id,
            subdistrict : row.subdistrict,
            subdistrict_id : row.subdistrict_id,
            village : row.village,
            village_id : row.village_id,
            zipcode : row.postcode,
            zipcode_id : row.postcode_id,
            street : row.street,
        })
        setUpdate(true)
        setDefaultCoverage(row.coverage)

        let coverage_area = []
        for(let k of row.coverage_all) {
            let cities = await getDataCity(k.province_id)
            coverage_area.push({
                province : k.province,
                province_id: k.province_id,
                city : proceedCity(k.cities),
                city_options :cities,
                selected_province : [{ label:  k.province, value :  k.province_id }],
                loading_city : false
            })
        }
        setCoverageItems(coverage_area)
        if(coverage_area) {
            setLoadingEdit({...loadingEdit, loading: false, index : index })
            setTimeout(() => {
                handleClickOpen(name)
            }, 100);
        }
    }

    useEffect(() => {
        if(store_warehouse.warehouse_post) {
            handleClose()
            window.location.reload()
        }
        // eslint-disable-next-line
    }, [store_warehouse.warehouse_post]);

    useEffect(() => {
        if(store_warehouse.warehouse_update) {
            handleClose()
            window.location.reload()
        }
        // eslint-disable-next-line
    }, [store_warehouse.warehouse_update]);

    useEffect(() => {
        dispatch(getAllWarehouse())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDataProvince()
        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
        },
        {
            name: 'PHONE',
            selector: row => row.phone,
        },
        {
            name: 'CITY',
            selector: row => row.city,
        },
        {
            name: 'ACTION',
            width: '250px',
            cell: (row, idx) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="warning" size="small"
                        onClick={() => onClickDetail(row, "Detail")}
                    >
                        Detail
                    </Button>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => onClickUpdate(row, "Edit", idx)}
                        disabled={loadingEdit.loading && loadingEdit.index === idx ? true : false}
                    >
                        { loadingEdit.loading && loadingEdit.index === idx ? 'Loading...' : "Edit" }
                    </Button>
                </Stack>
            )
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Warehouse Page"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Warehouse Lists</h3>
                </Box>
                <Box>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => handleClickOpen("Add")}
                    >
                        Add New Warehouse
                    </Button>
                </Box>
            </Stack>

            {/* Dialog add new user */}
            <Dialog  
                open={open.open} 
                fullWidth={true}
                maxWidth="lg"
            >
                { open.view === "Add" || open.view === "Edit"?
                <form onSubmit={onClickSubmit} >
                    <DialogTitle>{ !update ? "Add New Warehouse" : "Update Warehouse" }</DialogTitle>
                    <DialogContent style={{minHeight: 500}}>
                        <Box mt={2} pb={1}>
                            <h3>Basic</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Stack p={3}>
                                <Grid container >
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Warehouse Name</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueWarehouse.name}
                                            margin="dense"
                                            fullWidth
                                            id="name"
                                            label="Warehouse Name"
                                            type="text"
                                            name="name"
                                            size="small"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Email</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueWarehouse.email}
                                            margin="dense"
                                            fullWidth
                                            type="email"
                                            label="Email"
                                            name="email"
                                            size="small"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Phone</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={valueWarehouse.phone}
                                                onChange={(e) => setValueWarehouse({...valueWarehouse, phone : e})}
                                                defaultCountry="ID"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Paper>
                        <Box mt={2} pb={1}>
                            <h3>Address</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Stack p={3}>
                                <AddressForm 
                                    setValueAddress={setValueAddress}
                                    valueAddres={valueAddress}
                                />
                            </Stack>
                        </Paper>
                        <Box mt={2} pb={1}>
                            <h3>Coverage Area</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Stack p={3}>
                                <CoverageItem 
                                    coverageItems={coverageItems}
                                    setCoverageItems={setCoverageItems}
                                    optionsProvince={optionsProvince}
                                    loadingProvince={loadingProvince}

                                />
                            </Stack>
                        </Paper>
                        
                    </DialogContent>
                    <DialogActions sx={{p:3}}>
                        <Box sx={{width: '100%'}}>
                            <Button onClick={handleClose} color="error" variant='outlined'>Cancel</Button>
                        </Box>
                        <Box mr={1} />
                        <Box>
                            { !update ?
                            <ButtonLoading 
                                type="submit"
                                name="Submit"
                                loading={store_warehouse.loading_warehouse_post}
                            /> :
                            <ButtonLoading 
                                type="submit"
                                name="Update"
                                loading={store_warehouse.loading_warehouse_update}
                            /> }
                        </Box>
                    </DialogActions>
                </form> :
                <Stack>
                    <DialogTitle>Detail Warehouse</DialogTitle>
                    <DialogContent>
                        <Box>Name : {valueWarehouse.name}</Box>
                        <Box>Email : {valueWarehouse.email}</Box>
                        <Box>Phone : {valueWarehouse.phone}</Box>
                        <Box>Address : {valueAddress.street}, {valueAddress.village}, {valueAddress.subdistrict}, {valueAddress.city}, {valueAddress.country}</Box>
                        <Stack><strong>Coverage Area : </strong> {defaultCoverage.map((data:any, i:any) => ( <p key={i}>{data.nama},</p> ))}</Stack>
                    </DialogContent>
                    <DialogActions sx={{p:3}}>
                        <Box sx={{width: '100%'}}>
                            <Button onClick={handleClose} color="primary" variant='outlined'>OKE</Button>
                        </Box>
                    </DialogActions>
                </Stack>
                }
            </Dialog>

            <Box sx={{pt:3}}>
                <DataTableBase 
                    columns={columns}
                    data={store_warehouse.data}
                    progressPending={store_warehouse?.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                />
            </Box>

        </Box>
    )
}

export default Warehouse
