import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` } //here

export const getAllUsersTeams = createAsyncThunk(
    'get/userteams',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/users`, {
                params : {
                    "vendor_id": userCredentials.vendor_id  //here
                },
                headers
            })
            
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const updateUserTeams = createAsyncThunk(
    'update/userteams',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/vendor/user`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return { 
                    data : response.data.data, 
                    name: response.data.data.fullname, 
                    message : message,
                    is_owner : response.data.data.isOwner
                }
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postUserTeams = createAsyncThunk(
    'user-team/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/vendor/user`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, message : "Success added user team member."}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err.response)
        }
    }
);

export const disableUserTeams = createAsyncThunk(
    'user-disable/post',
    async (value : any, { rejectWithValue }) => { 
        try {
            let body = {
                status : value.status
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/user-vendor/${value.auth_id}`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, message :  response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err.response)
        }
    }
);


