// @ts-ignore
import { ChatEngine  } from 'react-chat-engine';
import { Box } from '@mui/material';
import { userCredentials } from '../../../utilities/config';


function ChatPage() {

    const username = `${userCredentials.fullname} - ${userCredentials.company_code}`

    return (
        <div>
            <Box>
                <div className='chats-page'>
                    <ChatEngine 
                        height="calc(100vh - 100px)"
                        projectID={process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}
                        userName={username}
                        userSecret={userCredentials.vendor_id}
                    />
                </div>
            </Box>
        </div>
    )
}

export default ChatPage
