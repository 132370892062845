import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllNotifications = createAsyncThunk(
    'get/notifications',
    async (page : number, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SOCKET_API}/notif/vendor`, {headers})
            if(response.data.status === 200) {
                let message = response.data.message
                return {data : response.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) { 
            return rejectWithValue(err)
        }
    }
);  
 

export const readNotification = createAsyncThunk(
    'read/notifications',
    async (id:string, { rejectWithValue }) => { 
        let body = {
            ids : [`${id}`],
            user_id : userCredentials.auth_id
        }
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SOCKET_API}/notif/read`, body, {headers})
            if(response.data.status === 200) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);  
