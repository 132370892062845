import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getAllNotifications, readNotification } from './reducers/notificationReducers';

const initialState: any = {
  data: [], 
  page : null,
  totalPage : null,
  loading : false,
  read : false,
  loading_read : false
}; 

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all notifications
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data.data;
        state.page = action.payload.data.page;
        state.totalPage = action.payload.data.totalPage;
      })
      .addCase(getAllNotifications.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // read notifications
      .addCase(readNotification.pending, (state) => {
        state.loading_read = true;
        state.read = false
      })
      .addCase(readNotification.fulfilled, (state, action:any) => {
        state.loading_read = false;
        state.read = action.payload.data;
      })
      .addCase(readNotification.rejected, (state, action : any) => {
        state.loading_read = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
  },
});


export default notificationSlice.reducer;
