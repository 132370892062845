import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getDeliveryReceiptNote = createAsyncThunk(
    'delivery-note/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/delivery-note`, {
                params : {
                    id : userCredentials.vendor_id
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const approveDeliveryNote = createAsyncThunk(
    'delivery-note-approve/post',
    async (id : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/grn/vendor/confirm/${id}`, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const rejectDeliveryNote = createAsyncThunk(
    'delivery-note-reject/post',
    async (id : string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/grn/vendor/reject/${id}`, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
