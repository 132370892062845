import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getDataEFaktur = createAsyncThunk(
    'e-faktur/get',
    async (_, { rejectWithValue }) => { 
        try {
            // let array_exist = localStorage.getItem('faktur')
            // let data_faktur = array_exist === null ? [] : JSON.parse(array_exist)
            // return {data : data_faktur, message : "succes get data"}
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/tax-invoice/Vendor/${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);
