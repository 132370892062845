import React, { useState, useEffect } from 'react';
import {
  Paper,
  Step,
  Stepper,
  StepLabel,
  Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
// import FormUserManagement from './FormUserManagement';
import FormCompanyDetail from './FormCompanyDetail';
import FormLegalDocument from './FormLegalDocument';
import FormBankAccount from './FormBankAccount';

const steps = ['Company Detail', "Bank Account", 'Legal Document'];

export default function StepperForm() {
  const initialStep = useSelector((state : RootState) => state.step_state)

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(initialStep.step_state)
  }, [initialStep])

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={2}>
          <Box p={3}>
          <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                  optional?: React.ReactNode;
              } = {};
              return (
                  <Step key={index} {...stepProps}>
                  <StepLabel {...labelProps}>
                      <h3>{label}</h3>
                      </StepLabel>
                  </Step>
              );
              })}
          </Stepper>
          </Box>
      </Paper>


      <React.Fragment>
      { activeStep === 0 ? (
            <Paper elevation={2}>
              <Box p={3} mt={2}>
                  <Box>
                      <FormCompanyDetail
                          profile={false}
                      />
                  </Box>
              </Box>
            </Paper>
      ) : activeStep === 1 ? (
        <Paper elevation={2}>
          <Box p={3} mt={2}>
              <Box>
                  <FormBankAccount
                      // profile={false}
                  />
              </Box>
          </Box>
        </Paper>
      ) : (
            <Paper elevation={2}>
              <Box p={3} mt={2}>
                  <Box>
                      <FormLegalDocument
                      />
                  </Box>
              </Box>
            </Paper>
      ) }
      </React.Fragment>
    </Box>
  );
}
