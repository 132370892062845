import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBarMessage({ load, message, setShippingCostMessage } : any) {
  // const [open, setOpen] = React.useState(false);

  // const handleClose = (reason : any) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };
 
  // React.useEffect(() => {
  //   if(load) {
  //       setOpen(load)
  //   }
  // }, [load]);

  return (
    <Stack spacing={2} sx={{ width: '100%',  }}>
      <Snackbar 
        open={load} 
        autoHideDuration={3000} 
        onClose={()=>setShippingCostMessage(false)}
        anchorOrigin={{ vertical : 'bottom', horizontal:  'center' }} 
      >
        <Alert onClose={() =>setShippingCostMessage(false)} severity="success" sx={{ width: '100%', backgroundColor: '#3bb001' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
