import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }


export const getDataSetting = createAsyncThunk(
    'setting/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/setting/vendor?vendor_id=${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postTokenByVendor = createAsyncThunk(
    'token/post ',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/third-party/Vendor`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true , message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateTokenByVendor = createAsyncThunk(
    'token/update ',
    async (body : any, { rejectWithValue }) => { 
        let body_data = {
            token : body.token,
            options : body.options
        }
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/third-party/${body.id}`, body_data, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true , message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getTokenByVendor = createAsyncThunk(
    'token/get ',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/third-party/Vendor/${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updateSettingPlatform = createAsyncThunk(
    'setting/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            if(value.update) {
                const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/setting/vendor`, value.body, {headers})
                if(response.data.errors === null) {
                    let message = response.data.message
                    return {data : true, message : message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/setting/vendor`, value.body, {headers})
                if(response.data.errors === null) {
                    let message = response.data.message
                    return {data : true, message : message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);