// @ts-ignore 
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const PrintDocument = ({ data, items }:any) => {
    
    function getDueDate(params: any ) {
        let result = new Date();
        result.setDate(result.getDate() + params);
        return result;
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.topSection}>
                    <Text style={styles.title}>PURCHASE ORDER</Text>

                    <View style={styles.topAddres}>
                        <View style={{ width: '60%' }}>
                            <Text style={styles.vendorName}>{data.vendor.name}</Text>
                            <Text style={styles.phone}>{data.vendor.phone}</Text>
                            <Text style={styles.address}>{data.vendor.address ? data.vendor.address.toLowerCase() : "-"}</Text>
                        </View>
                        <View style={{ width: '40%' }}>
                            <View style={styles.rightSide}>
                                <Text>Package ID : </Text>
                                <Text>{data.code_package}</Text>
                            </View>
                            <View style={styles.rightSide}>
                                <Text>PO ID : </Text>
                                <Text>{data.code_po}</Text>
                            </View>
                            <View style={styles.rightSide}>
                                <Text>Date : </Text>
                                <Text>{moment(data.date).format('ll')}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.sectionFromTo}>
                    <Text style={{ width: '60%' }}>Purchase From :</Text>
                    <Text style={{ width: '40%' }}>Ship To :</Text>
                </View>
                <View style={styles.sectionFromToBtm}>
                    <View style={{ width: '60%' }}>
                        <Text style={styles.fromToName}>{data.vendor.name}</Text>
                        <Text style={styles.fromToPhone}>{data.vendor.phone}</Text>
                        <Text style={styles.fromToAddress}>{data.vendor.address ? data.vendor.address.toLowerCase() : "-"}</Text>
                    </View>
                    <View style={{ width: '40%' }}>
                        <Text style={styles.fromToName}>{data.buyer.name}</Text>
                        <Text style={styles.fromToPhone}>{data.buyer.phone}</Text>
                        <Text style={styles.fromToAddress}>{data.buyer.address ? data.buyer.address.toLowerCase() : "-"}</Text>
                    </View>
                </View>
                <View style={styles.head}>
                    <View style={{ width: '40%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>Shipping Method</Text>
                        
                    </View>
                    <View style={{ width: '30%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>Payment Terms</Text>
                    </View>
                    <View style={{ width: '30%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>Due Date</Text>
                    </View>
                </View>
                <View style={styles.rowHead}>
                    <View style={{ width: '40%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>-</Text>
                        
                    </View>
                    <View style={{ width: '30%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>{data.payment_terms}</Text>
                    </View>
                    <View style={{ width: '30%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>{moment(getDueDate(data.items[0].payment_term[0].value)).format('ll')}</Text>
                    </View>
                </View>
                <View style={styles.head}>
                    <View style={{ width: '50%', textAlign: 'center', borderRight: '1px solid gray', }}>
                        <Text style={styles.textItem}>Items</Text>
                        
                    </View>
                    <View style={{ width: '10%', textAlign: 'center', borderRight: '1px solid gray', }}>
                        <Text style={styles.textItem}>Qty</Text>
                    </View>
                    <View style={{ width: '20%', textAlign: 'center', borderRight: '1px solid gray', }}>
                        <Text style={styles.textItem}>Unit Price</Text>
                    </View>
                    <View style={{ width: '10%', textAlign: 'center', borderRight: '1px solid gray', }}>
                        <Text style={styles.textItem}>Ppn</Text>
                    </View>
                    <View style={{ width: '20%', textAlign: 'center' }}>
                        <Text style={styles.textItem}>Amount</Text>
                    </View>
                </View>

                { items.map((val : any, idx:any) => (
                    <View key={idx} style={styles.itemHead}>
                        <View style={{ width: '50%', textAlign: 'left', borderRight: '1px solid gray', padding: '3px' }}>
                            <Text style={styles.textItem}>{val.name}</Text>
                            
                        </View>
                        <View style={{ width: '10%', textAlign: 'center', borderRight: '1px solid gray', padding: '3px' }}>
                            <Text style={styles.textItem}>{val.quantity}</Text>
                        </View>
                        <View style={{ width: '20%', textAlign: 'left', borderRight: '1px solid gray', padding: '3px' }}>
                            <Text style={styles.textItem}>Rp. {val.discount_price.toLocaleString()}</Text>
                        </View>
                        <View style={{ width: '10%', textAlign: 'center', borderRight: '1px solid gray', padding: '3px' }}>
                            <Text style={styles.textItem}>{val.tax.title}</Text>
                        </View>
                        <View style={{ width: '20%', textAlign: 'left', padding: '3px' }}>
                            <Text style={styles.textItem}>Rp. {val.sub_total.toLocaleString()}</Text>
                        </View>
                    </View>
                )) }

                <View style={styles.btmHead}>
                    <View style={{ width: '15%', textAlign: 'left' }}>
                        <Text style={styles.textItemBy}>Prepared By </Text>
                        <Text> </Text>
                        <Text style={styles.textItemBy}></Text>
                    </View>
                    <View style={{ width: '30%', textAlign: 'left' }}>
                        <Text style={styles.textItemBy}> </Text>
                        <Text></Text>
                        <Text style={styles.textItemGenerated}>Generated by System {moment(data.date).format('ll')} </Text>
                        <Text></Text>
                    </View>
                    <View style={{ width: '25%', textAlign: 'left' }}>
                        <Text style={styles.textItemBy}>Approved By </Text>
                        <Text></Text>
                        <Text style={styles.textItemBy}></Text>
                    </View>
                    <View style={{ width: '10%', textAlign: 'right', paddingRight: '5px' }}>
                            <Text style={styles.textItem}>Subtotal</Text>
                            <Text style={styles.textItem}>Sales Tax</Text>
                            <Text style={styles.textItem}>Order Total</Text>
                    </View>
                    <View style={{ width: '20%', textAlign: 'left' }}>
                        <Text style={styles.textItem}>: Rp. {data.grand_total.toLocaleString()}</Text>
                        <Text style={styles.textItem}>: Rp. 0</Text>
                        <Text style={styles.textItem}>: Rp. {data.grand_total.toLocaleString()}</Text>
                    </View>
                </View>

                <View style={styles.terms}>
                    {/* <View style={{  }}>
                        <Text style={styles.textItem}>Terms and Condition : Generated by System </Text>
                        <Text style={styles.textItem}>1. - </Text>
                        <Text style={styles.textItem}>2. - </Text>
                    </View> */}
                    <View>
                        <Text style={styles.textItem}>{`For any enquiries, email us on ${data.vendor.email} or call  ${data.vendor.phone}`}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

// Create styles
const styles = StyleSheet.create({
    page: {
      padding: '5px', 
      position: 'relative',  
      color: '#000'
    },
    topSection: {
      borderLeft: '2px solid gray', 
      borderTop: '2px solid gray', 
      borderRight: '2px solid gray'
    },
    title : {
      textAlign: 'center', 
      fontSize: 17, 
      fontWeight:700, 
      paddingTop: '20px', 
      paddingBottom: '15px'
    },
    topAddres : {
      flexDirection: 'row', 
      justifyContent: 'space-between', 
      padding: '5px'
    },
    vendorName : {
      fontSize: '12px', 
      paddingBottom: 3, 
      fontWeight:700,
    },
    address : {
      fontSize: '9px', 
      paddingBottom: 3, 
      width: '75%', 
      textTransform: 'capitalize', 
      lineHeight: 1.5
    },
    phone : {
      fontSize: '9px', 
      paddingBottom: 3
    },
    rightSide : {
      fontSize: '10px', 
      paddingBottom: 3, 
      flexDirection: 'row', 
      justifyContent: 'space-between'
    },
    sectionFromTo : {
      border: '2px solid gray', flexDirection: 'row', 
      fontSize: '10px', color: '#000', justifyContent: 'space-between',
      padding: '5px' 
    },
    sectionFromToBtm : {
      borderLeft: '2px solid gray', 
      borderRight: '2px solid gray', flexDirection: 'row', 
      fontSize: '10px', color: '#000', justifyContent: 'space-between',
      padding: '5px' 
    },
    fromToName : {
        fontSize: '12px', 
        paddingBottom: 3, 
        fontWeight:700,
    },
    fromToPhone : {
        fontSize: '9px', 
        paddingBottom: 3
    },
    fromToAddress : {
        fontSize: '9px', 
        paddingBottom: 3, 
        width: '75%', 
        textTransform: 'capitalize', 
        lineHeight: 1.5
    },
    head : {
        border: '2px solid gray', flexDirection: 'row', 
        color: '#000', justifyContent: 'space-between',
        padding: '5px' 
    },
    rowHead : {
        flexDirection: 'row',  borderLeft: '2px solid gray', 
        borderRight: '2px solid gray',
        fontSize: '10px', color: '#000', justifyContent: 'space-between',
        padding: '5px'
    },
    itemHead : {
        borderRight: '2px solid gray', borderLeft: '2px solid gray', flexDirection: 'row', 
        color: '#000', justifyContent: 'space-between',
    },
    btmHead : {
        flexDirection: 'row',  border : '2px solid gray',
        fontSize: '10px', color: '#000', justifyContent: 'space-between',
        padding: '5px' 
    },
    terms : {
        flexDirection: 'column',  border : '2px solid gray',
        fontSize: '10px', color: '#000', justifyContent: 'space-between',
        padding: '5px' 
    },
    textItem : {
        fontSize: '10px', 
        paddingBottom: 3, 
        fontWeight:700
    },
    textItemBy : {
        fontSize: '8px', 
        paddingBottom: 3, 
        fontWeight:500
    },
    textItemGenerated : {
        fontSize: '8px', 
        paddingBottom: 3, 
        fontWeight:500,
        textAlign: 'left'
    }
  
  });
  


export default PrintDocument;