import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getBuyerListsData, getSearchBuyer, postPreferredBuyerList, removeBuyerLists, updatePreferredBuyerList, uploadBulkPreferedList } from './reducers/buyerListsReducer';


const initialState: any = {
  data: [], 
  search_buyer : [],
  loading : true,
  loading_search : false,
  loaded_search : false,
  post_buyer : false,
  loading_post_buyer : false,
  update_buyer : false,
  loading_update_buyer : false,
  remove_buyer : false,
  loading_remove_buyer : false,
  loading_bulk_upload : false,
  bulk_upload : false
};

export const getPreferredBuyer = createSlice({
  name: 'buyer-lists',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // search buyer from db
      .addCase(getSearchBuyer.pending, (state) => {
        state.loading_search = true;
        state.loaded_search = false;
      })
      .addCase(getSearchBuyer.fulfilled, (state, action:any) => {
        state.loading_search = false;
        state.search_buyer = action.payload.data;
        state.loaded_search = true;
      })
      .addCase(getSearchBuyer.rejected, (state, action : any) => {
        state.loading_search = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get buyer lists from db
      .addCase(getBuyerListsData.pending, (state) => {
        state.loading = true;
        state.post_buyer = false;
        state.update_buyer = false;
        state.loaded_search = false;
        state.remove_buyer = false;
        state.search_buyer = [];
      })
      .addCase(getBuyerListsData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getBuyerListsData.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // post buyer list
       .addCase(postPreferredBuyerList.pending, (state) => {
        state.loading_post_buyer = true;
      })
      .addCase(postPreferredBuyerList.fulfilled, (state, action:any) => {
        state.loading_post_buyer = false;
        state.post_buyer = action.payload.data;
      })
      .addCase(postPreferredBuyerList.rejected, (state, action : any) => {
        state.loading_post_buyer = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // update buyer list
      .addCase(updatePreferredBuyerList.pending, (state) => {
        state.loading_update_buyer = true;
      })
      .addCase(updatePreferredBuyerList.fulfilled, (state, action:any) => {
        state.loading_update_buyer = false;
        state.update_buyer = action.payload.data;
      })
      .addCase(updatePreferredBuyerList.rejected, (state, action : any) => {
        state.loading_update_buyer = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // remove buyer list
      .addCase(removeBuyerLists.pending, (state) => {
        state.loading_remove_buyer = true;
      })
      .addCase(removeBuyerLists.fulfilled, (state, action:any) => {
        state.loading_remove_buyer = false;
        state.remove_buyer = action.payload.data;
      })
      .addCase(removeBuyerLists.rejected, (state, action : any) => {
        state.loading_remove_buyer = false;
        swal("Error", `${action.payload}`, 'error')
      })

       // bulk upload 
       .addCase(uploadBulkPreferedList.pending, (state) => {
        state.loading_bulk_upload = true;
        state.bulk_upload = false;
      })
      .addCase(uploadBulkPreferedList.fulfilled, (state, action:any) => {
        state.loading_bulk_upload = false;
        state.bulk_upload = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(uploadBulkPreferedList.rejected, (state, action : any) => {
        state.loading_bulk_upload = false;
        swal("Error", `${action.payload}`, 'error')
      })
    
  },
});

export default getPreferredBuyer.reducer;
