import React, { useState, useEffect } from 'react'; 
import { 
    Stack,
    Dialog,
    DialogContent,
    Button,
    Box,
    DialogActions,
    Checkbox,
    Paper
} from '@mui/material';
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../../components/TableData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import moment from 'moment'
import { getDataPackageDelivered } from '../reducers/invoiceReducers';
import TableItemProductCreate from './TableItemProductCreate';
import { remove } from 'lodash'
import swal from 'sweetalert';

const TableListPackage : React.FC<any> = ({ 
    selectedRows,
    setSelectedRows 
}) => {
    const dispatch = useDispatch()
    
    const store_invoice = useSelector((state : RootState) => state.invoices)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState(true);
    const [dataTable, setDataTable] = useState<any>([]);
    
    const [open, setOpen] = useState(false);
    const [dataItem, setDataItem] = useState<any>({
        data : [],
        shippingCost : 0
    });
    
    const handleClose = () => {
        setOpen(false)
    }

    const onProcessData = (data : any) => {
        let final_array = []
        for(let element of data) {
            final_array.push({...element, checked : false})
        }
        setDataTable(final_array)
        setLoading(false)
    }

    useEffect(() => {
        if(!store_invoice.loading_package) {
            onProcessData(store_invoice.data_package)
        }
        // eslint-disable-next-line
    }, [store_invoice.loading_package]);

    useEffect(() => {
        dispatch(getDataPackageDelivered())
        // eslint-disable-next-line
    }, []);


    function sumTotalPrice (data : any) {
        const totalPriceALl = data.reduce((total : any, data : any) => {
          return total + data.sub_total_received
        }, 0)
        return totalPriceALl;
    }

    const handlePPN = (data:any) =>{
        let totalTax = data[0]?.sub_total_tax
        return totalTax
    }    

    function dataCheckItem (data : any) {
        let error = false
        const copy_selected = [...selectedRows]
        if(
            copy_selected[0].buyer._id === data.buyer._id 
            && 
            copy_selected[0].reference_doc.payment_term.value === data.reference_doc.payment_term.value ) {
            error = false
        } else {
            error =  true
        }
        return error
    }


    const onChangeSelect = (check : any, row: any) => {
        const copy_array = [...dataTable]
        const copy_selected = [...selectedRows]

        if(check === true) {
            const newData : any = copy_array.map((obj : any) => {
                if(obj._id === row._id)
                   return {
                     ...obj,
                     checked: true,
                   }
                return obj
            });
            if(copy_selected.length === 0) {
                setDataTable(newData)
                setSelectedRows([row])
            } else {
                if(!dataCheckItem(row)) {
                    setDataTable(newData)
                    copy_selected.push(row)
                    setSelectedRows(copy_selected)
                } else {
                    swal('Error', 'You cant merge package with different payment terms and different buyer!', 'error')
                }
            }
        } else {
            const newData = copy_array.map((obj : any) => {
                if(obj._id === row._id)
                   return {
                     ...obj,
                     checked: false,
                   }
                return obj
            });
            setDataTable(newData)

            const items_remove = remove(copy_selected, function(obj : any) {
                return obj._id !== row._id
            });
            setSelectedRows(items_remove)
        }


    }

    const columns: TableColumn<any>[] = [
        {
            name: '',
            width: '70px',
            cell: (row, index) => (
                <div>
                    <Checkbox checked={row.checked} onChange={(e) => onChangeSelect(e.target.checked, row)} />
                </div>
            )
        },
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'BUYER',
            selector: row => row.buyer.name,
            width: '200px'
        },
        {
            name: 'ORDER DATE',
            cell: (row) => (
                <div>{moment(row.reference_doc.date_order).format('L')}</div>
            )
        },
        {
            name: 'ORDER ID',
            selector: row => row.reference_doc.code_po,
            width: '200px'
        },
        {
            name: 'PACKAGE ID',
            selector: row => row.reference_doc.code_package,
            width: '200px'
        },
        {
            name: 'PAYMENT TERM',
            selector: row => row.reference_doc.payment_term.name,
            width: '200px'
        },
        {
            name: 'TOTAL + PPN',
            cell: (row) => (
                <div>Rp. { (parseInt(sumTotalPrice(row.items)) + parseInt(row.shipping_cost) + handlePPN(row.items)).toLocaleString()}</div>
            )
        },
        {
            name: 'ITEM',
            width: '250px',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box 
                        // onClick={() => onClickUpdate(row, i) }
                    >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                setDataItem({data : row.items, shippingCost: row.shipping_cost})
                                setOpen(true)
                            }}
                        >
                        View Item
                        </Button>
                    </Box> 
                </Stack>
            ),
        },
    ];

    return (
        <div>
            <DataTableBase 
                columns={columns}
                data={dataTable}
                progressPending={loading}
                pagination
                onChangePage={(value) => setPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
                noDataComponent="You dont have any Package Delivered Yet!"
                
            />

            <Dialog 
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title"  
                open={open}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent>
                    <Stack pt={2} pb={2}>
                        <TableItemProductCreate
                            dataItem={dataItem}
                        />
                        <Paper sx={{ border: '#ccc 1px solid' }}>
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Box pl={2} pt={2}>Shipping Cost</Box> 
                                <Box  pr={4} pt={2} >Rp. { dataItem.shippingCost.toLocaleString()}</Box> 
                            </Stack>
                            <Stack flexDirection="row" justifyContent="space-between">
                                <Box p={2} fontWeight="bold">Total Price</Box> 
                                <Box p={2} pr={4} fontWeight="bold">Rp. {((Math.floor(sumTotalPrice(dataItem.data)) + dataItem.shippingCost + handlePPN(dataItem.data))).toLocaleString()}</Box> 
                            </Stack>
                        </Paper>
                    </Stack>
                    <DialogActions>
                        <Button variant='outlined' onClick={handleClose} color="error">Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TableListPackage
