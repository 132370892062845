import { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress,
    Alert, 
    Paper
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { clearErrorMessage } from '../login/loginSlice';
import { loginAction } from '../login/reducers/loginReducers';
import logo from '../../../assets/img/logo-color.png'
import { useMediaQuery } from '@mui/material'


const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
  })
  .required();

const SignInPage = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state : RootState) => state.login )
  const mobileDisplay = useMediaQuery('(min-width: 480px)')
  const matchesSmall = useMediaQuery('(min-width : 320')
  

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: any): void => {
    dispatch(clearErrorMessage())
    dispatch(loginAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  useEffect(() => {
    if(auth.login && auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/register/step'
      }, 300);
    } else if (auth.login && !auth.data?.first_time) {
      setTimeout(() => {
        window.location.href = '/dashboard'
      }, 300);
    }
    // eslint-disable-next-line
  }, [auth.login]);
  
  const mobileStyle = {
    boxTitle : {
      textAlign: 'center', 
      paddingBottom: 1
    },
    fontSizeLink : {
      fontSize : '12px'
    },
    fontSizeTitle : {
      fontSize : '24px'
    },
    fontSizeSubTitle : {
      fontSize : '15px'
    }
  }
  

  return (
    <>
      <div className="container-box">
       <Paper sx={mobileDisplay?{ margin: 5 } : { margin: 0}}>
         <Box
           sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
             padding: '4rem 2rem',
           }}
         >
           <Box>
               <a href='/'>
                   <img src={logo} alt="logo" width="150px" />
               </a>
           </Box>

           <Box className='name-login' color="primary" sx={{ textAlign: 'center', paddingBottom: 5 }}>
             <h1>Log in</h1> 
             <p>Sign in to your vendor account.</p>
           </Box>

           <Box sx={{ mt: 1, maxWidth : 450 }}>
               <form onSubmit={handleSubmit(onSubmit)}>
               <TextField
                   error={!!errors.email}
                   helperText={errors.email && errors.email.message}
                   {...register('email', { required: true })}
                   margin="normal"
                   fullWidth
                   id="email"
                   label="Email Address"
                   name="email"
                   autoComplete="email"
                   size={mobileDisplay ? 'medium' : 'small'}
                  sx={mobileDisplay? mobileStyle.fontSizeSubTitle : undefined}
               />
               <TextField
                   error={!!errors.password}
                   helperText={errors.password && errors.password.message}
                   {...register('password', { required: true })}
                   margin="normal"
                   size={mobileDisplay ? 'medium' : 'small'}
                   fullWidth
                   name="password"
                   label="Password"
                   id="password"
                   autoComplete="current-password"
                   type={showPassword ? 'text' : 'password'}
                   InputProps={{
                   endAdornment : (
                       <InputAdornment position="end">
                       <IconButton
                           aria-label="toggle password visibility"
                           onClick={handleClickShowPassword}
                           onMouseDown={handleMouseDownPassword}
                           edge="end"
                       >
                           {showPassword ? <VisibilityOff /> : <Visibility />}
                       </IconButton>
                       </InputAdornment>
                   )
                   }}
               />

               { auth.error === null ? null :
               <Box pt={1}>
                   <Alert severity="error">{auth.error}</Alert>
               </Box> }

               <Button
                   type="submit"
                   fullWidth
                   variant="contained"
                   sx={{ mt: 3, mb: 2 }}
                   size="medium"
                   color="primary"
               >
                   Sign In 
                   
                   { 
                   auth.loading && <CircularProgress  size={30} color="inherit" style={{ marginLeft: 10}} /> }
               </Button>
               <Grid container justifyContent="space-between">
                   <Grid item>
                   <Link href="/register" sx={ matchesSmall? undefined : mobileStyle.fontSizeLink}>
                       Create new account
                   </Link>
                   </Grid>
                   <Grid item>
                   <Link href="/forgot-password" sx={ matchesSmall? undefined : mobileStyle.fontSizeLink}>
                       Forgot password
                   </Link>
                   </Grid>
               </Grid>
               </form>
           </Box>
         </Box>
       </Paper>
      </div>
    </>
    )
}

export default SignInPage;
