import { Box, Stack, Button, Dialog, DialogContent, DialogActions, DialogTitle, CircularProgress } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import SearchAddNew from './components/SearchAddNew'
import TableBuyerList from './components/TableBuyerList'
// import axios from 'axios'
// import swal from 'sweetalert'
// import { userCredentials } from '../../../utilities/config'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { uploadBulkPreferedList } from './reducers/buyerListsReducer'
import { getDataSetting } from '../setting/reducers/settingReducers'



const Input = styled('input')({
    display: 'none',
  });


function BuyerLists() {

    const store_buyerlists = useSelector((state : RootState) => state.buyer_lists)
    const store_setting = useSelector((state : RootState) => state.setting)

    const dispatch = useDispatch()

    // const [loadingDownload, setLoadingDownload] = useState(false);
    const [openUplaod, setOpenUplaod] = useState(false);
    const [fileExcel, setFileExcel] = useState<any>(null);

    const [dataSetting, setDataSetting] = useState<any>({
        initial_discount: "",
        payment_term_value : "",
    });

    const onChangeValueFile = (e : any) => {
        setFileExcel(e.target.files)
    }
    // const downloadTemplateExcel = async () => {
    //     setLoadingDownload(true)
    //     try {
    //         const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/mybuyer/excel?vendor_id==${userCredentials.vendor_id}`)
    //         if(response.data.errors === null) {
    //             console.log(response.data, 'dataa')
    //             window.location.href = `${response.data.data}`
    //             setLoadingDownload(false)
    //         } else {
    //             swal('Error', `${response.error}`, 'error')
    //             setLoadingDownload(false)
    //         }
            
    //     } catch (error) {
    //         swal('Error', `${error}`, 'error')
    //         setLoadingDownload(false)
    //     }
    // }

    const onClickBulkUpload = (e : any) => {
        let data_send = {
            file : fileExcel,
            initial_discount : dataSetting.initial_discount,
            payment_term_value : dataSetting.payment_term_value

        }
        dispatch(uploadBulkPreferedList(data_send))
    }

    useEffect(() => {
        if(store_buyerlists.bulk_upload) {
            setOpenUplaod(false)
            setFileExcel(null)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
            // dispatch(getAllProduct())
        }
        // eslint-disable-next-line
    }, [store_buyerlists.bulk_upload]);

    useEffect(() => {
        if(!store_setting.loading) {
            if(store_setting.data !== null) {
                setDataSetting({...dataSetting, 
                    initial_discount: store_setting.data.setting[0].discount,
                    payment_term_value : store_setting.data.setting[0].payment_term.value,
                })
            } else {
                setDataSetting({...dataSetting, 
                    initial_discount: "0",
                    payment_term_value : "0",
                })
            }
        }
        // eslint-disable-next-line
    }, [store_setting.loading]);
      

    useEffect(() => {
        dispatch(getDataSetting())
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="My Buyers"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>My Buyers</h3>
                </Box>
            </Stack>

            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box>
                    <SearchAddNew/>
                </Box>
                <Box>
                    <Stack flexDirection="row" gap={1}>
                    {/* <Button 
                        variant="outlined" color="success" 
                        size="small"
                        onClick={downloadTemplateExcel}
                        disabled={loadingDownload}
                    >
                       { loadingDownload ? "Loading.." : "Download Excel Template" } 
                    </Button> */}
                    <Box>
                    <a href="https://mpindo.sgp1.digitaloceanspaces.com/vendor/ExcelBulkTemplateMyBuyers.xlsx">
                        <Box pb={1}>
                            <Button
                                variant="contained"
                                color="primary" 
                                size="small"
                                fullWidth
                            >Download Excel Template</Button>
                        </Box>
                    </a>
                    </Box>
                    <Box>
                    <Button 
                        variant="contained" color="success" 
                        onClick={() => setOpenUplaod(true)}
                        size="small"
                    >
                        Bulk Upload
                    </Button>
                    </Box>
                    </Stack>
                </Box>
            </Stack>

            <Box sx={{pt:3}}>
                <TableBuyerList />
            </Box>

            <Dialog open={openUplaod} maxWidth="md" fullWidth={true}  >
                <DialogTitle>Please Upload Excel File below!</DialogTitle>
                <DialogContent>
                    <Stack>
                    <Box>
                        { fileExcel === null ? 
                        <label htmlFor="icon-button-file" style={{ border: '1px solid #ccc', padding: '10px' }}>
                            <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={(e) => onChangeValueFile(e)} />
                            <IconButton size='small' color="primary" aria-label="upload picture" component="span">
                                Click here to select file 
                            </IconButton>
                        </label> : 
                        <Stack>
                            <Box> {fileExcel[0].name}</Box> 
                            <Box onClick={() => setFileExcel(null)} sx={{ cursor: 'pointer', color : 'red' }}>Change </Box>
                        </Stack> }
                    </Box>
                    
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenUplaod(false)
                            setFileExcel(null)
                        }} 
                        color="error" variant='outlined' 
                        disabled={store_buyerlists.loading_bulk_upload}
                    >
                        Cancel
                    </Button>
                    
                    <Button onClick={onClickBulkUpload} color="primary" variant='contained' disabled={fileExcel === null ? true : false}>
                        { store_buyerlists.loading_bulk_upload ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Submit" } 
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default BuyerLists
