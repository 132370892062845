import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    Box,
    TextField,
    Backdrop,
    CircularProgress,
    Paper, 
    Stack
} from '@mui/material';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { ISelectOption } from '../../globalTypes'
import { changeStep } from './stepFormSlice'
import { postCompanyDetail } from '../reducers/stepRegisterReducers';
import { RootState } from '../../../../app/store';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios'
import swal from 'sweetalert';
import AddressForm from '../../../../components/AddressForm';
import PhoneInput from 'react-phone-number-input'
import Swal from 'sweetalert2';

const FormCompanyDetail : React.FC<any> = () => {

    const dispatch = useDispatch()
    const state_stepregister = useSelector((state : RootState) => state.step_register)

    const [valueAddress, setValueAddress] = useState({
        country : "Indonesia",
        country_id : "1",
        error_country : false,
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        zipcode_id : "",
        error_zipcode : false,
        street : ""
    });

    const [valueCompany, setValueCompany] = useState<any>({
        legal_name : "",
        business_name : "",
        hotline: "",
        technical_name: "",
        technical_number: "",
        website: "",
        instagram: "",
        facebook: "",
        twitter: ""
    });

    // console.log(valueCompany, 'value company')

    const onChangeValue = (e : any) => {
        const { name, value } = e.target 
        setValueCompany({...valueCompany, [name] : value })
    }

    // state for category company
    const [optionsCategoryCompany, setOptionsCategoryCompany] = useState<ISelectOption[]>([]);
    const [selectedCategoryCompany, setSelectedCategoryCompany] = useState<any>([]);
    const [errorCategoryCompany, setErrorCategoryCompany] = useState<boolean>(false);

    // state for type company
    const [optionsTypeCompany, setOptionsTypeCompany] = useState<ISelectOption[]>([]);
    const [selectedTypeCompany, setSelectedTypeCompany] = useState<any>([]);
    const [errorTypeCompany, setErrorTypeCompany] = useState<boolean>(false);

    const [erroTechnicalNumber, setErroTechnicalNumber] = useState<boolean>(false);
    const [errorHotlineNumber, setErrorHotlineNumber] = useState<boolean>(false);

    // console.log(userCredentials, 'userss')
    /* istanbul ignore next */
    const handleChangeTypeCompany = (value: any) : void => {
        setErrorTypeCompany(false)
        setSelectedTypeCompany(value)
    }

     /* istanbul ignore next */
     const handleChangeCategoryCompany = (value: any) : void => {
         setErrorCategoryCompany(false)
        setSelectedCategoryCompany(value)
    }

    // function checkValidation() {
    //     let validate = false
    //     if(valueAddress.province === "") {
    //         setValueAddress({...valueAddress, error_province : true})
    //         validate = false
    //     } else if (valueAddress.city === "") {
    //         setValueAddress({...valueAddress, error_city : true})
    //         validate = false
    //     } else if (valueAddress.subdistrict === "") {
    //         setValueAddress({...valueAddress, error_subdistrict : true})
    //         validate = false
    //     } else if (valueAddress.village === "") {
    //         setValueAddress({...valueAddress, error_village : true})
    //         validate = false
    //     } else if(selectedCategoryCompany.length === 0) {
    //         setErrorCategoryCompany(true)
    //         validate = false
    //     } else if (selectedTypeCompany.length === 0) {
    //         setErrorTypeCompany(true)
    //         validate = false
    //     } else {
    //         validate = true
    //     }
    //     return validate
    // }

    function checkValidation() {
        let validate = false
        if(valueCompany.technical_number === "") {
            setErroTechnicalNumber(true)
            validate = false
        } else if(valueCompany.hotline === "") {
            setErrorHotlineNumber(true)
            validate = false
        } else if(selectedCategoryCompany.length === 0) {
            setErrorCategoryCompany(true)
            validate = false
        } else if (selectedTypeCompany.length === 0) {
            setErrorTypeCompany(true)
            validate = false
        } else if (valueAddress.province === "") {
            setValueAddress({...valueAddress, error_province : true})
            validate = false
        } else if (valueAddress.city === "") {
            setValueAddress({...valueAddress, error_city : true})
            validate = false
        } else if (valueAddress.subdistrict === "") {
            setValueAddress({...valueAddress, error_subdistrict : true})
            validate = false
        } else if (valueAddress.village === "") {
            setValueAddress({...valueAddress, error_village : true})
            validate = false 
        } else if (valueAddress.zipcode === "") {
            setValueAddress({...valueAddress, error_zipcode : true})
            validate = false
        } else {
            validate = true
        }
        return validate
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if(checkValidation()) {
            let save_local = {
                category: selectedCategoryCompany.value === undefined ? "" : selectedCategoryCompany.value,
                category_id: selectedCategoryCompany.value === undefined ? "" : selectedCategoryCompany.value,
                type : selectedTypeCompany.label === undefined ? "" : selectedTypeCompany.label,
                type_value : selectedTypeCompany.label === undefined ? "" : selectedTypeCompany.value,
                legal_name : userCredentials.vendor_name,
                country: valueAddress.country,
                country_id: valueAddress.country_id,
                province: valueAddress.province,
                province_id: valueAddress.province_id,
                city: valueAddress.city,
                city_id: valueAddress.city_id,
                subdistrict: valueAddress.subdistrict,
                subdistrict_id: valueAddress.subdistrict_id,
                village: valueAddress.village,
                village_id: valueAddress.village_id,
                postcode: valueAddress.zipcode,
                postcode_id: valueAddress.zipcode_id,
                street: valueAddress.street,
                name : valueCompany.business_name,
                phone : valueCompany.hotline,
                whatsapp : valueCompany.technical_number,
                technical_contact : valueCompany.technical_name,
                website : valueCompany.website,
                instagram : valueCompany.instagram,
                facebook : valueCompany.facebook,
                twitter : valueCompany.twitter
            }
            let body_send  = {
                _id: userCredentials.vendor_id,
                category: selectedCategoryCompany.value === undefined ? "" : selectedCategoryCompany.value,
                type : selectedTypeCompany.label === undefined ? "" : selectedTypeCompany.label,
                legal_name : userCredentials.vendor_name,
                country: valueAddress.country,
                country_id: valueAddress.country_id,
                province: valueAddress.province,
                province_id: valueAddress.province_id,
                city: valueAddress.city,
                city_id: valueAddress.city_id,
                subdistrict: valueAddress.subdistrict,
                subdistrict_id: valueAddress.subdistrict_id,
                village: valueAddress.village,
                village_id: valueAddress.village_id,
                postcode: valueAddress.zipcode,
                postcode_id: valueAddress.zipcode_id,
                street: valueAddress.street,
                name : valueCompany.business_name,
                phone : valueCompany.hotline,
                whatsapp : valueCompany.technical_number,
                technical_contact : valueCompany.technical_name,
                website : valueCompany.website,
                instagram : valueCompany.instagram,
                facebook : valueCompany.facebook,
                twitter : valueCompany.twitter,
                email: userCredentials.email
            }
            // localStorage.setItem('legal_doc_company', JSON.stringify(selectedTypeCompany.value))
            // console.log(body_send, 'body send')
            

            if(selectedTypeCompany.value === undefined) {
                Swal.fire({
                    title: 'Warning!',
                    text: "Are you sure want to next and without selected company type? Company type selection is needed for value legal document step.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Next!',
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result) {
                        localStorage.setItem('legal_doc_company', JSON.stringify([]))
                        localStorage.setItem('company_detaill', JSON.stringify(save_local))
                        dispatch(postCompanyDetail(body_send))
                        // dispatch(changeStep(1))
                    }
                })
            } else {
                localStorage.setItem('legal_doc_company', JSON.stringify(selectedTypeCompany.value))
                localStorage.setItem('company_detaill', JSON.stringify(save_local))
                dispatch(postCompanyDetail(body_send))
            }

        }
    }


    const getCompanyCategory = async () => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/master/category?is_buyer=0`, {
                headers : {
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data.errors === null) {
                let category = response.data.data
                let array_category = []
                for(let element of category) {
                    array_category.push({
                        label : element.category,
                        value : element.category
                    })
                }
                setOptionsCategoryCompany(array_category)
            } else {
                swal('Error', `${response.data.message}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }

    const getCompanyType = async () => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/master/type`, {
                headers : {
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data.errors === null) {
                let type = response.data.data
                let array_type = []
                for(let element of type) {
                    array_type.push({
                        label : element.companyType,
                        value : element.legalDoc
                    })
                }
                setOptionsTypeCompany(array_type)
            } else {
                swal('Error', `${response.data.message}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }


    useEffect(() => {
        getCompanyCategory()
        getCompanyType()
    }, []);

    useEffect(() => {
        if(state_stepregister.company_detail) {
            dispatch(changeStep(1))
        }
        // eslint-disable-next-line
    }, [state_stepregister.company_detail])

    useEffect(() => {
        const local_data = localStorage.getItem('company_detaill')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            // console.log(data,'data')
            if(data !== null) {
                setSelectedCategoryCompany(data.category === "" ? [] : { label : data.category, value : data.category})
                setSelectedTypeCompany(data.type  === "" ? [] : { label : data.type, value : data.type_value})
                setValueAddress({...valueAddress, 
                    country : data.country,
                    country_id : data.country_id,
                    province : data.province,
                    province_id : data.province_id,
                    city : data.city,
                    city_id : data.city_id,
                    subdistrict : data.subdistrict,
                    subdistrict_id : data.subdistrict_id,
                    village : data.village,
                    village_id : data.village_id,
                    zipcode : data.postcode,
                    zipcode_id : data.postcode_id,
                    street : data.street,
                })
                setValueCompany({...valueCompany, 
                    business_name : data.name,
                    hotline: data.phone,
                    technical_name: data.technical_contact,
                    technical_number: data.whatsapp,
                    website: data.website,
                    instagram: data.instagram,
                    facebook: data.facebook,
                    twitter: data.twitter
                })
            }
        }
        checkLocalData()
        // eslint-disable-next-line
    }, []);


    return (
        <div>
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state_stepregister.loading_comp_detail}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="section-form-company-detail">
                <Box>
                    <form onSubmit={handleSubmit}>    
                        <Box mt={2} pb={1}>
                            <h3>Basic Information (Required)</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Box p={2}>
                                <Grid container >
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Legal Name</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            margin="dense"
                                            defaultValue={userCredentials.vendor_name}
                                            fullWidth
                                            id="legalname"
                                            label="Legal Name"
                                            name="legalname"
                                            size="small"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Business Name</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            id="aliasname"
                                            label="Business Name"
                                            value={valueCompany.business_name}
                                            name="business_name"
                                            size="small"
                                            onChange={onChangeValue}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Contact Name</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                label="Name"
                                                type="text"
                                                value={valueCompany.technical_name}
                                                onChange={onChangeValue}
                                                name="technical_name"
                                                size="small"
                                                required
                                            />
                                        </Box>
                                    </Grid> 
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Main Phone Number</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={valueCompany.technical_number}
                                                onChange={(e) => { 
                                                    setErroTechnicalNumber(false)
                                                    setValueCompany({...valueCompany, technical_number : e})
                                                }}
                                                defaultCountry="ID"
                                            />
                                        </Box>
                                        {erroTechnicalNumber ? <div className="error-p"><p>Main Phone Number is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Mobile Phone Number</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Stack>
                                            <Box>
                                                <PhoneInput
                                                    placeholder="Enter hotline number"
                                                    value={valueCompany.hotline}
                                                    onChange={(e) => {
                                                        setErrorHotlineNumber(false)
                                                        setValueCompany({...valueCompany, hotline : e})
                                                    }}
                                                    defaultCountry="ID"
                                                />
                                            </Box>
                                        </Stack>
                                        { errorHotlineNumber ? <div className="error-p"><p>Mobile Phone Number is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Category Company</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box pt={1} pb={1}>
                                        <Select
                                            placeholder="Select Category Company"
                                            value={selectedCategoryCompany}
                                            isSearchable={true}
                                            options={optionsCategoryCompany && optionsCategoryCompany}
                                            onChange={handleChangeCategoryCompany}
                                            id="select-style-cat"
                                        />
                                        </Box>
                                        { 
                                        errorCategoryCompany ? <div className="error-p"><p>Category is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Type Company</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box pt={1} pb={1}>
                                        <Select
                                            placeholder="Select Type Company"
                                            value={selectedTypeCompany}
                                            isSearchable={true}
                                            options={optionsTypeCompany && optionsTypeCompany}
                                            onChange={handleChangeTypeCompany}
                                            id="select-style-type"
                                        />
                                        </Box>
                                        { 
                                        errorTypeCompany ? <div className="error-p"><p>Type is required</p></div> : null }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box mt={4} pb={1}>
                            <h3>Address (Required)</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Box p={2}>
                                <AddressForm 
                                    setValueAddress={setValueAddress}
                                    valueAddres={valueAddress}
                                />
                            </Box>
                        </Paper>

                        <Box mt={4} pb={1}>
                            <h3>Social Media (optional)</h3>
                        </Box>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                            <Box p={2}>
                                <Grid container>
                                    
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Website</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            name="website"
                                            onChange={onChangeValue}
                                            value={valueCompany.website}
                                            label="Website"
                                            type="website"
                                            id="website"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Instagram</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueCompany.instagram}
                                            margin="dense"
                                            fullWidth
                                            name="instagram"
                                            label="Instagram"
                                            type="instagram"
                                            id="instagram"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Facebook</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueCompany.facebook}
                                            margin="dense"
                                            fullWidth
                                            name="facebook"
                                            label="Facebook"
                                            type="facebook"
                                            id="facebook"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Twitter</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueCompany.twitter}
                                            margin="dense"
                                            fullWidth
                                            name="twitter"
                                            label="Twitter"
                                            type="twitter"
                                            id="twitter"
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, pb: 2 }}>
                            <Button
                                variant="contained"
                                color="inherit"
                                sx={{ mr: 1 }}
                                disabled
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button 
                                variant="contained"
                                type="submit"
                            >
                                Next
                            </Button>
                        </Box>
                        
                        
                    </form>
                </Box>
            </div>
        </div>
    )
}

export default FormCompanyDetail
