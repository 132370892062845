import { Box, Stack, Skeleton } from '@mui/material';

const TopTenProduct : React.FC<any> = ({ data }) => {


    const stylesHeader : any = {
        border: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: '#64b5f6'
    }

    const stylesColumnLeft : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        textAlign: 'left',
        pl: 1,
        width : '100%',
        padding: '5px 5px',
    }

    const stylesColumnLeftHeader : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        padding: '5px 0'

    }

    const stylesColumn : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        padding: '5px 0'
    }

    return (
        <Box pt={2}>
            { data.loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
                <Box>
                    <Box sx={stylesHeader} >Top 10 Most Sold Product</Box>
                    { data.data.product ? 
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box sx={{width: '60%'}}>
                            <Box sx={stylesColumnLeftHeader}>Product</Box>
                            { data.data.product.map((data : any,i :any) => (
                                <Box sx={stylesColumnLeft} key={i} fontSize={12}>{data._id}</Box>
                            )) }
                        </Box>
                        <Box sx={{width: '10%'}}>
                            <Box sx={stylesColumnLeftHeader}>Qty</Box>
                            { data.data.product.map((data : any,i :any) => (
                                <Box sx={stylesColumnLeftHeader} key={i} fontSize={12}>{data.qty}</Box>
                            )) }
                        </Box>
                        <Box sx={{width: '30%'}}>
                            <Box sx={stylesColumn}>IDR</Box>
                            { data.data.product.map((data : any,i :any) => (
                                <Box sx={stylesColumn} key={i} fontSize={12} fontWeight={600}>Rp. {data.totalAmount.toLocaleString()}</Box>
                            )) }
                        </Box>
                    </Stack> : null }
                </Box>
            }
        </Box>
    )
}

export default TopTenProduct
