import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Forgot from '../features_app/auth/forgot/Forgot';
import AccountPage from '../features_app/dashboard/account/AccountPage';
import BuyerLists from '../features_app/dashboard/buyer_lists/BuyerLists';
import ChatPage from '../features_app/dashboard/chat/ChatPage';
import CustomRole from '../features_app/dashboard/custom_role/CustomRole';
import OrdersPage from '../features_app/dashboard/orders/OrdersPage';
import CreateProducts from '../features_app/dashboard/products/create/CreateNewProducts';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import StepRegister from '../features_app/dashboard/step_register/StepRegister';
import TeamsPage from '../features_app/dashboard/teams/TeamsPage';
import Error404 from '../features_app/error404/Error404';
import DashboardLayout from '../utilities/DashboardLayout';
import SettingPage from '../features_app/dashboard/setting/SettingPage'
import Warehouse from '../features_app/dashboard/warehouse/Warehouse';
import DetailProducts from '../features_app/dashboard/products/detail/DetailProducts';
import UpdateProductPage from '../features_app/dashboard/products/update/UpdateProductPage';
import MainProductPage from '../features_app/dashboard/products/MainProductPage';
import MainInvoicePage from '../features_app/dashboard/finance/invoice/MainInvoicetPage';
import SelectCompany from '../features_app/dashboard/select_company/SelectCompany';
import LoginSuperAdmin from '../features_app/auth/login_superadmin/Login';
import DeliveryNote from '../features_app/dashboard/delivery_note/DeliveryNote';
import EFakturPage from '../features_app/dashboard/finance/efaktur/EFakturPage';
import MainHomePage from '../features_app/dashboard/home/MainIHomePage';
import ChartsPage from '../features_app/dashboard/reports/charts/ChartsPage';
import ChartsDownload from '../features_app/dashboard/reports/download/ChartsDownload';
import InvitationPage from '../features_app/dashboard/invitation/InvitationPage';
import SignInPage from '../features_app/auth/signin/signin';
import SignUpPage from '../features_app/auth/signup/signup';
import MediaCenter from '../features_app/dashboard/media_center/MediaCenterPage';

function AppRoute() {
  
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/superadmin" component={LoginSuperAdmin} />
          <Route exact path="/register" component={SignUpPage} />
          <Route exact path="/forgot-password" component={Forgot} />
          <DashboardLayout exact path="/register/step" component={StepRegister} />
          <DashboardLayout exact path="/login/select-company" component={SelectCompany} />
          <DashboardLayout exact path="/dashboard" component={MainHomePage} />
          <DashboardLayout exact path="/dashboard/orders" component={OrdersPage} />
          <DashboardLayout exact path="/dashboard/delivery-note" component={DeliveryNote} />
          <DashboardLayout exact path="/dashboard/teams" component={TeamsPage} />
          <DashboardLayout exact path="/dashboard/products" component={MainProductPage} />
          <DashboardLayout exact path="/dashboard/create-product" component={CreateProducts} />
          <DashboardLayout exact path="/dashboard/update-product/:slug" component={UpdateProductPage} />
          <DashboardLayout exact path="/dashboard/detail-product/:slug" component={DetailProducts} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/custom-role" component={CustomRole} />
          <DashboardLayout exact path="/dashboard/account" component={AccountPage} />
          <DashboardLayout exact path="/dashboard/finance/invoice" component={MainInvoicePage} />
          <DashboardLayout exact path="/dashboard/finance/efaktur" component={EFakturPage} />
          <DashboardLayout exact path="/dashboard/message" component={ChatPage} />
          <DashboardLayout exact path="/dashboard/warehouse" component={Warehouse} />
          <DashboardLayout exact path="/dashboard/setting" component={SettingPage} />
          <DashboardLayout exact path="/dashboard/invitation" component={InvitationPage} />
          <DashboardLayout exact path="/dashboard/buyer-list" component={BuyerLists} />
          <DashboardLayout exact path="/dashboard/report-charts" component={ChartsPage} />
          <DashboardLayout exact path="/dashboard/report-download" component={ChartsDownload} />
          <DashboardLayout exact path="/dashboard/setting/media-center" component={MediaCenter} />
          <Route exact path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
