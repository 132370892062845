import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { userCredentials } from '../utilities/config';
import { NavLink } from 'react-router-dom';
import Feedback from './Feedback';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

type Anchor = 'left';

export default function NavDashboardMobile() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleDropDownMenu = (name : string) => {
    if(dropDownOpen.name === name) {
        setDropdownOpen({...dropDownOpen, name : name, open : !dropDownOpen.open });
    } else {
        setDropdownOpen({...dropDownOpen, name : name, open : true });
    }
};


  const [dropDownOpen, setDropdownOpen] = React.useState({
    name : '',
    open : false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };


  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
    >
      <List sx={{ marginTop:'50px'}}>
      <div>   
            <div className="sidebar-navbar">
                <div>
                    { userCredentials.modules_menu.map((nav:any, index:any) => (
                    <div key={index}>
                    { nav.child_navigations.length === 0 ?
                    <>
                    <NavLink exact to={nav.module_detail.path} activeClassName="active-link" >
                        <ul>
                            <li className="navbar-list">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                    />
                                </div>
                                 <span>{nav.module_detail.name}</span> 
                            </li>
                        </ul>
                    </NavLink>
                    <Divider />
                    </>
                    : 
                    <>
                    <div className="sidebar-navbar-dropdown">
                        <div className="menu-dropdown" onClick={() => handleDropDownMenu(nav.module_detail.name)}>
                            <Stack flexDirection="row">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                    />
                                </div>
                                <Box ml={1} mr={1}>
                                    <span>{nav.module_detail.name}</span>
                                </Box>
                                <Box>
                                    { dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 
                                    <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                                </Box>
                            </Stack>
                        </div>
                        <div className={dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                            { nav.child_navigations.map((child:any, index:any) => (
                            <div key={index}>
                                <Divider />
                                <NavLink exact to={child.path} activeClassName="active-link" >
                                    <ul>
                                        <li className="navbar-list-child">
                                            <div className="icon">
                                                <Box
                                                    component="img"
                                                    src={child.iconImage}
                                                    sx={{ width: '23px', height: '23px' }}
                                                />
                                            </div>
                                            <span>
                                                <span>{child.name}</span>
                                            </span>
                                        </li>
                                    </ul>
                                </NavLink>
                                <Divider />
                            </div>
                            ))}
                        </div>
                    </div>
                    <Divider />
                    </>
                    }
                    </div>
                    ))}
                </div> 
                <Feedback disabled={userCredentials.isRegistered ? true : false} />
            </div>
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Box onClick={state.left? toggleDrawer(anchor, false) : toggleDrawer(anchor, true)}>
            <MenuIcon />
          </Box>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

