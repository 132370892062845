import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { checkStatusCompany, getStatistic } from './reducers/homeDashboardReducers';

const initialState: any = {
  status: [], 
  loading_status : false,
  statistic : [],
  loading_statistic : false
};

export const homeDashboardSlice = createSlice({
  name: 'dashboard-statistic',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder 
      // get company status
      .addCase(checkStatusCompany.pending, (state) => {
        state.loading_status = true;
      })
      .addCase(checkStatusCompany.fulfilled, (state, action:any) => {
        state.loading_status = false;
        state.status = action.payload.data;
      })
      .addCase(checkStatusCompany.rejected, (state, action : any) => {
        state.loading_status = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get statistic
      .addCase(getStatistic.pending, (state) => {
        state.loading_statistic = true;
      })
      .addCase(getStatistic.fulfilled, (state, action:any) => {
        state.loading_statistic = false;
        state.statistic = action.payload.data;
      })
      .addCase(getStatistic.rejected, (state, action : any) => {
        state.loading_statistic = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      
  },
});

export default homeDashboardSlice.reducer;
