import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

export const createNewChat = createAsyncThunk(
    'craete-chat/post', 
    async (data: any, { rejectWithValue }) => { 
        const headers : any = {
            'Project-ID' : `${process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}`,
            'User-Name' : data.username,
            'User-Secret' : data.secret
        }
        let body = {
            title : data.title,
            usernames : data.usernames,
            is_direct_chat : false,
        }
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_CHAT}`, body, { headers : headers })
            if(response) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getUsersForChat = createAsyncThunk(
    'chat-user/get', 
    async (buyer_id: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user-buyer?buyer_id=${buyer_id}`, {
                headers : {
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            } )
            if(response.data.errors === null) {
                return {data : response.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
})


