import { S3 } from "@aws-sdk/client-s3"
import AWS from 'aws-sdk'

const s3Client = new S3({
    endpoint : "https://sgp1.digitaloceanspaces.com",
    region : "us-east-1",
    credentials : new AWS.Credentials ({
        accessKeyId : "EN7KLGSM2IIEXYSXQG5Y",
        secretAccessKey : "Ox3K1JPiDcXMUN6kZueMjiHHIi0g3ptK/ttdicQ5y/4"
    })
})

export default s3Client