import { Stack, Box, Button, Paper, TextField, Grid } from '@mui/material'
import {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { 
    changeDefaultBankAccount, 
    // checkInquiryAccount, 
    getAllMasterBank, getBankAccount, postBankAccount, removeBankAccount 
} from '../reducers/profilesReducers';
import swal from 'sweetalert';
import Select from 'react-select'

const BankAccount = () => {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)

    const [index, setIndex] = useState<any>("");
    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [valueBank, setValueBank] = useState<any>({
        bank_account_name: "",
        bank_number : ""
    });

    const [optionsBank, setOptionsBank] = useState<any>([]);

    const [selectedBank, setSelectedBank] = useState<any>([]);

    const handleChangeBank = (value : any) => {
        setSelectedBank(value)    
       
    }

    const onChangeValue = (e:any) => {
        setValueBank({...valueBank, [e.target.name]: e.target.value })
        // if(e.target.value >= 0) {
        // }
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueBank({...valueBank, bank_account_name: "", bank_number : ""})
        setSelectedBank([])
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }

    const onClickSubmit = () => {
        let body_send = {
            "vendor_id": userCredentials.vendor_id,
            "bank": selectedBank.label,
            "account_number": valueBank.bank_number,
            "account_name":valueBank.bank_account_name,
            "code" : selectedBank.value,
            "name_code" : selectedBank.name
        }
        // console.log(body_send, 'body send')
        dispatch(postBankAccount(body_send))
    }

    // const onClickCheckInquiry = () => {
    //     let body_inquiry = {
    //         "bank_code": selectedBank.value,
    //         "account_number": valueBank.bank_number
    //     }
    //     dispatch(checkInquiryAccount(body_inquiry))
    // }

    const proceedBankOptions = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element.code, name : element.name_code, label: element.name })
        }
        let sortAccending = data_options.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsBank(sortAccending)
    }

    useEffect(() => {
        if(store_profile.bank_post) {
            handleClose()
            setValueBank({...valueBank, 
                bank_account_name: "",
                bank_number : ""
            }) 
            swal('Success', 'Success Add Bank Account', 'success')
            dispatch(getBankAccount())
        }
        // eslint-disable-next-line
    }, [store_profile.bank_post])

    useEffect(() => {
        if(store_profile.bank_default) {
            setIndex("")
            swal('Success', 'Success Change to Default Bank Account', 'success')
            dispatch(getBankAccount())
        }
        // eslint-disable-next-line
    }, [store_profile.bank_default])

    useEffect(() => {
        if(store_profile.bank_remove) {
            setIndex("")
            swal('Success', 'Success Remove Bank Account', 'success')
            dispatch(getBankAccount())
        }
        // eslint-disable-next-line
    }, [store_profile.bank_remove])

    useEffect(() => {
        if(store_profile.loaded_master_bank) {
            proceedBankOptions(store_profile.master_bank)
        }
        // eslint-disable-next-line
    }, [store_profile.loaded_master_bank])

    useEffect(() => {
        if(store_profile.loaded_inquiry === true) {
            setValueBank({...valueBank, bank_account_name: store_profile.inquiry.account_name})
        }
        // eslint-disable-next-line
    }, [store_profile.loaded_inquiry])

    useEffect(() => {
        dispatch(getBankAccount())
        dispatch(getAllMasterBank())
        // eslint-disable-next-line
    }, []);

    return (
        <Stack mt={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Box>
                    <Box fontWeight="700" fontSize={22}>List of Bank Account </Box>
                    <Box fontSize={14}>You can add bank account maximum 3 account.</Box>
                </Box>
                <Box>
                    <Button
                        variant='contained'
                        size="small"
                        color="primary"
                        onClick={() => handleOpen('Create New')}
                    > 
                        Add New Bank Account
                    </Button>
                </Box>
            </Stack>

            { store_profile.loading_bank ? 
            <Box pt={3}>Loading...</Box>
            :
            <Stack>
                { store_profile.bank.length === 0 ? 
                <Stack mt={2}>
                    <Paper elevation={3} sx={{ border: '#023047 2px solid' }}>
                        <Box p={3}>
                            <Box pt={1} fontWeight="700">
                                You dont have any bank account. Please create at least one bank account!
                            </Box>
                        </Box>
                    </Paper>
                </Stack> 
                :  
                <Stack>
                {store_profile.bank.map((val:any, i: any) => (
                <Stack mt={2} key={i}>
                    <Paper elevation={3} sx={{ border: !val.is_default ? '#fff 4px solid' : '#0091d6 2px solid' }}>
                        <Box p={3}>
                            <Box fontWeight="400">{val.bank}</Box>
                            <Box pt={1} fontWeight="700">
                                {val.account_number}
                            </Box>
                            <Box pt={1} fontWeight="500">
                                an {val.account_name}
                            </Box>
                            <Stack pt={2} flexDirection="row">
                                { val.is_default ? 
                                <Box mr={2}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#0091d6',
                                            color: '#fff',
                                            padding: '3px 10px',
                                            borderRadius: 5
                                        }}
                                    >
                                        Default Bank
                                    </Box> 
                                </Box> : 
                                <Box mr={2}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setIndex(i)
                                            dispatch(changeDefaultBankAccount(val._id))
                                        }}
                                    >
                                        { store_profile.loading_bank_default && index === i ?
                                         "Loading..." : 
                                         "Change to Default" }
                                    </Button>
                                </Box> }
                                <Box>
                                    <Button
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                            setIndex(i)
                                            dispatch(removeBankAccount(val._id))
                                        }}
                                        disabled={val.is_default}
                                    >
                                         { store_profile.loading_bank_remove && index === i ?
                                         "Loading..." : 
                                         "Remove" }
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                </Stack> 
                ))}
                </Stack> }
            </Stack> }

            <Dialog open={open.open} maxWidth="sm" fullWidth={true}  >
                    <DialogTitle>{open.name}</DialogTitle>
                    <DialogContent sx={{minHeight: 400}}>
                        <Stack>
                            <Box pb={1}>
                                <Select
                                    placeholder="Choose Bank"
                                    value={selectedBank}
                                    isSearchable={true}
                                    options={optionsBank && optionsBank}
                                    onChange={handleChangeBank}
                                    id={`select-style-cat`}

                                />
                                {/* { errorParent ? <div className="error-p"><p>Parent Module required</p></div> : null } */}
                            </Box> 
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Number"
                                        onChange={(e:any) => {
                                            if(e.target.value >= 0) {
                                                onChangeValue(e)
                                            }
                                        }}
                                        value={valueBank.bank_number}
                                        name="bank_number"
                                        type="number"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                               {/* <Grid item lg={2}>
                                    <Button 
                                        variant="contained" type="submit"
                                        sx={{pt:1}}
                                        onClick={onClickCheckInquiry}
                                    >
                                        { store_profile.loading_inquiry ? "Loading" : "Check" } 
                                    </Button>
                               </Grid> */}
                           </Grid>
                           </Box>
                           <Box pt={1} pb={1}>
                           <Grid container spacing={2}>
                                <Grid item lg={12}>
                                    <TextField 
                                        label="Account Name"
                                        onChange={onChangeValue}
                                        value={valueBank.bank_account_name}
                                        name="bank_account_name"
                                        type="text"
                                        size="small"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        fullWidth
                                    />
                               </Grid>
                           </Grid>
                           </Box>
                            {/* { valueBank.bank_account_name !== "" ?
                            <Box pt={1}><Alert severity="success">an/ {valueBank.bank_account_name}</Alert></Box>
                             : null } */}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancel</Button>
                        <Button onClick={onClickSubmit} variant="contained" type="submit" disabled={valueBank.bank_account_name === "" ? true : false}>
                            { store_profile.loading_bank_post ? "Loading" : "Submit" } 
                        </Button>
                    </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default BankAccount
