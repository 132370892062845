import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { 
    Paper,
} from '@mui/material';
import CardOrder from './components/CardOrder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getDeliveryReceiptNote } from './reducers/deliveryNoteReducers';

const DeliveryNote = () => {
    const dispatch = useDispatch()
    const store_deliverynote = useSelector((state : RootState) => state.delivery_note)

    const [refetch, setRefetch] = useState(false);
    const [dataDeliveryNote, setDataDeliveryNote] = useState<any>([]);
    const [loadedDelivery, setLoadedDelivery] = useState(false);
    
    useEffect(() => {
      if(store_deliverynote.loaded_delivery_note) {
          setDataDeliveryNote(store_deliverynote.delivery_note)
          setLoadedDelivery(true)
      }
      // eslint-disable-next-line
  }, [store_deliverynote.loaded_delivery_note]);

    useEffect(() => {
      setLoadedDelivery(false)
      dispatch(getDeliveryReceiptNote())
      // eslint-disable-next-line
    }, [refetch]);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Delivery Note Page"
            />
           <Box sx={{pt:2}}>
                <h3>Delivery Note</h3>
           </Box>

           <Box sx={{ width: '100%', mt: 3 }}>
            <Box>
              <Paper style={{ backgroundColor: '#ccc' }}>
              <Box p={3}>
                { store_deliverynote.loading_delivery_note ? "Loading..." : 
                  <CardOrder 
                    data={dataDeliveryNote} loaded={loadedDelivery} 
                    refetch={refetch}
                    setRefetch={setRefetch}
                  />
                }
              </Box>
              </Paper>
            </Box> 
            </Box> 
        </Box>
    )
}

export default DeliveryNote;
