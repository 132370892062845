import React, { useEffect, useState } from 'react'
import { Grid, TextField, Box, Skeleton } from '@mui/material'
import Select from 'react-select'
import axios from 'axios'
import swal from 'sweetalert';

const AddressForm : React.FC<any> = ({
    setValueAddress,
    valueAddres
}) => {

    // state for Country
    const optionsCountry : any = [{ value : "Indonesia", label: "Indonesia" }];
    const [selectedCountry, setSelectedCountry] = useState<any>([{ value : "Indonesia", label: "Indonesia" }]);

    // state for Province
    const [optionsProvince, setOptionsProvince] = useState<any>([]);
    const [loadingProvince, setLoadingProvince] = useState(false);
    // state for City
    const [optionsCity, setOptionsCity] = useState<any>([]);
    const [loadingCity, setLoadingCity] = useState(false);

    // state for Village
    const [optionsVillage, setOptionsVillage] = useState<any>([]);
    const [loadingVillage, setLoadingVillage] = useState(false);

    // state for SubDistrict
    const [optionsSubDistrict, setOptionsSubDistrict] = useState<any>([]);
    const [loadingSubDistrict, setLoadingSubDistrict] = useState(false);

    // state for SubDistrict
    const [optionsZIPCode, setOptionsZIPCode] = useState<any>([]);
    const [loadingZIPCode, setLoadingZIPCode] = useState(false);


    const clearAll = () => {
        setOptionsSubDistrict([])
        setOptionsVillage([])
        setOptionsZIPCode([])
    }

    const clearAll2 = () => {
        setOptionsVillage([])
    }

    const handleChangeCountry = (value: any) : void => {
        setSelectedCountry(value)
    }

    const handleChangeProvince = (value: any) : void => {
        getDataCity(value.value)
        setValueAddress({...valueAddres, 
            province : value.label,
            province_id : value.value,
            city : "",
            city_id : "",
            subdistrict : "",
            subdistrict_id : "",
            village : "",
            village_id : "",
            error_province : false,
            error_city : false,
            error_subdistrict : false,
            error_village : false,
        })
        clearAll()
    }

    const handleChangeCity = (value: any) : void => {
        getDataSubDistrict(value.value)
        setValueAddress({...valueAddres, 
            city : value.label,
            city_id : value.value,
            subdistrict : "",
            subdistrict_id : "",
            village : "",
            village_id : "",
            error_city : false,
            error_subdistrict : false,
            error_village : false,
        })
        clearAll2()
    }

    const handleChangeSubDistrict = (value: any) : void => {
        getDataVillage(value.value)
        setValueAddress({...valueAddres, 
            subdistrict : value.label,
            subdistrict_id : value.value,
            village : "",
            village_id : "",
            error_subdistrict : false,
            error_village : false,
        })
    }

    const handleChangeVillage = (value: any) : void => {
        getDataZipCode(value.value)
        setValueAddress({...valueAddres, 
            village : value.label,
            village_id : value.value,
            error_village : false,
        })
    }

    const handleChangeZipCode = (value: any) : void => {
        setValueAddress({...valueAddres, 
            zipcode : value.label,
            zipcode_id : value.value,
            error_zipcode : false,
        })
    }

    const proceedProvince = (params : any) => {
        let province = []
        for(let prov of params) {
            province.push({
                value : prov.id,
                label : prov.nama,
            })
        }
        let sortAccending = province.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsProvince(sortAccending)
        setLoadingProvince(false)
    }

    const proceedCity = (params : any) => {
        let city = []
        for(let prov of params) {
            city.push({
                value : prov.id,
                label : prov.nama.toUpperCase(),
            })
        }
        let sortAccending = city.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsCity(sortAccending)
        setLoadingCity(false)
    }

    const proceedSubDistrict = (params : any) => {
        let subdistrict = []
        for(let prov of params) {
            subdistrict.push({
                value : prov.id,
                label : prov.nama.toUpperCase(),
            })
        }
        let sortAccending = subdistrict.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsSubDistrict(sortAccending)
        setLoadingSubDistrict(false)
    }

    const proceedVillage = (params : any) => {
        let village = []
        for(let prov of params) {
            village.push({
                value : prov.id,
                label : prov.nama.toUpperCase(),
            })
        }
        let sortAccending = village.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsVillage(sortAccending)
        setLoadingVillage(false)
    }

    const proceedZipCode = (params : any) => {
        let zipcode = []
        for(let prov of params) {
            zipcode.push({
                value : prov._id,
                label : prov.postcode,
            })
        }
        let sortAccending = zipcode.sort((a, b) => a.label.localeCompare(b.label))
        setOptionsZIPCode(sortAccending)
    }

    const getDataProvince = async () => {
        setLoadingProvince(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/propinsi`)
            if(response.data) {
                proceedProvince(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingProvince(false)
            } 
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingProvince(false)
        }
    }

    const getDataCity = async (id : number) => {
        setLoadingCity(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kota?id_provinsi=${id}`)
            if(response.data) {
                proceedCity(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingCity(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingCity(false)
        }
    }

    const getDataSubDistrict = async (id : number) => {
        setLoadingSubDistrict(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kecamatan?id_kota=${id}`)
            if(response.data) {
                proceedSubDistrict(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingSubDistrict(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingSubDistrict(false)
            
        }
    }

    const getDataVillage = async (id : number) => {
        setLoadingVillage(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kelurahan?id_kecamatan=${id}`)
            if(response.data) {
                proceedVillage(response.data)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingVillage(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingVillage(false)
        }
    }

    const getDataZipCode = async (id : number) => {
        setLoadingZIPCode(true)
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kodepos?id_kelurahan=${id}`)
            if(response.data) {
                proceedZipCode(response.data)
                setLoadingZIPCode(false)
            } else {
                swal('Error', `${response.error}`, 'error')
                setLoadingZIPCode(false)
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
            setLoadingZIPCode(false)
        }
    }
    
    useEffect(() => {
        getDataProvince()
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Grid container>
                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Country (Negara)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Country"
                        value={selectedCountry}
                        isSearchable={true}
                        options={optionsCountry && optionsCountry}
                        onChange={handleChangeCountry}
                        id="select-style-countryy"
                    />
                    </Box>
                    { valueAddres.error_country ? <div className="error-p"><p>Country is required</p></div> : null }
                </Grid>
                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Province (Provinsi)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    { loadingProvince ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Province"
                        value={{value : valueAddres.province_id, label: valueAddres.province}}
                        isSearchable={true}
                        options={optionsProvince && optionsProvince}
                        onChange={handleChangeProvince}
                        id="select-style-provincee"
                    /> 
                    </Box> }
                    { valueAddres.error_province ? <div className="error-p"><p>Province is required</p></div> : null }
                </Grid>
                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>City (Kota)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    { loadingCity ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select City"
                        value={{value : valueAddres.city_id, label: valueAddres.city}}
                        isSearchable={true}
                        options={optionsCity && optionsCity}
                        onChange={handleChangeCity}
                        id="select-style-cityy"
                    /> 
                    </Box> }
                    { valueAddres.error_city ? <div className="error-p"><p>City is required</p></div> : null }
                </Grid>

                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Sub District (Kecamatan)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    { loadingSubDistrict ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Sub District"
                        value={{value : valueAddres.subdistrict_id, label: valueAddres.subdistrict}}
                        isSearchable={true}
                        options={optionsSubDistrict && optionsSubDistrict}
                        onChange={handleChangeSubDistrict}
                        id="select-style-subdistrict"
                    /> 
                    </Box> }
                    { valueAddres.error_subdistrict ? <div className="error-p"><p>Sub District is required</p></div> : null }
                </Grid>

                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Village (Kelurahan)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    { loadingVillage ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Village"
                        value={{value : valueAddres.village_id, label: valueAddres.village}}
                        isSearchable={true}
                        options={optionsVillage && optionsVillage}
                        onChange={handleChangeVillage}
                        id="select-style-village"
                    /> 
                    </Box> }
                    { valueAddres.error_village ? <div className="error-p"><p>Village is required</p></div> : null }
                </Grid>

                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Zip Code (Kode Pos)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    { loadingZIPCode ? 
                    <Skeleton height={60} />
                    :
                    <Box pt={1} pb={1}>
                    <Select
                        placeholder="Select Zip Code"
                        value={{value : valueAddres.zipcode_id, label: valueAddres.zipcode}}
                        isSearchable={true}
                        options={optionsZIPCode && optionsZIPCode}
                        onChange={handleChangeZipCode}
                        id="select-style-zipcode"
                    /> 
                    </Box> }
                    { valueAddres.error_zipcode ? <div className="error-p"><p>ZipCode is required</p></div> : null }
                </Grid>

                <Grid item xl={3} lg={4} xs={12}>
                    <Box pt={1}><h4>Street (Jalan)</h4></Box>
                </Grid>
                <Grid item xl={9} lg={8} xs={12}>
                    <TextField
                        margin="dense"
                        fullWidth
                        name="street"
                        label="Street"
                        onChange={(e) => setValueAddress({...valueAddres, street : e.target.value })}
                        value={valueAddres.street}
                        type="text"
                        id="street"
                        size="small"
                        multiline
                        rows={3}
                        required
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default AddressForm
