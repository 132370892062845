import { Stack, Box, Button, Paper, Typography, Dialog, Grid } from '@mui/material'
import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../../app/store';
import BreadCrumbs from '../../../../../components/BreadCrumbs'
import { userCredentials } from '../../../../../utilities/config';
import TableItemProduct from '../components/TableItemProduct';
import { confirmPayment, postNewInvoice } from '../reducers/invoiceReducers';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';
import { getShippingCost, getDataInvoice } from '../reducers/invoiceReducers';


function InvoiceSummary() {

    const dispatch = useDispatch()
    const location : any = useLocation()
    const state_invoice = location.state === undefined ? null : location.state.data_invoice     
    
    const state_invoice_name = location.state === undefined ? null : location.state.name
    const store_invoice = useSelector((state : RootState) => state.invoices)
    
    useEffect(()=>{
        dispatch(getDataInvoice())
        dispatch(getShippingCost())
        // eslint-disable-next-line
    },[])

    const [valueAdditionalCost] = useState<any>([]);
    const [detailInvoice, setDetailInvoice] = useState<any>({
        open : false,
        data : {}
    });

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [detailProof, setDetailProof] = useState<any>({
        open : false,
        img : ""
    });

    function sumTotalPPNItem (data : any) {        
        return data.reduce((total : any, data : any) => {
            return total + data.sub_total_tax
        }, 0)
    }

    function sumTotalPrice (data : any) {
        return  data.reduce((total : any, data : any) => {
            return total + data.sub_total_received
        }, 0)
    }

    // function sumTotalAdditionalCost (data : any) {
    //     const additonal_cost = data.reduce((total : any, data : any) => {
    //         return total === "" ? 0 : total + data.value === "" ? 0 : parseInt(data.value)
    //     }, 0)
    //     return additonal_cost;
    // }

    function getDueDate(params: any ) {
        let result = new Date();
        result.setDate(result.getDate() + params.payment_term.value);
        return result;
    }

    function sumGrandTotalPrice(data:any) {
        let grand_total = (sumTotalPrice(data) - state_invoice.down_payment) + sumTotalPPNItem(data) + state_invoice.shipping_costs
        return grand_total
    }
    

    function onClickSubmitInvoice() {
        let data_send = {
            invoice_number: state_invoice.invoice_number,
            due_date : getDueDate(state_invoice.reference_document[0]),
            vendor : state_invoice.vendor,
            buyer : state_invoice.buyer,
            reference_document: state_invoice.reference_document,
            items : state_invoice.items,
            additional_fee: valueAdditionalCost,
            credit_note : "", 
            shipping_costs: state_invoice.shipping_costs,
            tax: [{
                title: "ppn",
                description: "ppn",
                value: sumTotalPPNItem(state_invoice.items)
            }],
            down_payment: state_invoice.down_payment,
            sub_total: sumTotalPrice(state_invoice.items),
            grand_total: sumGrandTotalPrice(state_invoice.items) >= 0? sumGrandTotalPrice(state_invoice.items) : 0,
            note: "",
            creator: userCredentials.fullname,
            direct_payment : userCredentials.company_detail.direct_payment
        }
        dispatch(postNewInvoice(data_send))
    }

    const onClickConfirmPayment =  () => {
        setLoadingBtn(true)
        let data_send = {
            code : detailInvoice.data.infoPayment[0].code,
            user : userCredentials.fullname
        } 
        setTimeout(async() => {
            const response : any = await dispatch(confirmPayment(data_send))
            if(response.payload.data) {
                setLoadingBtn(false)
                setDetailInvoice({...detailInvoice, open: false, data : {}})
                swal('Success', "Success confirm the payment", 'success')
                setTimeout(() => {
                    window.location.href = "/dashboard/finance/invoice"
                }, 1500);
            }
        }, 1000);
    }

    useEffect(() => {
        if(store_invoice.post_invoice) {
            window.location.href = '/dashboard/finance/invoice'
        }
        // eslint-disable-next-line
    }, [store_invoice.post_invoice]);

    useEffect(() => {
        if(state_invoice === null) {
            window.location.href = "/dashboard/finance/invoice/create"  
        } else {
            if(state_invoice.infoPayment.length && state_invoice.from === "notification") {
                setDetailInvoice({...detailInvoice, open: true, data : state_invoice})
            }
        }
        // eslint-disable-next-line
    }, [state_invoice]);

    const componentRef = useRef<any>();    

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={true}
                page="Invoice"
                link="/dashboard/finance/invoice"
                current="Invoice Summary"
            />

            <div>
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Invoice Summary</h3>
                </Box>
                { state_invoice_name === "detail" ? 
                    <Stack mt={3} direction="row" justifyContent="flex-end" spacing={3}>
                        <ReactToPrint
                            trigger={() => <Button variant="outlined" color="primary">Print Invoice</Button>}
                            content={() => componentRef.current}
                            onBeforePrint={() : any => document.title = `Invoice Number ${state_invoice.invoice_number}`}
                            onAfterPrint={() => document.title = "Pasar Pemesanan - Vendor Platform"}
                        />
                    </Stack> : null 
                }
            </Stack>

            <Stack pt={3} ref={componentRef}>
                <Paper sx={{ border: '2px solid #ddd' }}>
                    <Box p={3}>
                        { state_invoice_name === "detail"  ? 
                        <Stack direction="row" justifyContent="space-between">
                            <Box pt={2} pb={2} fontWeight="600">Invoice Number : {state_invoice.invoice_number}</Box>
                            { state_invoice.status === "Review Payment" ?  
                            <Box pt={2} pb={2} fontWeight="600">Status : 
                                <span>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        sx={{ml:2}}
                                        onClick={() => {
                                            setDetailInvoice({...detailInvoice, open: true, data : state_invoice})
                                        }}

                                    >Review Payment</Button>
                                </span>
                            </Box>
                            :
                            <Box pt={2} pb={2} fontWeight="600">Status : <span style={{color: state_invoice.status === 'Paid' ? 'green' : 'black' }}>{state_invoice.status}</span></Box>
                            }
                        </Stack> :
                        <Box pt={2} pb={2} fontWeight="600">Invoice Number : {state_invoice.invoice_number}</Box> }
                        <Stack pb={2} direction="row" justifyContent="space-between" spacing={3}>
                            <Box sx={{width: '100%', }}>
                                <Box pb={1} fontSize="15px">From :</Box>
                                <Paper sx={{ border: '1px solid #ccc', minHeight: 100 }}>
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">{state_invoice.vendor.name}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.vendor.phone}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.vendor.address}</Box>
                                    </Box>
                                </Paper>
                            </Box>
                            <Box sx={{width: '100%'}}>
                                <Box pb={1} fontSize="15px">To :</Box>
                                <Paper sx={{ border: '1px solid #ccc', minHeight: 100 }}>
                                    { userCredentials.company_detail.direct_payment ?
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">{state_invoice.buyer.name}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.buyer.phone}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.buyer.address}</Box>
                                    </Box> :
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">PT Manajemen Pemesanan Indonesia</Box>
                                        <Box fontWeight="400" fontSize="12px">+62129339390</Box>
                                        <Box fontWeight="400" fontSize="12px">APL Office Tower, 16th-T9 Floor, Podomoro City. Jl. Letjend. S. Parman Kav. 28, <br/> Tj. Duren Selatan, Grogol Petamburan, Jakarta Barat. 11440</Box>
                                    </Box>
                                    }
                                </Paper>
                            </Box>
                        </Stack>

                        <Stack pt={4}>
                            <TableItemProduct 
                                dataItem={state_invoice.items}
                                state_invoice_name={state_invoice_name}
                            />
                            <Paper>
                                <Stack direction="row" justifyContent="flex-end" >
                                    <Box p={2}>
                                        <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Sub Total Price</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. { sumTotalPrice(state_invoice.items).toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Shipping Cost</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {state_invoice.shipping_costs.toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13}>Down Payment</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {state_invoice.down_payment.toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13}>Total PPN</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumTotalPPNItem(state_invoice.items).toLocaleString()}</Box>
                                        </Stack>
                                        {/* <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Total Additional Cost</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumTotalAdditionalCost(valueAdditionalCost).toLocaleString()}</Box>
                                        </Stack> */}
                                        { sumGrandTotalPrice(state_invoice.items) >= 0?
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13} fontWeight="600">Grand Total</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumGrandTotalPrice(state_invoice.items).toLocaleString()}</Box>
                                        </Stack>
                                        :
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13} fontWeight="600">Overpayment</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {Math.abs(sumGrandTotalPrice(state_invoice.items)).toLocaleString()}</Box>
                                        </Stack>
                                        }
                                    </Box>
                                </Stack>
                            </Paper>
                        </Stack>

                        <Stack pt={2}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body2" fontWeight="500">REFERENCE DOCUMENT</Typography>
                                    <Stack direction="row" pt={1} >
                                        <Box width={200} fontSize={13}>Code PO :</Box>
                                        { state_invoice.reference_document.map((val:any, i:any) => (
                                            <Box pl={1} fontWeight="600" fontSize={13} key={i}>({val.code_po})</Box>
                                        )) }
                                    </Stack>
                                    <Stack direction="row" >
                                        <Box width={200} fontSize={13}>Code Package :</Box>
                                        { state_invoice.reference_document.map((val:any, i:any) => (
                                            <Box pl={1} fontWeight="600" fontSize={13} key={i}>({val.code_package})</Box>
                                        )) }
                                    </Stack>
                                    {/* <Stack direction="row" >
                                        <Box width={200}>Payment Term :</Box>
                                        <Box pl={1} fontWeight="600" >{state_invoice.reference_document[0].payment_term.name}</Box>
                                    </Stack> */}
                                </Box>
                            </Paper>
                        </Stack>

                        <Stack pt={2}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body2" fontWeight="500">DUE DATE : {moment(getDueDate(state_invoice.reference_document[0])).format('ll')}</Typography>
                                </Box>
                            </Paper>
                        </Stack>
                        
                        { state_invoice_name === "detail" ? null :
                        <Stack mt={3} direction="row" justifyContent="flex-end" spacing={3}>
                            <Button 
                                variant='contained' 
                                color="success" 
                                disabled={store_invoice.loading_post_invoice ? true : false}
                                onClick={() => {
                                    onClickSubmitInvoice()
                                }}
                            >
                                { store_invoice.loading_post_invoice ? "Loading.." : "Submit Invoice" }
                            </Button>
                        </Stack>  }
                        
                    </Box>
                </Paper>
           </Stack>
           </div>

           <Dialog
                fullWidth={true}
                maxWidth="md"
                open={detailInvoice.open}
                onClose={() => setDetailInvoice({...detailInvoice, open : false, data : {}})}
            >
                <Box p={3}>
                    { detailInvoice.data.invoice_number === undefined ? null : 
                    <Grid container flexDirection="column" alignItems="center" justifyContent="center" >
                        <Box pb={2} pt={2} fontSize={24} sx={{width: '70%'}} textAlign="center" color="#ff9f43">
                            Please make sure the amount and payment proof is correct before confirm this payment.
                        </Box>
                        <Box pb={1} fontWeight="bold">Amount : Rp. {sumGrandTotalPrice(state_invoice.items).toLocaleString()}</Box>
                        <Box pb={1}>Payment Proof : </Box>
                        <Box onClick={() => setDetailProof({...detailProof, open: true, img : detailInvoice.data.infoPayment[0].imageUrl }) } sx={{textAlign: 'center'}}>
                            { detailInvoice.data.infoPayment[0].imageUrl === undefined ? 'Buyer is not yet upload proof payment' :
                            <img 
                                id="img-review-proof" 
                                alt="proof" 
                                src={detailInvoice.data.infoPayment[0].imageUrl} 
                                width="50%" height="50%" 
                            /> }
                        </Box>
                    </Grid> }
                    <Box pt={2} pb={1} />
                    <Grid container flexDirection="row" justifyContent="center" >
                        <Button 
                            variant='contained' color="primary" 
                            onClick={() => onClickConfirmPayment()}
                            disabled={loadingBtn}
                        >
                            { loadingBtn ? "Loading.."  : " Confirm Payment"}
                        </Button>
                        <Box pr={1} pl={1}/>
                        <Button 
                            variant='contained' color="error" 
                            onClick={() => setDetailInvoice({...detailInvoice, open : false, data : {}})}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Box>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={detailProof.open}
                onClose={() => setDetailProof({...detailProof, open : false, img : ""})}
            >
                { detailProof.img === "" ? null : 
                <Box p={3}>
                    <img 
                        id="img-review-proof" 
                        alt="proof" 
                        src={detailProof.img}  
                        width="100%" height="100%" 
                    />
                    <Box pt={2} pb={1} />
                    <Grid container flexDirection="row" justifyContent="center" >
                        <Box pr={1} pl={1}/>
                        <Button 
                            variant='contained' color="primary" 
                            onClick={() => setDetailProof({...detailProof, open : false, img : ""})}
                        >
                            OK
                        </Button>
                    </Grid>
                </Box> }
            </Dialog>
        </Box>
    )
}

export default InvoiceSummary
