import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
} from '@mui/material';


const ModuleDetail : React.FC<any> = ({
    moduleItems,
}) => {

  return (
    <Box>
        <TableContainer component={Paper} style={{ minHeight: 500 }} >
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700,}}>Parent Module</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Child Module</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Features</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { moduleItems.map((row :any, i : any) => (
                <TableRow key={i} >
                    <TableCell component="th" scope="row">
                        <Box>
                            {row.module_detail.name}
                        </Box> 
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.child_navigations.length !== 0 ?
                        <Box>
                            {row.child_navigations.map((child:any, ind:any) => (
                                <Box key={ind}>
                                    {child.name},
                                </Box> 
                            ))}
                        </Box>  
                        : "-" }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.features.map((ele:any, idx:any) => (
                        <Box key={idx}>
                            {ele.name},
                        </Box> 
                        ))}
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default ModuleDetail