import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import logo from '../assets/img/logo.png'
import logop from '../assets/img/logo-p.png'
import { checkLoginSessionTime, userCredentials } from '../utilities/config';
import { Stack, Card, Button } from '@mui/material';
import Feedback from './Feedback';
import { Offline, Online } from "react-detect-offline";
import { useIdleTimer } from 'react-idle-timer'
import NotificationBell from './Notification';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MuiDrawer from '@mui/material/Drawer';
import NavDashboardMobile from './NavDashboardMobile';
import { useMediaQuery } from '@mui/material'

const drawerWidth = 195;

const openedMixin = (theme: Theme): CSSObject => ({ 
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


const onClickRefresh = () => {
    window.location.reload()
}


const onClickSignOut = () : void => {
    localStorage.clear()
    window.location.href = "/"
}

interface Props {
    window?: () => Window;
}

const NavDashboard = (props: Props) => {
    const auth = useSelector((state : RootState) => state.login )
    const matchesMid = useMediaQuery('(min-width: 480px)')

    const location = useLocation()

    const [dropDownOpen, setDropdownOpen] = React.useState({
        name : '',
        open : false
    });

    
    const [openDraw, setOpenDraw] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpenDraw(true);
    };
  
    const handleDrawerClose = () => {
      setOpenDraw(false);
    };


    const handleDropDownMenu = (name : string) => {
        handleDrawerOpen()
        if(dropDownOpen.name === name) {
            setDropdownOpen({...dropDownOpen, name : name, open : !dropDownOpen.open });
        } else {
            setDropdownOpen({...dropDownOpen, name : name, open : true });
        }
    };
    

    // dropdown menu 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    /* istanbul ignore next */
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    /* istanbul ignore next */
    const id = 2 ? 'simple-popover' : undefined;

    useEffect(() => {
        if(checkLoginSessionTime()) {
            onClickSignOut()
        }
        // eslint-disable-next-line
    }, []);


    const handleOnIdle = (event : any) => {
        onClickSignOut()
        getRemainingTime()
    }
    

    const { getRemainingTime } = useIdleTimer({
        timeout: 3600000,
        onIdle: handleOnIdle,
        debounce: 500
    })


    const drawerComponent = (
        <div>   
            <div className="sidebar-navbar" onMouseEnter={handleDrawerOpen} >
                <div>
                    { userCredentials.modules_menu.map((nav:any, index:any) => (
                    <div key={index}>
                    { nav.child_navigations.length === 0 ?
                    <>
                    <NavLink exact to={nav.module_detail.path} activeClassName="active-link" >
                        <ul>
                            <li className="navbar-list">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                    />
                                </div>
                                { openDraw ? <span>{nav.module_detail.name}</span> : null }
                            </li>
                        </ul>
                    </NavLink>
                    <Divider />
                    </>
                    : 
                    <>
                    <div className="sidebar-navbar-dropdown" onMouseEnter={() => handleDropDownMenu(nav.module_detail.name)} onMouseLeave={() => handleDropDownMenu(nav.module_detail.name)} >
                        <div className="menu-dropdown"  >
                            <Stack flexDirection="row">
                                <div className="icon">
                                    <Box
                                        component="img"
                                        src={nav.module_detail.iconImage}
                                    />
                                </div>
                                <Box ml={1} mr={1}>
                                    { openDraw ? <span>{nav.module_detail.name}</span> : null }
                                </Box>
                                <Box>
                                    { dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 
                                    <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                                </Box>
                            </Stack>
                        </div>
                        <div className={dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                            { nav.child_navigations.map((child:any, index:any) => (
                            <div key={index} >
                                <Divider />
                                <NavLink exact to={child.path} activeClassName="active-link" >
                                    <ul>
                                        <li className="navbar-list-child">
                                            <div className="icon">
                                                <Box
                                                    component="img"
                                                    src={child.iconImage}
                                                    sx={{ width: '23px', height: '23px' }}
                                                />
                                            </div>
                                            <span>
                                                { openDraw ? <span>{child.name}</span> : null }
                                            </span>
                                        </li>
                                    </ul>
                                </NavLink>
                                <Divider />
                            </div>
                            ))}
                        </div>
                    </div>
                    <Divider />
                    </>
                    }
                    </div>
                    ))}
                </div> 
                <Feedback disabled={userCredentials.isRegistered ? true : false}  openDraw={openDraw}/>
            </div>
    
        </div>
      );

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={openDraw}
            >
                <Toolbar>
                    { matchesMid? null :
                    <Box >
                        <NavDashboardMobile />
                    </Box>
                    }
                    { matchesMid?
                    <Box 
                        className="logo-mpi-p" 
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <a href="/dashboard">
                            <img 
                                alt="logo mpi dashboard" 
                                src={logop }
                            />
                        </a>
                    </Box>
                    :
                    <Toolbar>
                        <div className="logo-mpi" style={{ margin:'auto', display:'flex', justifyContent:'center'}}>
                            <a href="/dashboard">
                                <img 
                                    alt="logo mpi dashboard" 
                                    src={ logo }
                                />
                            </a>
                        </div>
                    </Toolbar>
                    }
                    { matchesMid?
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        // onClick={handleDrawerOpen}
                        onMouseEnter={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <DoubleArrowIcon 
                            sx={{color:'#fff'}} />
                    </IconButton>
                    : null}
                    <Box  sx={{ display: { xs: 'none', sm: 'none', lg : 'block' } }}>
                        <Stack 
                            pl={2} pt={1} 
                            flexDirection="row"
                            sx={{ cursor: 'pointer' }}
                            onClick={onClickRefresh}
                        >
                            <Box>
                                <AutorenewIcon />
                            </Box>
                            <Box>Reload</Box> 
                        </Stack>
                    </Box>
                    <div style={{flexGrow: 1}} />
                    <Box pr={4} sx={{ display: { xs: 'none', sm: 'none', lg : 'block' } }}>
                        <div>
                            <Online>
                                <Box
                                    sx={{
                                        border: '1px solid #fff',
                                        borderRadius: '4px',
                                        padding: '4px 20px',
                                        fontSize: '14px'
                                    }} 
                                
                                >You are Connected.</Box>
                            </Online>
                            <Offline>
                                <Box
                                    sx={{
                                        border: '1px solid red',
                                        backgroundColor: 'red',
                                        borderRadius: '4px',
                                        padding: '4px 20px',
                                        fontSize: '14px'
                                    }} 
                                >Disconnected from server, Please Check your internet!</Box>
                            </Offline>
                        </div>
                    </Box>
                    { location.pathname === "/register/step" || location.pathname === "/login/select-company" ? null : 
                    <Box pl={1}>
                        { !auth.login ? null :
                        <NotificationBell/>  }
                    </Box>
                    }
                    <Box>
                        <div 
                            className="right-navbar"  
                            data-testid="dropdownButton"
                            onClick={handleClick}
                        >
                            {/* <Box> <AccountCircleIcon/>  </Box> */}
                            <Box pl={matchesMid? 1 : undefined}> {auth?.data?.fullname}</Box>
                            <Box pl={matchesMid? 1 : undefined}> <ArrowDropDownIcon/></Box>
                        </div>
                        {/* Dropdown Menu */}
                        <Popover
                            data-testid="dropdown"
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className="popper-style"
                        >
                        <Box>
                            <div style={{ width: 150 }}>
                                <Card>
                                    <Box p={1}>
                                        <Box pt={1} pb={1} fontSize="14px">
                                            You login as <br/> 
                                            <span style={{ fontWeight: 500 }}>{userCredentials.role.name}</span>
                                        </Box>
                                        <Button 
                                            color="error"
                                            variant="outlined" fullWidth 
                                            size='small'
                                            sx={{mb:1}}
                                            onClick={onClickSignOut}
                                        >
                                            Logout
                                        </Button>
                                    </Box>
                                </Card>
                            </div>
                        </Box>
                        </Popover>
                    </Box>
                </Toolbar>
            </AppBar>
            { matchesMid?
            <Drawer
                variant="permanent"
                open={openDraw}
                onMouseLeave={handleDrawerClose}
            >
                <DrawerHeader sx={{ backgroundColor: '#0091d6', display:'flex', justifyContent:'left'  }}>
                    <Toolbar>
                        <div className="logo-mpi">
                            <a href="/dashboard">
                                <img 
                                    alt="logo mpi dashboard" 
                                    src={ logo }
                                />
                            </a>
                        </div>
                    </Toolbar>
                    {/* <IconButton>
                       <ChevronLeftIcon sx={{color:'#fff'}} />
                    </IconButton> */}
                </DrawerHeader>
                <Divider />
                {drawerComponent}
            </Drawer>
            : null}
        </div>
    )
}

export default NavDashboard;
