import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Button,
    Stack,
    Skeleton
} from '@mui/material';
import { remove } from 'lodash'
import Select from 'react-select'
import axios from 'axios'
import swal from 'sweetalert';
const CoverageItem : React.FC<any> = ({
    coverageItems,
    setCoverageItems,
    optionsProvince,
    loadingProvince
}) => {

     const handleChangeProvince = (value: any, index : any) : void => {
        let copy_array = [...coverageItems]
        if(copy_array.some(item => item.province_id === value.value)) {
            swal('Duplicate', `Province ${value.label} already selected!`, 'error')
        } else {
            const newData = copy_array.map((obj : any, i : any) => {
                if(i === index)
                return {
                    ...obj,
                    loading_city : true,
                }
                return obj
            });
            setCoverageItems(newData)
            getDataCity(value, index)
        }

    }

    const proceedCity = (params : any, index :any, value:any) => {
        let copy_array = [...coverageItems]
        let city : any = []
        for(let prov of params) {
            city.push({
                value : prov.id,
                label : prov.nama,
            })
        }
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                loading_city : false,
                city_options : city,
                city : city,
                province : value.label,
                province_id: value.value,
                selected_province : [{ label: value.label, value : value.value }]
            }
            return obj
        });
        setCoverageItems(newData)
    }


    const getDataCity = async (value : any, index: any) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/region/kota?id_provinsi=${value.value}`)
            if(response.data) {
                await proceedCity(response.data, index, value)
            } else {
                swal('Error', `${response.error}`, 'error')
            }
            
        } catch (error) {
            swal('Error', `${error}`, 'error')
        }
    }


    const handleChangeCity = (value: any, index : any) : void => {
        let copy_array = [...coverageItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                city: value,
            }
            return obj
        });
        setCoverageItems(newData)
    }

    const onClickAddNewField = () => {
        let copy_item = [...coverageItems]
        let new_object = {
            province : "",
            province_id: "",
            city : [],
            selected_province: [],
            city_options : [],
            loading_city : false
        }
        copy_item.push(new_object)
        setCoverageItems(copy_item)
    }

    const onClickRemoveItem = (value : any) => {
        let copy_item = [...coverageItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setCoverageItems(items_remove)
    }


  return (
    <Box>
        <Stack>
          <Box mb={2}>
            <Button 
                variant="outlined" color="error"
                size="small"
                onClick={() => onClickAddNewField()}
            >
              Add More Coverage
            </Button>
          </Box>
        </Stack>
        <TableContainer component={Paper} style={{ minHeight: 500 }} >
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700, width: '270px' }}>Province</TableCell>
                    <TableCell style={{fontWeight: 700, }}>City</TableCell>
                    <TableCell style={{fontWeight: 700,}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { coverageItems.map((row :any, i : any) => (
                <TableRow key={i} >
                    <TableCell component="th" scope="row">
                        { loadingProvince ? 
                        <Skeleton height={60} /> :
                        <Box>
                            <Select
                                placeholder="Select Province"
                                value={row.selected_province}
                                options={optionsProvince && optionsProvince}
                                onChange={(e) => handleChangeProvince(e,i)}
                                id={`select-style-coverage-${i}`}
                            />
                        </Box>  }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.loading_city ? 
                        <Skeleton height={60} /> :
                        <Box>
                            { row.city_options.length === 0 ? 
                            <Select
                                placeholder="Please select province first"
                                id={`select-style-coverage-${i}`}
                            /> 
                            :
                            <Select
                                placeholder="You can select multiple city"
                                value={row.city}
                                isSearchable={true}
                                onChange={(e) => handleChangeCity(e, i)}
                                isMulti
                                options={row.city_options}
                                id={`select-style-coverage-${i}`}
                            />  }
                        </Box> 
                        }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button 
                            color="error" 
                            onClick={() => onClickRemoveItem(i)}
                        >Remove</Button>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default CoverageItem