/* istanbul ignore file */
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { 
  Route, 
  Redirect,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import NavDashboard from '../components/NavDashboard'
import { userCredentials } from './config';
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  component : React.ElementType;
  path?: string | string[];
  exact?: boolean;
}

const  DashboardLayout : React.FC<Props> = ({ component : Component, ...rest }) => {
  const auth = useSelector((state : RootState) => state.login.login)
  const location = useLocation()
  const mobileDisplay = useMediaQuery('(minWidth:480px)')
  
  const checkModule = () => {
    let access = false
    if(userCredentials.modules_path.find((ele:any) => ele === rest.path)) {
      access = true
    } else {
      access = false
    }
    return access
  }

  const isAuthenticated = () => {
    if(auth && checkModule()) {
        return auth
    } else {
        return false
    }
  }


  
  function getStylePage () {
    switch(location.pathname) {
      case "/dashboard" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5'
        }
      }
      case "/dashboard/setting/media-center" : {
        return { 
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5'
        }
      }
      default: {
        return { 
          display: 'flex'
        }
      }
    }      
  }

  return (

    <Route {...rest} 
        render={props => {
            if(isAuthenticated()){
                return (
                  <Box sx={getStylePage()}>
                    <NavDashboard />
                      <Box component="main" sx={mobileDisplay?{ flexGrow: 1, p: 3 } : { flexGrow: 1, pt: 3, pr:1, pl:1, pb:3 }}>
                        <Toolbar />
                        <Component {...props} />
                      </Box>
                  </Box>
                )
            }
            else {
                return (
                    <Redirect 
                        to={{
                            pathname: "*",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        }}
    />
    
  );
}

export default DashboardLayout;