import React, { useEffect, useState } from 'react'
import  Box  from '@mui/system/Box'
import BreadCrumbs from '../../../components/BreadCrumbs'
import Badge from '@mui/material/Badge'
import Tabs from '@mui/material/Tabs'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardOrder from './components/CardOrder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { 
  getDataPurchaseOrders,
  getDataPurchaseOrdersDP,
  getPurchaseOrdersData, 
  getPurchaseOrdersStatus, 
  getPurchaseOrdersWaitingDP 
} from './reducers/purchaseOrdersReducers';
import CardOrderDP from './components/CardOrderDP';
import SearchData from './components/SearchData';
import { useHistory, useLocation } from 'react-router-dom';
import EmptyData from '../../../components/EmptyData';
import  find  from 'lodash/find';
import SearchDataDP from './components/SearchDataDP';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import SnackBarMessage from '../../../components/SnackBarSuccess';
import { useMediaQuery } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const array_tabs = [
  { label: "Ready to Process", value: "New" },
  { label: "Pick & Pack", value: "Pick" },
  { label: "Ready to Ship", value: "Ready to Ship" },
  { label: "Shipped", value: "Shipped" },
  { label: "Delivered", value: "Delivered" },
  { label: "Paid", value: "Paid" },
  { label: "Canceled", value: "Cancel" },
]


const OrdersPage = () => {
    const location : any = useLocation()
    const history : any = useHistory()
    const dispatch = useDispatch()

    const { 
      data_orders,
      loading_data_orders,
      data,
      loading,
      data_dp, 
      loading_dp,
      data_status,
      pick,
      pack,
      delivery,
      confirm,
      count_dp,
      data_dp_all,
      loading_dp_all, 
    } = useSelector((state : RootState) => state.purchase_orders)    
    
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('status')
    const mobileDisplay = useMediaQuery('(min-width:480px)')

    const [stateLocation, setStateLocation] = useState<any>([]);
    const [value, setValue] = React.useState<any>(0);
    const [activeTab, setActiveTab] = useState<any>("Waiting DP");

    const [dataSearch, setDataSearch] = useState<any>({ 
      searchTopic : ['lastStatus'], 
      searchValue: [],
      rangeTopic : "",
      rangeFrom: "",
      rangeTo: "",
      limit: "20",
      skip: "0",
      search : false
    });

    const [waitingDPSearch, setWaitingDPSearch] = useState<any>({ 
      searchTopic : [], 
      searchValue: [],
      rangeTopic : "",
      rangeFrom: "",
      rangeTo: "",
      limit: "20",
      skip: "0",
      search : false
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setStateLocation([])
      history.push({
        pathname: "/dashboard/orders"
      })
      setValue(newValue);
    };

    function getDataList(data:any, status :string) {
      let data_package = data.filter((key : any) => key.lastStatus === status)
      return data_package
    }

    function getDataListStatus(data:any, status :string) {
      let count = 0
      let order_status = find(data, { "_id": status })
      if(order_status !== undefined) {
        count = order_status.count
      } 
      return count
    }

    const scrollToTop = () => {
      // @ts-ignore
      document.getElementById("scrollableDiv").scrollTo({
          top: 0,
          behavior: "smooth",
      });
    };

    useEffect(() => {
      if(active_page === "Waiting DP" ) {
        setValue(0)
        setActiveTab(active_page)
      } else if(active_page === "New" ) {
        setValue(1)
        setActiveTab(active_page)
      } else if(active_page === "Pick" ) {
        setValue(2)
        setActiveTab(active_page)
      } else if(active_page === "Ready to Ship" ) {
        setValue(3)
        setActiveTab(active_page)
      } else if(active_page === "Shipped" ) {
        setValue(4)
        setActiveTab(active_page)
      } else if(active_page === "Delivered" ) {
        setValue(5)
        setActiveTab(active_page)
      } else if(active_page === "Paid" ) {
        setValue(6)
        setActiveTab(active_page)
      } else if(active_page === "Cancel" ) {
        setValue(7)
        setActiveTab(active_page)
      } else {
        setValue(1)
      }
    }, [active_page]);

    useEffect(() => {
      if(value === 0) {
        if(stateLocation.length !== 0) {
          setWaitingDPSearch({...waitingDPSearch, 
            searchTopic: ['code_po'],
            searchValue: [stateLocation.orderCode],
            run : true
          })
        } else {
          setWaitingDPSearch({...waitingDPSearch, 
            searchTopic: [],
            searchValue: [],
            run : true
          })
        }
      }
      if(value === 1) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'New']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['New']
          })
        }
      }
      if(value === 2) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Pick']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Pick']
          })
        }
      }
      if(value === 3) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Ready to Ship']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Ready to Ship']
          })
        }
      }
      if(value === 4) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Shipped']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Shipped']
          })
        }
      }
      if(value === 5) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Delivered']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Delivered']
          })
        }
      }
      if(value === 6) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Paid']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Paid']
          })
        }
      }
      if(value === 7) {
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Cancel']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Cancel']
          })
        }
      }
      // eslint-disable-next-line
    }, [value, stateLocation]); 

    useEffect(() => {
      dispatch(getPurchaseOrdersData()) 
      dispatch(getPurchaseOrdersWaitingDP())
      dispatch(getPurchaseOrdersStatus())
      // eslint-disable-next-line
    }, []); 

    useEffect(() => {
      if(dataSearch.searchValue.length) {
        dispatch(getDataPurchaseOrders(dataSearch))
      }
      dispatch(getPurchaseOrdersStatus())
      // eslint-disable-next-line
    }, [dataSearch]);   

    useEffect(() => {
      if(waitingDPSearch.run) {
        dispatch(getDataPurchaseOrdersDP(waitingDPSearch))
      }
      dispatch(getPurchaseOrdersWaitingDP())
      // eslint-disable-next-line
    }, [waitingDPSearch]); 

    useEffect(() => {
      if(location.state !== null && location.state !== undefined) {
        setStateLocation(location.state.payload)
      }
    }, [location]);

    function getStyle (val : any) {
      return val === value ? '#0091d6' : '#fff'
    }
    
    const BadgeLabel = ({name, total, index} : any) => {
      return (
        <Badge 
          color={total === 0 ? "info" : "error"}
          badgeContent={total === 0 ? '' : total}  
        >
          <Box 
            fontSize={12} 
            fontWeight="bold" 
            sx={{ 
              mr: 1, 
              backgroundColor: index === value ? '#fff' : '',
              padding: '5px 10px',
              borderRadius: '5px',
              fontFamily: 'Roboto', 
              color: getStyle(index) 
            }}
          >{name}</Box>
        </Badge>
      )
    }   
    
    return (
      <div id="scrollableDiv" style={{ height: '85vh', overflow: "auto" }} className="overhiddenflow" >
        <Box sx={mobileDisplay?{pt:2, pl:3, pr:3} : {pt:2, pl:1, pr:1}} >
            <BreadCrumbs 
                isPage={false}
                current="Orders Page"
            />
           <Box sx={{pt:2}}>
                <h3>Orders List</h3>
           </Box>
           <Box sx={mobileDisplay?{ mt: 2 } : { mt:2, margin:'auto'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#0091d6'  }}>
                    <Tabs 
                      value={value} 
                      onChange={handleChange} 
                      aria-label="basic tabs example" 
                      color="success" sx={mobileDisplay? { paddingTop:'2px'} : {paddingTop: '2px', width:'280px', margin:'auto'}}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                        <Tab 
                          label={
                            <BadgeLabel 
                              name="Waiting DP" 
                              total={count_dp && count_dp} 
                              index={0} 
                            />
                        } {...a11yProps(0)} 
                        />
                        { array_tabs.map((val,idx) => (
                          <Tab 
                            key={idx}
                            label={
                              <BadgeLabel 
                                name={val.label} 
                                total={getDataListStatus(data_status && data_status, val.value)} 
                                index={idx+1} 
                              />
                            } 
                            {...a11yProps(idx+1)} 
                          />
                        )) }
                    </Tabs>
                </Box>
                <Paper>
                  <TabPanel value={value} index={0} > 
                    <Box>
                      <SearchDataDP 
                        data={data_dp_all && data_dp_all} 
                        progressPending={loading_dp_all}
                        activeTab={activeTab}
                        status="Waiting Down Payment"
                        waitingDPSearch={waitingDPSearch} 
                        setWaitingDPSearch={setWaitingDPSearch}
                      />
                    </Box>
                    { loading_dp ? <LoaderBackdrop loading={loading_dp}/> :
                      <>
                      { data_dp && 
                        <Box  fontSize={12}>
                          { !loading_dp && data_dp.length === 0 ? 
                            <EmptyData 
                              message={`Data order with status Waiting DP not found!`} 
                            /> : 
                            <CardOrderDP 
                              data={data_dp} 
                              waitingDPSearch={waitingDPSearch} 
                              setWaitingDPSearch={setWaitingDPSearch}
                            />
                          }
                        </Box> 
                      }
                      </>
                    }
                  </TabPanel>
                  { array_tabs.map((val, idx) => (
                    <TabPanel value={value} index={idx+1} key={idx}>
                      <Box>
                        <SearchData 
                          data={getDataList(data && data, val.value)} 
                          progressPending={loading}
                          activeTab={activeTab}
                          status={val.value}
                          dataSearch={dataSearch} 
                          setDataSearch={setDataSearch}
                        />
                      </Box>
                      { loading_data_orders ? <LoaderBackdrop loading={loading_data_orders} /> :
                        <>
                        { data_orders && 
                          <Box mt={2}>
                            { !loading_data_orders && data_orders.length === 0 ? 
                              <EmptyData 
                                message={`Data order with status ${val.label} not found!`} 
                              /> : 
                              <Box>
                                <CardOrder data={data_orders} stateLocation={stateLocation} status={val.label} dataSearch={dataSearch} setDataSearch={setDataSearch} />
                              </Box>
                            }
                          </Box> 
                        }
                        </>
                      }
                    </TabPanel>
                  ))}
                </Paper>
           </Box>
        </Box>
        <Box
          onClick={scrollToTop}
          role="presentation"
          sx={{ 
            position: 'fixed', bottom: 20, right: 40
          }}
        >
          <Fab 
            size="small" 
            aria-label="scroll back to top" 
            sx={{ backgroundColor: '#0091d6', }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box> 
        <SnackBarMessage load={pick} message="Success created Pick Package" />
        <SnackBarMessage load={pack} message="Success created Pack Package" />
        <SnackBarMessage load={delivery} message="Success created Delivery Note" />
        <SnackBarMessage load={confirm} message="Success confirm down payment" />
      </div> 
    )
}

export default OrdersPage;
