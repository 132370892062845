import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { changeDefaultBankAccount, checkInquiryAccount, getAllMasterBank, getBankAccount, getProfileCompany, postBankAccount, postStoreProfile, removeBankAccount } from './reducers/profilesReducers';

const initialState: any = {
  data: [], 
  loading : true,
  bank : [],
  master_bank : [],
  loading_master_bank: false,
  loaded_master_bank: false,
  inquiry : {},
  loaded_inquiry: false,
  loading_inquiry: false,
  loading_bank : false,
  bank_post : false,
  loading_bank_post : false,
  bank_remove : false,
  loading_bank_remove : false,
  bank_default : false,
  loading_bank_default : false,
  store_profile : false,
  loading_store_profile : false
};

export const getCompanyProfile = createSlice({
  name: 'profile/get',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get profile
      .addCase(getProfileCompany.pending, (state) => {
        state.loading = true;
        state.loaded_master_bank = false
      })
      .addCase(getProfileCompany.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getProfileCompany.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // get master bank
       .addCase(getAllMasterBank.pending, (state) => {
        state.loading_master_bank = true;
        state.loaded_master_bank = false
      })
      .addCase(getAllMasterBank.fulfilled, (state, action:any) => {
        state.loading_master_bank = false;
        state.master_bank = action.payload.data;
        state.loaded_master_bank = true
      })
      .addCase(getAllMasterBank.rejected, (state, action : any) => {
        state.loading_master_bank = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // post inquiry
      .addCase(checkInquiryAccount.pending, (state) => {
        state.loading_inquiry = true;
        state.loaded_inquiry = false
      })
      .addCase(checkInquiryAccount.fulfilled, (state, action:any) => {
        state.loading_inquiry = false;
        state.inquiry = action.payload.data;
        state.loaded_inquiry = true
      })
      .addCase(checkInquiryAccount.rejected, (state, action : any) => {
        state.loading_inquiry = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get bank
      .addCase(getBankAccount.pending, (state) => {
        state.loading_bank = true;
        state.bank_post = false;
        state.bank_remove = false;
        state.bank_default = false;
        state.loaded_master_bank = false;
        state.loaded_inquiry = false
      })
      .addCase(getBankAccount.fulfilled, (state, action:any) => { 
        state.loading_bank = false;
        state.bank = action.payload.data;
      })
      .addCase(getBankAccount.rejected, (state, action : any) => {
        state.loading_bank = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post bank
      .addCase(postBankAccount.pending, (state) => {
        state.loading_bank_post = true;
      })
      .addCase(postBankAccount.fulfilled, (state, action:any) => {
        state.loading_bank_post = false;
        state.bank_post = action.payload.data;
      })
      .addCase(postBankAccount.rejected, (state, action : any) => {
        state.loading_bank_post = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // remove bank
      .addCase(removeBankAccount.pending, (state) => {
        state.loading_bank_remove = true;
      })
      .addCase(removeBankAccount.fulfilled, (state, action:any) => {
        state.loading_bank_remove = false;
        state.bank_remove = action.payload.data;
      })
      .addCase(removeBankAccount.rejected, (state, action : any) => {
        state.loading_bank_remove = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // default bank
      .addCase(changeDefaultBankAccount.pending, (state) => {
        state.loading_bank_default = true;
      })
      .addCase(changeDefaultBankAccount.fulfilled, (state, action:any) => {
        state.loading_bank_default = false;
        state.bank_default = action.payload.data;
      })
      .addCase(changeDefaultBankAccount.rejected, (state, action : any) => {
        state.loading_bank_default = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // default bank
      .addCase(postStoreProfile.pending, (state) => {
        state.loading_store_profile = true;
      })
      .addCase(postStoreProfile.fulfilled, (state, action:any) => {
        state.loading_store_profile = false;
        state.store_profile = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postStoreProfile.rejected, (state, action : any) => {
        state.loading_store_profile = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export default getCompanyProfile.reducer;
