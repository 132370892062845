import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { 
  getDataPurchaseOrders,
  getDataPurchaseOrdersDP,
  getPurchaseOrdersBySearch,
  getPurchaseOrdersData, getPurchaseOrdersStatus, 
  getPurchaseOrdersWaitingDP, 
  postConfirmationDP, postDeliveryNotePurchaseOrders, postPackPurchaseOrders, postPickPurchaseOrders
} from './reducers/purchaseOrdersReducers';


const initialState: any = {
  data_status : [],
  loading_data_status : false,
  data: [], 
  loading : true,
  data_orders: [],
  loading_data_orders : false,
  count_dp : 0,
  data_dp : [],
  loading_dp : true,
  data_dp_all : [],
  loading_dp_all : false, 
  loading_pick : false,
  loading_pack : false,
  loading_shippingCost : false,
  shipping_cost : false,
  pick : false,
  pack : false,
  delivery : false,
  error : null,
  error_pick : null,
  error_pack : null,
  error_shippingCost : null,
  loading_delivery : null,
  error_dp : null,
  confirm : false,
  loading_confirm : false,
  error_confirm : null,
  error_delivery : null,
};

export const getPurchaseOrders = createSlice({
  name: 'purchase-orders',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get data purchase orders 
      .addCase(getDataPurchaseOrders.pending, (state) => {
        state.loading_data_orders = true;
        state.confirm = false
      })
      .addCase(getDataPurchaseOrders.fulfilled, (state, action:any) => {
        state.loading_data_orders = false;
        state.data_orders = action.payload.data;
      })
      .addCase(getDataPurchaseOrders.rejected, (state, action : any) => {
        state.loading_data_orders = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get data purchase orders status
      .addCase(getPurchaseOrdersStatus.pending, (state) => {
        state.loading_data_status = true;
        state.pick = false;
        state.pack = false;
        state.delivery = false;
      })
      .addCase(getPurchaseOrdersStatus.fulfilled, (state, action:any) => {
        state.loading_data_status = false;
        state.data_status = action.payload.data;
      })
      .addCase(getPurchaseOrdersStatus.rejected, (state, action : any) => {
        state.loading_data_status = false;
        swal("Error", `${action.payload}`, 'error')
      })
      

      // get purchase orders from db
      .addCase(getPurchaseOrdersData.pending, (state) => {
        state.loading = true;
        state.confirm = false
      })
      .addCase(getPurchaseOrdersData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseOrdersData.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get purchase orders from db
      .addCase(getPurchaseOrdersBySearch.pending, (state) => {
        state.loading = true;
        state.confirm = false
      })
      .addCase(getPurchaseOrdersBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseOrdersBySearch.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // post pick purchase request
      .addCase(postPickPurchaseOrders.pending, (state) => {
        state.loading_pick = true;
      })
      .addCase(postPickPurchaseOrders.fulfilled, (state, action:any) => {
        state.loading_pick = false;
        state.pick = action.payload.data;
      })
      .addCase(postPickPurchaseOrders.rejected, (state, action : any) => {
        state.loading_pick = false;
        state.error_pick = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })


      // post pack purchase request
      .addCase(postPackPurchaseOrders.pending, (state) => {
        state.loading_pack = true;
      })
      .addCase(postPackPurchaseOrders.fulfilled, (state, action:any) => {
        state.loading_pack = false;
        state.pack = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postPackPurchaseOrders.rejected, (state, action : any) => {
        state.loading_pack = false;
        state.error_pack = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // post delivery note purchase request
      .addCase(postDeliveryNotePurchaseOrders.pending, (state) => {
        state.loading_delivery = true;
      })
      .addCase(postDeliveryNotePurchaseOrders.fulfilled, (state, action:any) => {
        state.loading_delivery = false;
        state.delivery = action.payload.data;
      })
      .addCase(postDeliveryNotePurchaseOrders.rejected, (state, action : any) => {
        state.loading_delivery = false;
        state.error_delivery = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get all waiting dp
      .addCase(getPurchaseOrdersWaitingDP.pending, (state) => {
        state.loading_dp_all = true;
        state.confirm = false
      })
      .addCase(getPurchaseOrdersWaitingDP.fulfilled, (state, action:any) => {
        state.loading_dp_all = false;
        state.data_dp_all = action.payload.data;
        state.count_dp = action.payload.count_dp;
      })
      .addCase(getPurchaseOrdersWaitingDP.rejected, (state, action : any) => {
        state.loading_dp_all = false;
        state.error_dp = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get all dp data for search
      .addCase(getDataPurchaseOrdersDP.pending, (state) => {
        state.loading_dp = true;
        state.confirm = false
      })
      .addCase(getDataPurchaseOrdersDP.fulfilled, (state, action:any) => {
        state.loading_dp = false;
        state.data_dp = action.payload.data;
      })
      .addCase(getDataPurchaseOrdersDP.rejected, (state, action : any) => {
        state.loading_dp = false;
        state.error_dp = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get all waiting dp by search
      // .addCase(getPurchaseOrdersWaitingDPBySearch.pending, (state) => {
      //   state.loading_dp = true;
      // })
      // .addCase(getPurchaseOrdersWaitingDPBySearch.fulfilled, (state, action:any) => {
      //   state.loading_dp = false;
      //   state.data_dp = action.payload.data;
      // })
      // .addCase(getPurchaseOrdersWaitingDPBySearch.rejected, (state, action : any) => {
      //   state.loading_dp = false;
      //   state.error_dp = action.payload; 
      //   swal("Error", `${action.payload}`, 'error')
      // })

      // confirmation dp
      .addCase(postConfirmationDP.pending, (state) => {
        state.loading_confirm = true;
      })
      .addCase(postConfirmationDP.fulfilled, (state, action:any) => {
        state.loading_confirm = false;
        state.confirm = action.payload.data;
      })
      .addCase(postConfirmationDP.rejected, (state, action : any) => {
        state.loading_confirm = false;
        state.error_confirm= action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});

export default getPurchaseOrders.reducer;
