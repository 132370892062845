import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    Box,
    TextField,
    Backdrop,
    CircularProgress,
    Stack,
    Skeleton  
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { changeStep } from './stepFormSlice';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material/styles';
import { postLegalDocument } from '../reducers/stepRegisterReducers';
import { RootState } from '../../../../app/store';
import { userCredentials } from '../../../../utilities/config';
import { getAllModulesMenu, getAllModulesPath } from '../../../../utilities/setModulesPath';
import IconButton from '@mui/material/IconButton';
import crypto from 'crypto-js'; 

const Input = styled('input')({
    display: 'none',
  });

const FormLegalDocument : React.FC<any> = ({
    profile
}) => {

      
    const dispatch = useDispatch()
    const state_stepregister = useSelector((state : RootState) => state.step_register)

    const [legalDocState, setLegalDocState] = useState<any>([]);

    const [updateCredential, setUpdateCredential] = useState<any>(userCredentials);
    const [updated, setUpdated] = useState(false);

    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState("");

    const onChangeValue = (index : any, value : any) => {
        let copyState = [...legalDocState]
        const newData = copyState.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                [value.target.name]: value.target.value,
            }
            return obj
        });
        setLegalDocState(newData)
    }

    const onChangeValueFile = (index : any, e : any) => {
        setLoading(true)
        setIndex(index)
        const copyState = [...legalDocState]
        const random = uuid();
        setTimeout(() => {
            var S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
                endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
        
            if (e.target.files && e.target.files[0]) {
            const blob = e.target.files[0]
            const file_name = blob.name.replace(/\s/g, "")
            const params = { Body: blob, 
                            Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                            Key: 'vendor/' + random + file_name
                            };
            s3bucket.putObject(params)
            .on('build', (request : any) => {
                request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                request.httpRequest.headers['Content-Length'] = blob.size;
                request.httpRequest.headers['Content-Type'] = blob.type;
                request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
            })
            .send((err : any, data : any) => {
                if (err){  
                    console.log(err, err.stack,);
                } 
                else {      
                    const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${random}${file_name}`
                    const newData = copyState.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            url : imageUrl,
                        }
                        return obj
                    });
                    setLegalDocState(newData)
                    setLoading(false)
                }}
            )} 
        }, 1000);
    }

    const onClickNext = (e : any) => {
        e.preventDefault()
        const body_send = {
            _id: userCredentials.vendor_id,
            legal_docs: legalDocState
        }
        dispatch(postLegalDocument(body_send))
        localStorage.setItem('legal_document', JSON.stringify(legalDocState))
    }

    useEffect(() => {
        if(state_stepregister.legal_document) {
            dispatch(changeStep(2))
        }
        // eslint-disable-next-line
    }, [state_stepregister.legal_document])

    const proceedState = () => {
        const master_legaldoc = localStorage.getItem('legal_doc_company')
        const data : any = master_legaldoc === null ? [] : JSON.parse(master_legaldoc)
        let state = []
        for(const element of data) {
            state.push({
                id: element.short_title,
                title: element.title,
                short_title : element.short_title,
                value : "",
                url : "",
                error : false
            })
        }
        setLegalDocState(state)
    }

    useEffect(() => {
        const local_data = localStorage.getItem('legal_document')
        const checkLocalData = () => {
            const data : any = local_data === null ? null : JSON.parse(local_data)
            setLegalDocState(data)
        }
        if(local_data === null) {
            proceedState()
        } else {
            checkLocalData()
        }
        // eslint-disable-next-line
    }, []);

    
    useEffect(() => {
        if(state_stepregister.legal_document) {
            setUpdateCredential({...updateCredential, 
                first_time : false, 
                modules_path : getAllModulesPath(false, updateCredential.company_status, updateCredential.role),  
                modules_menu : getAllModulesMenu(false, updateCredential.company_status, updateCredential.role),  
            })
            setUpdated(true)
        }
        // eslint-disable-next-line
    }, [state_stepregister.legal_document])

    useEffect(() => {
        if(updated) {
            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(updateCredential), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
            localStorage.setItem('_?credentials', saveToLocalStorage)
            setTimeout(() => {
                window.location.href = '/dashboard'
            }, 1000);
        }
        // eslint-disable-next-line
    }, [updated]);


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state_stepregister.loading_legal_document}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Box mt={2} pl={2} pb={2}>
                <h3>Legal Document Company </h3>
            </Box> 
           <div className="section-form-company-detail">
               <form onSubmit={(e) => onClickNext(e)}>
                <Box pl={2}>
                { legalDocState.length === 0  ? 
                    <Box>To view input legal document you need to select company type on step 1.</Box>
                    :
                    <Box>
                    {legalDocState?.map((val : any, i :any) => (
                        <Box key={i}>
                            <Grid container >
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={2}><h4>{val.title}</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField
                                        margin="dense"
                                        fullWidth
                                        label={`Number ${val.title}`}
                                        value={val.value}
                                        name="value"
                                        size="small"
                                        onChange={(e) => onChangeValue(i, e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={2}><h4>Upload {val.short_title}</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loading && i === index ? 
                                    <Skeleton 
                                        variant="rectangular" 
                                        height={30} 
                                        sx={{ borderRadius: '4px', mt: 2 }}
                                    /> :
                                    <Box>
                                        { val.url === "" ?
                                        <Box>
                                            <label htmlFor="icon-button-files" style={{ border: '1px solid #ccc', padding: '10px' }}>
                                                <Input accept="application/pdf,image/*" id="icon-button-files" type="file"  onChange={(e) => onChangeValueFile(i,e)} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                    <span>Upload File</span>
                                                </IconButton>
                                            </label>
                                        </Box>
                                        :
                                        <Stack flexDirection="row" alignItems="center" >
                                            <Box pt={1} pr={2}>
                                                <a href={val.url} target="_blank" rel="noreferrer">Document Link</a>
                                            </Box>
                                            <Box
                                                sx={{
                                                    color: 'red',
                                                    cursor: 'pointer',
                                                    fontSize: 12,
                                                    pl: 1,
                                                    pt : 1
                                                }}
                                                onClick={() => onChangeValue(i,{target : {value : "", name : "url"}})}
                                            >Change</Box>
                                        </Stack> }
                                    </Box> }
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                    </Box>
                    }
                   
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, pb: 2 }}>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => { 
                                // dispatch(defaulCompanyDetail())
                                dispatch(changeStep(1))
                            }} 
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button 
                            variant="contained"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Box> 
                </Box>
                </form>
           </div>
        </div>
    )
}

export default FormLegalDocument
