import {
    Container,
    CssBaseline,
    Stack,
    Button,
    Box
} from '@mui/material';
import notfound from '../../assets/img/notfound.png'
import logo from '../../assets/img/logo-color.png'

const Error404 = () => {

    return (
      <div className="container-box">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
            <Stack sx={{ 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box>
                    <a href='/'>
                        <img src={logo} alt="logo" width="150px" />
                    </a>
                </Box>
                <div className="img-notfound"> 
                  <img alt="notfound" src={notfound} />
                </div>
                <div className="text-notfound">
                  <Button 
                    variant="contained"
                    href="/"
                    size="small"
                  >Back to homepage</Button>
                </div>
                
            </Stack>
        </Container>
      </div>
    )
}

export default Error404;
