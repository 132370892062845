import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getAllWarehouse = createAsyncThunk(
    'warehouse/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/warehouse`, {
                params : {
                    "vendor_id": userCredentials.vendor_id
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postWarehouse = createAsyncThunk(
    'warehouse/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/warehouse`, body , {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateWarehouse = createAsyncThunk(
    'warehouse/update',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/warehouse`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

