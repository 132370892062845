import { Alert, Button, Stack, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import crypto from 'crypto-js'; 
import { RootState } from '../../../../app/store'
import { userCredentials } from '../../../../utilities/config'
import { checkStatusCompany } from '../reducers/homeDashboardReducers'
import { getAllModulesMenu, getAllModulesPath } from '../../../../utilities/setModulesPath';

function UnverifiedWelcome() {

    const dispatch = useDispatch()
    const store_dashboard = useSelector((state : RootState) => state.dashboard)

    const [updateCredential, setUpdateCredential] = useState<any>(userCredentials);
    const [updated, setUpdated] = useState(false);


    const username = userCredentials === null ? "" : userCredentials.fullname
    const company = userCredentials === null ? "" : userCredentials.buyer_name

    useEffect(() => {
        if(store_dashboard.status.length !== 0) {
            if(store_dashboard.status.verification_status === "UNVERIFIED") {
                swal('Waiting Verify', "Your Company Status is still Unverified, Please contact our administrator for further information.")
            } else {
                swal('Yeay!', "Congratulation, you are verified.", "success")
                setUpdateCredential({...updateCredential, 
                    company_status : "VERIFIED",
                    modules_path : getAllModulesPath(false, "VERIFIED", updateCredential.role),
                    modules_menu : getAllModulesMenu(false, "VERIFIED", updateCredential.role),    
                })
                setUpdated(true)
            }
        }
        // eslint-disable-next-line
    }, [store_dashboard.status]);

    useEffect(() => {
        if(updated) {
            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(updateCredential), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
            localStorage.setItem('_?credentialss', saveToLocalStorage)
            setTimeout(() => {
                window.location.href = '/dashboard'
            }, 1000);
        }
        // eslint-disable-next-line
    }, [updated]);

    return (
        <Box sx={{pl:3, pr:3, pt:2}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                    <h3>Welcome back, {username}</h3>
                    <Box fontWeight="400">{company}</Box>
                </Box>
            </Stack>

            <Stack sx={{pt:5}} >
                <Alert severity="info">
                    Thank you for completing register your company. Please wait, we will check yor legal document for verification. 
                    <Button 
                        sx={{ml:1}} size="small" color="warning"
                        onClick={() => dispatch(checkStatusCompany()) }
                    >{ store_dashboard.loading_status ? "Loading.." : "Check status" }</Button>
                </Alert>
            </Stack>
        </Box>
    )
}

export default UnverifiedWelcome
