import { Box, Skeleton } from '@mui/material';

const TotalSales : React.FC<any> = ({ data }) => {

    const stylesHeaderToday : any = {
        border: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: '#64b5f6'
    }
    const stylesHeaderMonth : any = {
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        borderBottom: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: '#64b5f6'
    }
    const stylesHeaderYear : any = {
        borderRight: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderBottom: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: '#64b5f6'
    }
    const stylesColumn : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        pt: 1,
        height : '40px',
    }

    return (
        <Box pt={2}>
            { data.loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
                <Box>
                    <Box sx={stylesHeaderToday} >Total Sales Today</Box>
                    { data.data.sales_today ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.sales_today.length ? data.data.sales_today[0].totalAmount.toLocaleString() : 0}</Box> : null }
                    <Box sx={stylesHeaderMonth} >Total Sales This Month</Box>
                    { data.data.sales_month ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.sales_month.length ? data.data.sales_month[0].totalAmount.toLocaleString() : 0}</Box> : null }
                    <Box sx={stylesHeaderYear} >Total Sales This Year</Box>
                    { data.data.sales_year ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.sales_year.length ? data.data.sales_year[0].totalAmount.toLocaleString() : 0}</Box> : null }
                </Box>
            }
        </Box>
    )
}

export default TotalSales
