import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Button,
    TextField,
    Stack,
} from '@mui/material';
import { remove } from 'lodash'
import AutoCompleteProduct from './AutoCompleteProduct';

const ProductsField : React.FC<any> = ({
    productItems,
    setProductItems,
    dataProducts
}) => {

    const onChangeValue = (index : any, e : any) => {
        const { name, value } = e.target
        let value_number = value === "" ? 0 : parseInt(value)
        if(value < 101) {
            let copy_array = [...productItems]
            const newData = copy_array.map((obj : any, i : any) => {
                if(i === index)
                return { 
                    ...obj,
                    [name]: value_number,
                }
                return obj
            });
            setProductItems(newData)
        }
    }
    
    const onClickAddNewField = () => {
        let copy_item = [...productItems]
        let new_object = {
            product_id : "",
            discount : "",
            product_name : ""
        }
        copy_item.push(new_object)
        setProductItems(copy_item)
    }

    const onClickRemoveItem = (value : any) => {
        let copy_item = [...productItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setProductItems(items_remove)
    }

    const onChangeProduct = (value : any, index : any) => {
        let copy_array = [...productItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                product_id: value._id,
                product_name : value.name
            }
            return obj
        });
        setProductItems(newData)
    }

  return (
    <Box>
        <Stack>
          <Box mb={2}>
            <Button 
                variant="outlined" color="primary"
                size="small"
                onClick={() => onClickAddNewField()}
            >
              Add Product
            </Button>
          </Box>
        </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell>PRODUCT</TableCell>
                    <TableCell width="150px">DISCOUNT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { productItems.map((row :any, i : any) => (
                <TableRow key={i} >
                    <TableCell component="th" scope="row">
                        <AutoCompleteProduct
                            dataProducts={dataProducts}
                            onChangeProduct={onChangeProduct}
                            index={i}
                            default_value={row.product_name}
                        />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            label="Discount in %"
                            name="discount"
                            value={row.discount}
                            onChange={(e) => onChangeValue(i, e)}
                            type="text"
                            size="small"
                            required
                        />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button color="error" onClick={() => onClickRemoveItem(i)}>Remove</Button>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default ProductsField