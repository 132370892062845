import { useEffect, useState } from 'react'; 
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../../components/TableData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { getDataEFaktur } from '../reducers/eFakturReducers';
import moment from 'moment';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';

const TableListEFaktur = () => {

    const dispatch = useDispatch()

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const store_efaktur = useSelector((state : RootState) => state.efaktur)

    useEffect(() => {
        dispatch(getDataEFaktur())

        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'DATE',
            selector: row => moment(row.createdAt).format("l"),
            width: '200px'
        },
        {
            name: 'NPWP',
            selector: row => row.npwp,
            width: '200px' 
        },
        {
            name: 'DOCUMENT NUMBER',
            selector: row => row.document_number,
            width: '200px'
        },
        // {
        //     name: 'CREATED DATE',
        //     cell: (row) => (
        //         <div>{moment(row.createdAt).format('ll')}</div>
        //     )
        // },
        // {
        //     name: 'TAX PERIOD',
        //     width: '150px',
        //     selector: row => row.tax_period,
        //     // cell: (row) => (
        //     //     <div>Rp. {row.grand_total.toLocaleString()}</div>
        //     // )
        // },
        // {
        //     name: 'DJP RESPONSE',
        //     selector: row => row.djp_response_message,
        // },
        {
            name: 'ACTION',
            width: '',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                // onClickProceed(row)
                                window.open(row.link_url, "_blank")
                            }}
                        >   
                            Download E-Faktur
                        </Button>
                    </Box> 
                </Stack>
            ),
        },
    ];

    return (
        <div>
            <DataTableBase 
                columns={columns}
                data={store_efaktur.data}
                progressPending={store_efaktur.loading}
                pagination
                onChangePage={(value) => setPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
                noDataComponent="Data E-Faktur not found!"
            />
        </div>
    )
}

export default TableListEFaktur
