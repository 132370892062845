import { Stack, Box, Button } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../../app/store'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import TableListInvoice from './components/TableListInvoice'
import { getDataPackageDelivered } from './reducers/invoiceReducers'
// import {userCredentials} from './../../../utilities/config'

function InvoicesPage() {
    const dispatch = useDispatch()
    const history = useHistory()

    const store_invoice = useSelector((state : RootState) => state.invoices)

    useEffect(() => {
        dispatch(getDataPackageDelivered())
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Finance Page"
            />

           <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Invoice Lists</h3>
                </Box> 
                <Box> 
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => {
                            history.push({
                                pathname: "/dashboard/finance/invoice",
                                search: `?page=create`, 
                            })
                        }}
                        disabled={store_invoice.data_package.length === 0 ? true : false}
                        >
                        Create Invoice
                    </Button>
                </Box>
            </Stack>

           <Stack pt={3}>
                <TableListInvoice/>
           </Stack>
        </Box>
    )
}

export default InvoicesPage
