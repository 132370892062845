import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../components/TableData';
import React from 'react'


const TableProductList : React.FC<any> = ({
    dataProducts
}) => {

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'PRODUCT NAME',
            selector: row => row.product_name,
        },
        {
            name: 'DISCOUNT',
            cell: (row) => (
                <p>{row.discount}%</p>
            ),
        },
        
    ];

    return (
        <Box sx={{pt:1}}>
            <DataTableBase 
                columns={columns}
                data={dataProducts}
            />
        </Box>
    )
}

export default TableProductList
