import { Button, Stack, Box, Paper } from '@mui/material'
import React  from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableProductList from './TableProductList';

const DialogDetail : React.FC<any> = ({
    open,
    setOpen,
    dataVendor,
    name,
    valueVendor
}) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} maxWidth="lg" fullWidth={true}  >
                    <DialogTitle>{name}</DialogTitle>
                    <DialogContent>
                            <Stack>
                                { dataVendor.length !== 0 ? 
                                    <Stack>
                                        <Box>Company Name : {dataVendor.legal_name}</Box>
                                        <Box>Company Code : {dataVendor.company_code}</Box>
                                        {/* <Box>Phone Number : {dataVendor.hotline}</Box> */}
                                    </Stack>
                                    : null
                                }
                            </Stack>
                            <Stack mt={2}>
                                <Box>Discount : {valueVendor.initial_discount} %</Box>
                                <Box>Payment Terms :{valueVendor.payment_term} Days</Box>
                            </Stack>
                            <Stack mt={3}>
                                <Paper>
                                    <Stack>
                                        <Box>Product Discount </Box>
                                        <TableProductList 
                                            dataProducts={valueVendor.products}
                                        />
                                    </Stack> 
                                </Paper>
                            </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="contained">OK</Button>
                    </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogDetail
