import { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, Stack } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import upload from '../../../../assets/img/icon/upload.png'
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import  remove  from 'lodash/remove'

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: 3,
    borderColor: '#eeeeee',
    backgroundColor: '#68c7f5',
    color: '#fff',
    transition: 'border .3s ease-in-out',
    height: '200px',
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#2196f3'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const DropzoneComponent = ({multipleFiles, setMultipleFiles, handleSubmit, successUploaded} : any) =>{

    const [imgPreview, setImgPreview] = useState<any>([]);

    const onDrop = (acceptedFiles : any) =>{
      let images = acceptedFiles.map((file:any)=> Object.assign(file, {
            preview: URL.createObjectURL(file)
          }))
          setMultipleFiles([...multipleFiles, images])
          setImgPreview([...multipleFiles, images])
      }
      
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({
      onDrop,
      accept: 'image/jpeg, image/png'
    });
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);

    function deletePreviewImages (idx : number){
      let copy_item = [...imgPreview]
      const items_remove = remove(copy_item, function(obj : any, index : any) {
          return index !== idx
      });
      setImgPreview(items_remove)
    }

    useEffect(() => {
      if(successUploaded){
        setImgPreview([])
        setMultipleFiles([])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successUploaded]);

    return(
      <Box>
        <Box py={1} fontWeight="500">Upload Your Images</Box>
        <Box {...getRootProps()} sx={style}>
          <Box><input {...getInputProps()} /></Box>
          <Box><img width="50px" src={upload} alt="upload" /></Box>
          <Box fontSize={20} pt={1}>Drag and drop or browse to choose a file.</Box>
        </Box>

        { imgPreview.length === 0 ? null :
        <Stack sx={{my:2}}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box py={1} fontWeight="500" >Preview</Box>
            <Box>
                <Button variant="contained" size="small" onClick={() => handleSubmit()}>UPLOAD IMAGES</Button>
                <Button onClick={() => setImgPreview([])} variant="contained" size="small" color="error" sx={{ ml : 2}}>CANCEL</Button>
            </Box>
          </Stack>
          <Box 
            sx={{
              backgroundColor : '#fff',
              p : 2,
              display: 'flex',
              flexWrap: 'wrap',
              borderRadius: 3
            }}
          >
          { imgPreview.map((file:any,index:number) => (
            <Box className="boxDisplayFilterData" key={index} pr={1}>
                <Card 
                    sx={{ 
                        border : '0.5px solid gray', 
                        width : '100px',
                        height : '100px', 
                        boxShadow: '0px 0px 2px 0px gray',
                        padding : '3px'
                    }}
                    className="containerDiv"
                >
                    <img
                        className="imageMediaCenter"
                        style={{ width:'100%', height:'100%', objectFit: 'contain', display: 'block'}}
                        src={file[0].preview}
                        alt={file[0].name}
                    />
                    <Box className="overlayediaCenters">
                        <Box 
                            onClick={() => deletePreviewImages(index)}
                        >
                            <DisabledByDefaultTwoToneIcon 
                                sx={{
                                    position : 'absolute', 
                                    bottom:'0',
                                    right: 0,
                                    color:'red',
                                    fontSize: 20,
                                    margin : 0.4,
                                    cursor: 'pointer'
                                }}
                            />
                        </Box>
                    </Box>
                    
                </Card>
            </Box> 
            ))}
          </Box> 
          
        </Stack>}
      </Box>
    )
}

export default DropzoneComponent