import { useState } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../app/store';
import { postEmailBlast } from './reducers/invitationReducers';
import Select from 'react-select'

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

import emailRegex from "./regexEmail.js";
import swal from 'sweetalert';

    
function InvitationPage() {
    const dispatch = useDispatch()

    const invitation_state = useSelector((state : RootState) => state.invitation)

    // console.log(invitation_state, 'invites sta')

    const [emails, setEmails] = useState<string[] | []>([]);

    const [invalidEmails, setInvalidEmails] = useState<string[] | []>([]);

    const [optionsChannel] = useState<any[]>([
        {
            value : "BUYER",
            label : "Buyer",
        },
        {
            value : "VENDOR",
            label : "Vendor",
        }
    ]);

    const [selectedChannel, setSelectedChannel] = useState<any>([]);
    const [errorSelectChannel, setErrorSelectChannel] = useState<boolean>(false);

     /* istanbul ignore next */
    const handleChangeChannel = (value: any) : void => {
        setSelectedChannel(value)
        setErrorSelectChannel(false)
    }

    // console.log(emails, 'emailss')
    const onSubmitSend = (e: any): void => {
        e.preventDefault()
        const target = []

        if(emails.length === 0) {
            swal('Error!', 'Email is required!', "error")
        } else if(selectedChannel.length === 0) {
            setErrorSelectChannel(true)
        } else {
            for(let ele of emails) {
                target.push({ email : ele, name: ele })
            }
            let data_send = {
                target : target,
                channel : selectedChannel.value
            }
            dispatch(postEmailBlast(data_send))
        }
    }

    const styles = {
        fontFamily: "sans-serif",
        width: "96%",
        border: "1px solid #eee",
        background: "#f3f3f3",
        padding: "25px",
        marginTop: '10px',
        marginRight: '10px'
      };
      
    
    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Invitation"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h2>Invitation Email</h2>
            </Stack>
            <Box pt={2} style={styles}>
                <Box pb={1}><h4>Target Emails</h4></Box>
                <ReactMultiEmail
                    placeholder="Input target email addresses"
                    emails={emails}
                    validateEmail={email => {
                        const isValid = emailRegex.test(email);

                        if (!isValid) {
                            setInvalidEmails([...invalidEmails, email]);
                        }
                        return isValid;
                    }}
                    onChange={(_emails: string[]) => {
                        setEmails(_emails);
                    }}
                    getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void
                    ) => {
                    return (
                        <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                        </span>
                        </div>
                    );
                    }}
                />
                
                <Box pt={2}>
                    <Select
                        placeholder="Select Channel"
                        value={selectedChannel}
                        isSearchable={false}
                        options={optionsChannel}
                        onChange={handleChangeChannel}
                    />
                    { errorSelectChannel ? <div className="error-p"><p>Channel is required</p></div> : null }
                </Box>

                <Box pt={2}>
                    <Button type="submit" variant='contained' onClick={onSubmitSend} disabled={invitation_state.loading_create}>
                        {invitation_state.loading_create ? "Loading.." : "Send Invitation"}
                    </Button>
                </Box>
            </Box>
            
        </div>
    )   
}

export default InvitationPage
