import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'
import {userCredentials} from '../../../../utilities/config'

export const getAllBroadcastMessage = createAsyncThunk(
    'broadcast/fetch', 
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_SOCKET_API}/notif?category=broadcast`, {
                headers: {
                    Authorization : `Bearer ${userCredentials.id_token}`
                }
            })
            if(response.data) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
}) 

export const postEmailBlast = createAsyncThunk(
    'invitation/post', 
    async (value : any, { rejectWithValue }) => {
        try {
            const body = {
                "target": value.target,
                "body": "Caption in body email",
                "invite_as": value.channel
            }
            const response : any = await Axios.post(`${process.env.REACT_APP_API_SERVER}/user-vendor/invite`, body, {
                headers: {
                    Authorization : `Bearer ${userCredentials.id_token}`
                }
            },)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

