import { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Stack, TextField } from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import SalesValues from './components/SalesValue';
import GrossSales from './components/Tiles/GrossSales';
import TotalOrders from './components/TotalOrders';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getChartsReports } from './reducers/chartsReducers';
import PoCanceled from './components/Tiles/PoCanceled';
import DNTransit from './components/Tiles/DNTransit';
import POLatePayment from './components/Tiles/POLatePayment';
import ARDue from './components/Tiles/ARDue';
import NettSales from './components/Tiles/NettSales';
import BuyerByOrder from './components/BuyerByOrder';
import SalesByCategory from './components/SalesByCategory';
import TotalSales from './components/TotalSales';
import TopTenProduct from './components/TopTenProduct';
import Select from 'react-select'
import moment from 'moment';

const ChartsPage = () => {

    const dispatch = useDispatch()

    const state_reports = useSelector((state : RootState) => state.chart_report)

    const optionSearch = [
        { label : "Today", value : "today" },
        { label : "This Month", value : "this_month" },
        { label : "Last Month", value : "last_month" },
        { label : "Custom", value : "custom" }
    ]

    const [selectedSearch, setSelectedSearch] = useState<any>([ { label : "Today", value : "today" }]);
    
    const [dataSearch, setDataSearch] = useState({ 
        rangeFrom: "",
        rangeTo: "",
    });

    const handleChangeSearch = (value: any) : void => {
        setSelectedSearch(value)
    }

    useEffect(() => {
        const range = {
            from : moment().format('YYYY-MM-DD'),
            to : moment().format('YYYY-MM-DD')
        }
        dispatch(getChartsReports(range))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(selectedSearch.value === "this_month") {
            const range = {
                from : moment().startOf('month').format('YYYY-MM-DD'),
                to : moment().endOf('month').format('YYYY-MM-DD')
            }
            dispatch(getChartsReports(range))
        } else if (selectedSearch.value === "last_month") {
            const range = {
                from : moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                to : moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            }
            dispatch(getChartsReports(range))
        } else if (selectedSearch.value === "custom") {
            // do nothing
        }  else {
            const range = {
                from : moment().format('YYYY-MM-DD'),
                to : moment().format('YYYY-MM-DD')
            }
            dispatch(getChartsReports(range))
        }
        // eslint-disable-next-line
    }, [selectedSearch]);

    const onClickSearch = () => {
        const range = {
            from : dataSearch.rangeFrom,
            to : dataSearch.rangeTo
        }
        dispatch(getChartsReports(range))
    }

    return (
        <Box sx={{pt:2, pl:3, pr:3, mb: 5}}>
            <BreadCrumbs
                isPage={false}
                current="Report Charts"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Report Charts</h3>
                </Box>
            </Stack>
            

            <Stack flexDirection="row">

                <Box mt={1} sx={{ minWidth: 250 }}>
                    <Select
                        placeholder="Filter By"
                        value={selectedSearch}
                        options={optionSearch}
                        onChange={handleChangeSearch}
                        id={`select-style-searchby`}
                    />
                </Box>
                { selectedSearch.length !== 0 && selectedSearch.value === 'custom' ? 
                <>
                <Box ml={2} mt={1}>
                    <TextField 
                        type="date"
                        size="small"
                        onChange={(e) => setDataSearch({...dataSearch, rangeFrom: e.target.value})}
                        value={dataSearch.rangeFrom}
                        sx={{ backgroundColor: '#fff' }}
                    />
                </Box>  
                <Box pl={1} pt={2} pr={1}>to</Box>
                <Box mt={1}>
                    <TextField 
                        type="date"
                        size="small"
                        variant="outlined"
                        value={dataSearch.rangeTo}
                        onChange={(e) => setDataSearch({...dataSearch, rangeTo: e.target.value})}
                        sx={{ backgroundColor: '#fff' }}
                    />
                </Box>
                <Box mt={1} ml={2} sx={{ minWidth: 250 }}>
                    <Button 
                        color="primary" variant="contained"
                        disabled={dataSearch.rangeFrom !== "" && dataSearch.rangeTo !== "" ? false : true}
                        onClick={onClickSearch}
                    >Search</Button>
                </Box>
                </> : null }
            </Stack>  



            <Box mt={2}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <GrossSales data={state_reports} />
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <PoCanceled data={state_reports}/>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <DNTransit data={state_reports}/>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <POLatePayment data={state_reports}/>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <ARDue data={state_reports}/>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                        <NettSales data={state_reports}/>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2}>
                <Paper>
                    <SalesValues data_charts={state_reports}/>
                </Paper>
            </Box>
            <Box mt={2}>
                <Paper>
                    <TotalOrders data_charts={state_reports}/>
                </Paper>
            </Box>

            <Box mt={2}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <TotalSales data={state_reports}/>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={6} xs={12}>
                        <TopTenProduct data={state_reports}/>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <BuyerByOrder data={state_reports}/>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <SalesByCategory data={state_reports}/>
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
}

export default ChartsPage
