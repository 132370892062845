import { Stack, Box, Skeleton, Button, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import { getAllProductBySearch, getAllProduct } from '../reducers/productsReducers';
import { useMediaQuery } from '@mui/material'
import BreadCrumbs from '../../../../components/BreadCrumbs';

const styles = {
    control: (base:any) => ({
      ...base,
      fontSize:'12px',
      width:'100%'
    }),
    crossicon: (base:any) =>({
        ...base,
        display : 'none',
        color : 'red'
    }),
    menu: (base:any) =>({
        ...base,
        fontSize:'9px'
    })
}

const SearchData : React.FC<any> = ({ data, progressPending, }) => {

    const mobileDisplay = useMediaQuery('(min-width:480px)')
    const dispatch = useDispatch()

    const [dateSearch, setDateSearch] = useState(false);

    const [dataSearch, setDataSearch] = useState({
        searchTopic : "", 
        searchValue: "",
        rangeTopic : "",
        rangeFrom: "",
        rangeTo: "",
        search : false
    });
    const [isReset, setisReset] = useState<boolean>(false)
    const optionSearch = [
        { label : "Name", value : "name" },
        { label : "SKU", value : "SKU" },
        { label : "Payment Terms", value : "payment_term.value" },
        { label : "Warehouse", value : "warehouse_id" },
        { label : "Tax", value : "pajak_id" }
    ]

    const [optionsValue, setoptionsValue] = useState<any>([]);
    const [selectedSearch, setSelectedSearch] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);

    const proceedOptions = (value : any, data: any) => {
        let data_options : any = []
        if(value === 'name') {
            for(let ele of data) {
                data_options.push({ value: ele.name, label: ele.name })
            }
        } else if(value === "SKU") {
            for(let ele of data) {
                data_options.push({ value: ele.SKU, label: ele.SKU })
            }
        } else if(value === "payment_term.value") {
            for(let element of data) {
                data_options.push({ value: element.payment_term.value, label: element.payment_term.name })
            }
        } else if(value === "warehouse_id") {
            for(let element of data) {
                data_options.push({ value: element.warehouse_detail._id, label: element.warehouse_detail.name })
            }
        } else if(value === "pajak_id") {
            for(let element of data) {
                data_options.push({ value: element.pajak._id, label: element.pajak.title })
            }
        } 

        const uniqueValuesSet = new Set();
        const filteredArr = data_options.filter((obj:any) => {
            const isPresentInSet = uniqueValuesSet.has(obj.label);
            uniqueValuesSet.add(obj.label);
            return !isPresentInSet;
        });

        let sortBy = filteredArr.sort((a:any,b:any) => {
            if(a.label < b.label) return -1
            return 1
        })
        setoptionsValue(sortBy)
    }

    const handleChangeSearch = (value: any) : void => {
        proceedOptions(value.value, data)
        setSelectedSearch(value)
        setSelectedValue([])
        if(value.value === "date") {
            setDateSearch(true)
            setDataSearch({...dataSearch, 
                searchTopic: "",
                rangeTopic: value.value,
                rangeFrom: "",
                rangeTo: "",
                search : false
            })
        } else {
            setDateSearch(false)
            setDataSearch({...dataSearch, 
                searchTopic: value.value,
                rangeTopic: "",
                rangeFrom: "",
                rangeTo: "",
                search : false
            })

        }
    }

    const handleChangeValue = (value: any) : void => {
        setDateSearch(false)
        setSelectedValue(value)
        setDataSearch({...dataSearch, 
            searchValue: value.value,
            search : true
        })
    }

    const inActiveButton = () => {
        let active = true
        if(dateSearch) {
            if(dataSearch.rangeTo !== "" && dataSearch.rangeFrom !== "") {
                active = false
            }
        } else {
            active = !dataSearch.search
        }
        return active
    }

    const onClickReset = () => {
        // setLoading(true)
        setSelectedSearch([])
        setSelectedValue([])
        setoptionsValue([])
        setDataSearch({...dataSearch, 
            searchTopic: "",
            searchValue: "",
            rangeFrom: "",
            rangeTo: "",
            rangeTopic: "",
            search : false
        })
        dispatch(getAllProduct())
    }

    const onClickSearch = () => {
        // setLoading(true)
        let search = {
            topic: dataSearch.searchTopic,
            value: dataSearch.searchValue
        } 
        dispatch(getAllProductBySearch(search))
    }

    const resetFilterMobile = () =>{
        if(isReset){
            setSelectedSearch([])
            setSelectedValue([])
            setoptionsValue([])
            setDataSearch({...dataSearch, 
                searchTopic: "",
                searchValue: "",
                rangeFrom: "",
                rangeTo: "",
                rangeTopic: "",
                search : false
            })
            setisReset(false)
            dispatch(getAllProduct())
        }
    }

    const handleChangeSearchMobile = (value: any) : void => {
        proceedOptions(value.value, data)
        setSelectedSearch(value)
        setSelectedValue([])
        if(value.value === "date") {
            setDateSearch(true)
            setDataSearch({...dataSearch, 
                searchTopic: "",
                rangeTopic: value.value,
                rangeFrom: "",
                rangeTo: "",
                search : false
            })
        } else {
            setDateSearch(false)
            setDataSearch({...dataSearch, 
                searchTopic: value.value,
                rangeTopic: "",
                rangeFrom: "",
                rangeTo: "",
                search : false
            })
        }
    }

    const handleChangeValueMobile = (value: any) : void => {
        // setDateSearch(false)
        setSelectedValue(value)
        setDataSearch({...dataSearch, 
            searchValue: value.value,
            search : true
        })
        let search = {
            topic: dataSearch.searchTopic,
            value: dataSearch.searchValue
        } 
        setisReset(true)
        dispatch(getAllProductBySearch(search))
    }

    return (
        // exception
        <div>
        { mobileDisplay?
        <Stack>
        { !progressPending ? 
        <Stack flexDirection="row">
            <Stack flexDirection="row">
                <Box mr={1} sx={{ minWidth: 250 }}>
                    <Select
                        placeholder="Search By"
                        value={selectedSearch}
                        options={optionSearch}
                        onChange={handleChangeSearch}
                        id={`select-style-searchby`}
                    />
                </Box>
                { dataSearch.rangeTopic === "date" ? 
                <Stack flexDirection="row">
                    <Box ml={1}>
                        <TextField 
                            type="date"
                            size="small"
                            onChange={(e) => setDataSearch({...dataSearch, rangeFrom: e.target.value})}
                            value={dataSearch.rangeFrom}
                            sx={{ backgroundColor: '#fff' }}
                        />
                    </Box>  
                    <Box pl={1} pt={1}>to</Box>
                    <Box ml={1}>
                        <TextField 
                            type="date"
                            size="small"
                            variant="outlined"
                            value={dataSearch.rangeTo}
                            onChange={(e) => setDataSearch({...dataSearch, rangeTo: e.target.value})}
                            sx={{ backgroundColor: '#fff' }}
                        />
                    </Box>
                </Stack>  
                :
                <Box ml={1} sx={{ minWidth: 350 }}>
                    <Select
                        placeholder="Select Value"
                        value={selectedValue}
                        isSearchable={true}
                        options={optionsValue}
                        onChange={handleChangeValue}
                        id={`select-style-value`}
                    />
                </Box>
                }
                <Box ml={2}>
                    <Button 
                        color="primary" variant="contained"
                        disabled={inActiveButton()}
                        onClick={onClickSearch}
                    >Search</Button>
                </Box>
                <Box ml={1}>
                    <Button 
                        color="primary" variant="outlined"
                        onClick={onClickReset}
                        disabled={selectedSearch.length === 0 ? true : false}
                    >Reset Search</Button>
                </Box>
            </Stack>  
        </Stack> :
        <Stack flexDirection="row" rowGap={3}>
            <Box mr={1}><Skeleton width={250} height={70}/></Box>
            <Box ml={1}><Skeleton width={250} height={70}/></Box>
            <Box ml={2}><Skeleton width={120} height={70}/></Box>
        </Stack>
        }
        </Stack>
        :
        <Stack>
        <BreadCrumbs isPage={false} current='product page'/>
        { !progressPending ? 
        <Stack flexDirection="row" mt={2}>
            <Stack flexDirection="row">
                <Box sx={{ minWidth: 135}}>
                <Select
                    placeholder="Search By"
                    value={selectedSearch}
                    options={optionSearch}
                    onChange={handleChangeSearchMobile}
                    styles={styles}
                    onFocus={()=>resetFilterMobile()}
                />
                </Box>
                <Box ml={1} sx={{ minWidth: 135 }}>
                    <Select
                        placeholder="Select Value"
                        value={selectedValue}
                        isSearchable={true}
                        styles={styles}
                        options={optionsValue}
                        onChange={handleChangeValueMobile}
                    />
                </Box>
            </Stack>  
        </Stack> :
        <Stack flexDirection="row" rowGap={3} minWidth={'100%'}>
            <Box mr={1}><Skeleton width={'50%'} height={70}/></Box>
            <Box ml={1}><Skeleton width={'50%'} height={70}/></Box>
        </Stack>
        }
        </Stack>
        }
        </div>
    )
}

export default SearchData
