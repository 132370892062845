import { Box } from '@mui/system'
import { Button, Stack } from '@mui/material';
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../components/TableData';
import { getBuyerListsData } from '../reducers/buyerListsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { useEffect, useState } from 'react';
import DialogField from './DialogField'; 
import DialogDetail from './DialogDetail';
import { userCredentials } from '../../../../utilities/config';
import { createNewChat, getUsersForChat } from '../../chat/reducers/chatReducers';
import { getAllUsersTeams } from '../../teams/reducers/teamsReducers';


const TableBuyerList = () => {

    const dispatch = useDispatch()
 
    const store_buyerlists = useSelector((state : RootState) => state.buyer_lists)
    const store_chat = useSelector((state : RootState) => state.chat)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [dataBuyerLists, setDataBuyerLists] = useState<any>([]);

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [dataBuyer, setDataBuyer] = useState<any>([]);
    const [valueBuyer, setvalueBuyer] = useState<any>({});
    const [open, setOpen] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);

    const updatePreferredBuyer = (value : any) => {
        let buyer = {
            legal_name : value.legal_name,
            company_code : value.company_code,
            address : value.address,
            _id: value.buyer_id
        }
        setDataBuyer(buyer)
        setvalueBuyer({...valueBuyer, 
            payment_term : value.payment_term === null ? "" : value.payment_term.value,
            initial_discount : value.initial_discount === null ? "" : value.initial_discount,
            products : value.product
        })
        setOpen(true)
    }


    const viewDetail = (value : any) => {
        let buyer = {
            legal_name : value.legal_name,
            company_code : value.company_code,
            address : value.address,
            _id: value.vendor_id
        }
        setDataBuyer(buyer)
        setvalueBuyer({...valueBuyer, 
            payment_term : value.payment_term === null ? "-" : value.payment_term.value,
            initial_discount : value.initial_discount === null ? "-" : value.initial_discount,
            products : value.product
        })
        setOpenDetail(true)
    }


    const [idxItem, setIdxItem] = useState();
    const [loadingMessage, setLoadingMessage] = useState(false);

    const onClickCreateChat = async (row : any, i : any) => {
        setLoadingMessage(true)
        setIdxItem(i)

        const user_vendor : any = await dispatch(getUsersForChat(row.buyer_id))

        if(user_vendor.type === "chat-user/get/fulfilled") {
            let data_response = user_vendor.payload.data.data
            let user_names = []
            for(let k of data_response) {
                user_names.push(`${k.fullname} - ${k.company_code}`)
            }
            for(let l of state_teams.data) {
                user_names.push(`${l.fullname} - ${l.company_code}`)
            }
            let body = {
                username: `${userCredentials.fullname} - ${userCredentials.company_code}`,
                secret : userCredentials.vendor_id,
                title : `${row.legal_name} & ${userCredentials.vendor_name}`,
                usernames : user_names
            }
            dispatch(createNewChat(body))
        } 
    }

    useEffect(() => {
        if(store_chat.chat) {
            setLoadingMessage(false)
            window.location.href = '/dashboard/message'
        }
    }, [store_chat.chat]);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'COMPANY NAME',
            selector: row => row.legal_name,
        },
        {
            name: 'COMPANY CODE',
            selector: row => row.company_code,
        },
        {
            name: 'Discount',
            cell : (row) => (
                <p>{row.initial_discount !== null ? `${row.initial_discount}%` : "-"}</p>
            )
        },
        {
            name: 'Payment Term',
            cell : (row) => (
                <p>{row.payment_term !== null ? row.payment_term.name : "-"}</p>
            )
        },
        {
            name: 'STATUS',
            selector: row => row.status,
        },
        // {
        //     name: 'MESSAGE',
        //     width: '200px',
        //     cell: (row, i) => (
        //         <Stack direction="row" spacing={2}>
        //              { state_teams.loading ? "Loading" : 
        //             <Button 
        //                 variant="contained" color="primary" size="small"
        //                 onClick={() => onClickCreateChat(row, i)}
        //                 disabled={loadingMessage ? true : false}
        //             >
        //                 { loadingMessage && idxItem === i ? "Loading" : "Message" }
        //             </Button> }
        //         </Stack>
        //     ),
        // },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => updatePreferredBuyer(row)}
                    >
                        Edit
                    </Button>
                    <Button 
                        variant="outlined" color="success" size="small"
                        onClick={() => viewDetail(row)}
                    >
                        Detail
                    </Button>
                </Stack>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getBuyerListsData())
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!store_buyerlists.loading) {
            setDataBuyerLists(store_buyerlists.data)
        }
        // eslint-disable-next-line
    }, [store_buyerlists.loading]);

    return (
        <Box sx={{pt:3}}>
            <DataTableBase 
                columns={columns}
                data={dataBuyerLists}
                progressPending={store_buyerlists?.loading}
                pagination
                onChangePage={(value) => setPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
            />
            <DialogField 
                open={open}
                dataBuyer={dataBuyer}
                setOpen={setOpen}
                valueUpdateBuyer={valueBuyer}
                name="Update Preferred Buyer"
            />
            <DialogDetail 
                open={openDetail}
                dataVendor={dataBuyer}
                valueVendor={valueBuyer}
                setOpen={setOpenDetail}
                name="View Detail"
            />
        </Box>
    )
}

export default TableBuyerList
