import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LoaderBackdrop from './LoaderBackdrop';
import { getMasterCategoryListByChild } from '../features_app/dashboard/products/reducers/productsReducers';
import { useDispatch } from 'react-redux';

function NewCategoryTree(props : any) {
    const dispatch = useDispatch()
    const { data, loading, dataCategory, setDataCategory, selectedData, setSelectedData, setErrorCategory, defaultCategory  } = props;

    const [openDropdown, setOpenDropdown] = useState(false);

    const findCategoryOnSelected = (arr:any, index:any) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].idx === index) {
                return true;
            }
        }
        return false;
    }

    const findNameOnSelected = (arr:any, name:any) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].name === name) {
                return true;
            }
        }
        return false;
    }

    const onSelectedCategory = (data:any, idx:any) => {
        let copy_selected  = [...selectedData];
        let index = idx + 1;
        if(!findCategoryOnSelected(copy_selected, index)) {
           copy_selected.push({ idx: index, name: data.name, _id : data._id })
           setSelectedData(copy_selected)
        } else {
            copy_selected[idx] = { idx: index, name : data.name, _id: data._id }
            const dataa = copy_selected.slice(0, index);
            setSelectedData(dataa)
        }
    }

    const findIdOnCategory = (arr:any, value:any) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === value) {
                return true;
            }
        }
        return false;
    }

    const onClickSelect = (item:any, idx:any) => {
        let copy_array = [...dataCategory];
        let index = idx + 1;
        onSelectedCategory(item, idx)
        if(!findIdOnCategory(copy_array, index)) {
            if(item.child && item.child.length) {
                copy_array.push({ id: index, data : item.child});
                setDataCategory(copy_array);
            } else {
                setErrorCategory(false)
                setOpenDropdown(false)
            }
        } else {
            if(item.child && item.child.length) {
                copy_array[index] = { id: index, data : item.child}
                const dataa = copy_array.slice(0, index + 1);
                setDataCategory(dataa)
            } else {
                const new_data = copy_array.slice(0, index)
                setDataCategory(new_data)
                setErrorCategory(false)
                setOpenDropdown(false)
            } 
        }
    }

    const onGetMasterCategory = async () => {
        try {
            const response : any = await dispatch(getMasterCategoryListByChild(defaultCategory.slice(-1).pop()._id));
            if(response.type === "category-list-bychild/fetch/fulfilled") {
                const data_default :any = []
                const dataloop = response.payload.data
                for (let i = 0; i < dataloop.length; i++) {
                    data_default.push({ id: i+1, data: dataloop[i] })
                }
                setDataCategory(data_default)
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        if(data.length) {
            if(defaultCategory.length) {
                setSelectedData(defaultCategory)
                onGetMasterCategory()
            } else {
                const new_array = [{ id: 1, data : data}];
                setDataCategory(new_array);
            }
        }
        // eslint-disable-next-line
    }, [data, defaultCategory]);


    const styleActive = (name:any) => {
        if(findNameOnSelected(selectedData, name)) {
            return { backgroundColor: '#0091d6', color: '#fff', borderRadius: '5px' }
        }
        return {'border': 'transparent'}
    }
        
    return (
    <div className='container-cattree' style={{ fontSize:'13px', minWidth:'200px'}}>
        <div className='input-select-tree' onClick={() => setOpenDropdown(!openDropdown)}>
            { selectedData.length ? 
            <div className='box-tree-selected'>
                { selectedData.map((item:any, idx:any) => (
                    <div key={idx} className='title-tree-selected'>
                    <p >
                        {item.name}
                    </p> 
                    { idx !== selectedData.length - 1 ? 
                        <p>{">"}</p>    
                    : null }
                    </div>
                ))}
            </div>
            :
            <p>Select Category</p>
            }
            <div className='icon-tree'>
                { !openDropdown  ? 
                <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon/> }
            </div>
        </div>
        { openDropdown ? 
        <>
        { loading ? <LoaderBackdrop loading={loading} /> :
        <div className='content-catree'>
            { dataCategory.map((content: any, idx: any) => (
                <div className='content-box' style={{ width: '100%' }} key={idx}>
                    {content.data.map((item:any, index:any) => (
                        <div className={`content-box-item`} key={index} onClick={() => onClickSelect(item, idx)} style={styleActive(item.name)}>
                            <div className='box-tree'>
                                <p>{item.name}</p>
                                { item.child && item.child.length ?
                                <KeyboardArrowRightIcon/> : null }
                            </div>
                        </div>
                    ))}
                </div>
            )) }
        </div> 
        }
        </> 
        : null }
    </div>

  )
}

export default NewCategoryTree