import { Stack, Box } from '@mui/material'
import emptyimg from '../assets/img/empty.png'

function EmptyData(prop:any) {
    const { message } = prop

    return (
        <Stack flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
                <img alt='empty' src={emptyimg} width="200px"/>
            </Box>
            <Box>{message}</Box>
        </Stack>
    )
}

export default EmptyData