import {
    Box,
    TextField,
    Button, 
    Grid, 
    Link,
    CircularProgress,
    Paper
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'; 
import { RootState } from '../../../app/store';
import { sendEmail } from './forgotSlice';
import { UserFormSubmit } from './forgotTypes'
import logo from '../../../assets/img/logo-color.png'
import { useMediaQuery } from '@mui/material'

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid")
  })
  .required();


const Forgot = () => {

  const dispatch = useDispatch()
  const forgot = useSelector((state : RootState) => state.forgot)
  const mobileDisplay = useMediaQuery('(min-width: 480px)')

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserFormSubmit>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: UserFormSubmit): void => {
    dispatch(sendEmail(data))
  }

  const mobileStyle = {
    boxTitle : {
      textAlign: 'center', 
      paddingBottom: 1
    },
    fontSizeLink : {
      fontSize : '13px'
    },
    fontSizeTitle : {
      fontSize : '24px',
      paddingTop : '10px'
    },
    fontSizeSubTitle : {
      fontSize : '13px'
    }
  }

    return (
      <div className={ mobileDisplay? "container-box" : undefined} style={{ display:'flex', alignContent:'center', alignItems:'center', justifyContent:'center', height:'600px'}}>
        <Paper sx={ mobileDisplay?{ margin: 5 } : { display:'auto', justifyContent:'center', margin:5}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '4rem 2rem',
            }}
          >
            <Box>
              <a href='/'>
                <img src={logo} alt="logo" width="150px" />
              </a>
            </Box>
            <Box className='name-login' color="primary" sx={ mobileDisplay?{ textAlign: 'center', paddingBottom: 5 } : mobileStyle.boxTitle}>
              <h1 style={ mobileDisplay? undefined : mobileStyle.fontSizeTitle}>Forgot password</h1> 
              <p style={ mobileDisplay? undefined : mobileStyle.fontSizeSubTitle}>Tell us your email so we can send you a reset link.</p>
            </Box>

            <Box sx={{ mt: 1, maxWidth : 450 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={ mobileDisplay?{ width: 450 } : { width: 'auto'}}>
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                    {...register('email', { required: true })}
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    size={ mobileDisplay? undefined : 'small'}
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={mobileDisplay?{ mt: 3, mb: 2 } : {mt: 1, mb: 2}}
                  data-testid="submit-forgot"
                >
                  Submit
                  {
                    /* istanbul ignore next */
                    forgot.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> 
                  } 
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/" style={ mobileStyle.fontSizeLink}>
                      Back to Sign In
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Paper>
      </div>
    )
}

export default Forgot;
