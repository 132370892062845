import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllProduct = createAsyncThunk(
    'get/products',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/algoliaByVendor`, {
                params : {
                    vendor_id : `${userCredentials.vendor_id}`
                },
                headers
                
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data.hits, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);

export const getAllProductBySearch = createAsyncThunk(
    'get/products-search',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/algoliaByVendor`, {
                params : {
                    vendor_id : `${userCredentials.vendor_id}`,
                    [body.topic] : `${body.value}`
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data.hits, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err.response)
        }
    }
);

export const getProductBySlug = createAsyncThunk(
    'product-slug/fetch', 
    async (slug: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/by/${slug}`, { headers })
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterTax = createAsyncThunk(
    'pajak/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/pajak`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const getMasterCategory = createAsyncThunk(
    'category/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category/tree`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterCategoryList = createAsyncThunk(
    'category-list/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            // const response : any = await axios.get(`https://1591-43-252-11-30.ngrok.io/category/tree`, {headers})
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category/tree`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getMasterCategoryListByChild = createAsyncThunk(
    'category-list-bychild/fetch', 
    async (id:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category/${id}`, {
                headers
            })
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postCreateProducts = createAsyncThunk(
    'product/post',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product`, value.products, {headers})
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success added new product."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateProducts = createAsyncThunk(
    'product/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/product/${value.id}`, value.products, {headers})
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success updated product."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const uploadBulkProduct = createAsyncThunk(
    'product-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('vendor_name', userCredentials.vendor_name);
            bodyFormData.append('company_code', userCredentials.company_code);
            bodyFormData.append('vendor_id', userCredentials.vendor_id);
            bodyFormData.append('warehouse_id', value.warehouse_id);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/uploadExcel`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            }) 
            if(response.data.errors === null) {
                return {data : true, message : "Bulk upload is running in the background, please check later while we are processing the upload."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const uploadBulkProductUpdateAll = createAsyncThunk(
    'all-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('vendor_name', userCredentials.vendor_name);
            bodyFormData.append('company_code', userCredentials.company_code);
            bodyFormData.append('vendor_id', userCredentials.vendor_id);
            bodyFormData.append('warehouse_id', value.warehouse_id);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/uploadExcel`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            }) 
            if(response.data.errors === null) {
                return {data : true, message : "Bulk upload is running in the background, please check later while we are processing the upload."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkProductUpdateStock = createAsyncThunk(
    'stock-bulk-update/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
        bodyFormData.append('file', value.file[0]);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/updateExcel/stock/${userCredentials.vendor_id}`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data.errors === null) {
                return {data : true, message : "Bulk update stock is running in the background, please check later while we are processing the update."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkProductUpdatePrice = createAsyncThunk(
    'price-bulk-update/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
        bodyFormData.append('file', value.file[0]);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/product/updateExcel/price/${userCredentials.vendor_id}`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            })
            if(response.data.errors === null) {
                return {data : true, message : "Bulk update price is running in the background, please check later while we are processing the update."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postVariantProducts = createAsyncThunk(
    'product-variant/post',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/variance`, value, {headers})
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success added product variance."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const updateVariantProducts = createAsyncThunk(
    'product-variant/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/variance/${value.id_variance}`, value.variance, {headers})
            if(response.data.errors === null) {
                return {data : response.data.data, message : "Success updated product variance."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removeProduct = createAsyncThunk(
    'product/remove', 
    async (id: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/product/${id}`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const removeVariantProduct = createAsyncThunk(
    'product-variant/remove', 
    async (id: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/variance/${id}`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : "Success removed product variance."}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})