import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }


export const getProfileCompany = createAsyncThunk(
    'profile',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail?vendor_id=${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getBankAccount = createAsyncThunk(
    'bank/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank/vendor?vendor_id=${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const checkInquiryAccount = createAsyncThunk(
    'inquiry/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/bank/Account-Inquiry`, body, {headers})
            if(response.data.status.message === "Success") {
                return {data : response.data, message : "Success"}
            } else {
                return rejectWithValue(response.data.status.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postBankAccount = createAsyncThunk(
    'bank/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/bank/vendor`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postStoreProfile = createAsyncThunk(
    'profile/post',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/vendor/profile`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAllMasterBank = createAsyncThunk(
    'bank-master/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank?category=Disbursement`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const removeBankAccount = createAsyncThunk(
    'bank/remove',
    async (id : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/bank/vendor?bank_id=${id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const changeDefaultBankAccount = createAsyncThunk(
    'bank/default',
    async (id : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/bank/vendor/default?bank_id=${id}&vendor_id=${userCredentials.vendor_id}`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);