import React, { useState, useEffect } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Stack,
    TextField,
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails, 
} from '@mui/material'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDataPurchaseOrdersDP, getPurchaseOrdersWaitingDPLoadMore, postConfirmationDP } from '../reducers/purchaseOrdersReducers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import TableItemDownPayment from './TableItemDownPament';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../../components/LoaderProgress';
import swal from 'sweetalert';


const CardOrderDP : React.FC<any> = ({ 
    data, 
    waitingDPSearch
}) => {
    const dispatch = useDispatch()
    const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)

    const [dataOrder, setDataOrder] = useState<any>([]);
    const [hasMore,setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    // const fetchMoreData = () => {
    //     setTimeout(() => {
    //         if(increase >= data.length ) {  
    //             setDataOrder(data)
    //             setHasMore(false)
    //         } else {
    //             setDataOrder(data.slice(0,increase + 50))
    //             setIncrease(increase + 50)
    //         }
    //     }, 500);
    // };

    const fetchMoreData = async () => {
        const body = {
            searchTopic : waitingDPSearch.searchTopic,
            searchValue: waitingDPSearch.searchValue,
            rangeTopic : waitingDPSearch.rangeTopic,
            rangeFrom: waitingDPSearch.rangeFrom,
            rangeTo: waitingDPSearch.rangeTo,
            limit: waitingDPSearch.limit,
            skip: page + 1,
            search : waitingDPSearch.search,
        }
        const newdata : any = await dispatch(getPurchaseOrdersWaitingDPLoadMore(body))
        if(newdata.type === "purchase-orders-dp-loadmore/get/fulfilled"){
            setPage(page + 1)
            let array = [...dataOrder, ...newdata.payload.data]
            if(newdata.payload.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataOrder(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    const [open, setOpen] = useState(false);
    const [nominalDP, setNominalDP] = useState("");
    const [dataFile, setDataFile] = useState<any>([]);
    const [dataID, setDataID] = useState<any>("");


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNominalDP("")
        setDataFile([])
    };

    const onClickSubmit = () => {
        if(nominalDP !== "") {
            const body = {
                "id": dataID,
                "nominal": nominalDP,
            }
            dispatch(postConfirmationDP(body))
        }
    }

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if(store_purchaseorders.confirm) {
            handleClose()
            setTimeout(() => {
                dispatch(getDataPurchaseOrdersDP(waitingDPSearch))
            }, 500);
        }
        // eslint-disable-next-line
    }, [store_purchaseorders.confirm]);

    useEffect(() => {
        setDataOrder(data)
        if(data.length < waitingDPSearch.limit) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [data]);

    return (
    <Stack>
        <div>
          <InfiniteScroll
            dataLength={dataOrder.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoaderProgress/>}
            scrollableTarget="scrollableDiv"
            endMessage={
                <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={5} mb={5}>
                    <Box>
                        <p>No more order ...</p>
                    </Box>
                    <Box pt={1}>
                        <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                            <p>Back to Top</p>
                        </Button>
                    </Box>
                </Stack>
              }
          >
            { dataOrder?.map((val : any, i : any) => (
            <Box mb={4} mt={4} key={i}>
                <Paper elevation={2} sx={{ borderLeft: '3px solid #0091d6', borderRight: '3px solid #0091d6' }}>
                    <Paper sx={{ borderTop: '3px solid #0091d6' }}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Box fontWeight="bold" pl={2} pt={1} fontSize={14}> Order ID : {val.code_po}</Box>
                                <Box fontWeight="normal" pl={2} pb={1} fontSize={12}> Date : {moment(val.date).format('ll')}</Box>
                            </Grid>
                            <Grid item>
                            <Box pr={2} pt={1}>
                                <Button 
                                    size="small" color="primary" variant="outlined"
                                    onClick={() => {
                                        handleClickOpen()
                                        setNominalDP(val.down_payment)
                                        if(val.proof_of_advance_payment) {
                                            setDataFile([val.proof_of_advance_payment])
                                        }
                                        setDataID(val._id)
                                    }}
                                >
                                    { !val.proof_of_advance_payment ? "Waiting Payment" : "Confirmation Payment" }
                                </Button>
                            </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                <Box p={2}>
                    <Grid container spacing={3} >
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                <Box pr={1} fontSize={14}>{val.buyer.name} </Box>
                                {/* <Button size="small" color="primary" variant="outlined">
                                    Contact Buyer
                                </Button> */}
                            </Box> 
                            <Box pt={1} fontWeight="normal" fontSize={12}>
                                <Box style={{width: '80%'}}>{val.buyer.phone}</Box>
                                <Box style={{width: '80%'}}>{val.buyer.address}</Box>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Box fontWeight="bold" pt={1} fontSize={13}> 
                                Total Price : Rp. {(val.grand_total).toLocaleString()}
                            </Box>
                            <Box fontWeight="bold" pt={1} fontSize={13}> 
                                Down Payment : Rp. {val.down_payment ? (val.down_payment).toLocaleString() : "-"}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2} >
                    <Paper elevation={3}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: '#0091d6' }}
                            >
                                <Stack flexDirection="row" alignItems="center">
                                    <Box fontWeight="bold" fontSize={14} color="#fff">
                                        Click here to view items ({val.packages?.length})
                                    </Box>
                                    <Box pl={1}>
                                        <ExpandMoreIcon sx={{ color: '#fff', marginTop: '4px' }}/>
                                    </Box>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Paper style={{ backgroundColor: '#ddd' }}>
                                        <TableItemDownPayment 
                                            data={val.packages}  
                                        /> 
                                    </Paper>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Box>
                </Paper>
            </Box>
            ))}
        </InfiniteScroll>
        </div>
        {/* DIALOG CONFIRMATION */}
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirmation Down Payment"}
            </DialogTitle>
            <DialogContent>
            { dataFile.length === 0 ? 
                <Box pt={1}>Buyer not yet paid down payment</Box>
                :
                <Stack sx={{ pt:1 }} flexDirection="column" alignContent="center">
                    <Box pb={1}>
                        <Box pb={1}>Proof of payment </Box>
                        { dataFile.length === 0  ? null :
                            <img src={dataFile[0].file.url} alt="file" width="200px" />
                        }
                    </Box>
                    <TextField
                        label="Nominal Down Payment"
                        defaultValue={nominalDP}
                        onChange={(e) => setNominalDP(e.target.value)}
                        type="number"
                        size="small"
                        fullWidth
                        disabled
                    />
                </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Close</Button>
                <Button 
                    onClick={() => onClickSubmit()} 
                    variant="contained" color="primary"
                    disabled={dataFile.length === 0 ? true : false}
                >
                    { store_purchaseorders.loading_confirm ? 
                        <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                        "Confirm"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    </Stack>
    )
}

export default CardOrderDP
