import { useState, useEffect } from 'react';
import { 
    Stack, 
    Paper, 
    Box, 
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import Select from 'react-select'
import { userCredentials } from '../../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { getMasterCategoryList, getMasterTax, removeProduct, updateProducts } from '../../reducers/productsReducers';
import { getAllWarehouse } from '../../../warehouse/reducers/warehouseReducers';
import swal from 'sweetalert';
import moment from 'moment';
import TextAreaEditor from '../../create/components/TextAreaEditor';
import NewCategoryTree from '../../../../../components/CategoryTree';
import ImagesItems from '../../create/components/ImagesItems';
import { useMediaQuery } from '@mui/material'


let images_items = [
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    },
    {
        image : ""
    }
]


const DetailUpdateProduct : React.FC<any> = ({ dataproduct }) => {
    const mobileDisplay = useMediaQuery('(min-width: 480px)')
    const dispatch = useDispatch()
    const store_product = useSelector((state : RootState) => state.products)
    const state_warehouse = useSelector((state : RootState) => state.warehouse)

    const [imagesItems, setImagesItems] = useState<any>(images_items);

    const [valueProduct, setValueProduct] = useState<any>({
        name: "",
        image : "",
        brand : "",
        short_description: "",
        made_date : "",
        expired_date : "",
        retail_price: "",
        discount: "",
        dimension_width : "",
        dimension_length : "",
        dimension_height : "",
        dimension_weight : "",
        stock: "",
        minimum_order_quantity: "",
        storage_rack : "",
        storage_bin : "",
        storage_level : "",
        payment_terms: "", 
        category_id : "",
        sku_product : "",
        vategory_tree : [],
        product_country : "",
        product_volume : "",
        product_size : "",
        special_handling : ""
    });

    const [loaded, setLoaded] = useState(false);
    const [valueDescription, setValueDescription] = useState("");

    const [errorDescription, setErrorDescription] = useState(false);

    const [defaultCategory, setDefaultCategory] = useState<any>([]);

    const [optionsTax, setOptionsTax] = useState<any[]>([]);
    const [optionsWarehouse, setOptionsWarehouse] = useState<any[]>([]);

    const [selectedTax, setSelectedTax] = useState<any>([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState<any>([]);
    
    const [errorTax, setErrorTax] = useState<boolean>(false);
    const [errorWarehouse, setErrorWarehouse] = useState<boolean>(false);
    const [errorCategory, setErrorCategory] = useState<boolean>(false);


    const [dataCategory, setDataCategory] = useState<any>([]);
    const [selectedData, setSelectedData] = useState<any>([]);
 
    const handleChangeTax = (value: any) : void => {
        setErrorTax(false)
        setSelectedTax(value)
    }
    const handleChangeWarehouse = (value: any) : void => {
        setErrorWarehouse(false)
        setSelectedWarehouse(value)
    }

    const onchangeValue = (e : any) => {
        const { name, value } = e.target
        setValueProduct({...valueProduct, [name] : value })
    }

    const onchangeValueNumeric = (e : any) => {
        const { name, value } = e.target
        let value_number = value === "" ? 0 : parseInt(value)
        setValueProduct({...valueProduct, [name] : value_number })
    }
    const checkError = () => {
        let error = true
        if(selectedTax.length === 0) {
            setErrorTax(true)
            error = true
        } else if (selectedWarehouse.length === 0) {
            setErrorWarehouse(true)
            error = true
        } else if (valueProduct.category_id === "") {
            setErrorCategory(true)
            error = true
        } else if (imagesItems[0].image === "") {
            swal("Error","Please upload at least one image!",'error')
            error = true
        } else {
            error = false
        }
        return error
    }

    const getImagesArray = (data: any) => {
        let images = []
        for(let i of data) {
            images.push(i.image)
        }
        let filter = images.filter(ele => ele !== "")
        return filter
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        let data_submit = {
            id: dataproduct._id,
            products : {
                "vendor_id": userCredentials.vendor_id,
                "vendor_name": userCredentials.vendor_name,
                "name": valueProduct.name,
                "variance_name": "",
                "description": valueDescription, 
                "short_description": valueProduct.short_description,
                "made_date": valueProduct.made_date,
                "expired_date": valueProduct.expired_date,
                "brand": valueProduct.brand,
                "category_id": selectedData.slice(-1).pop()._id,
                "category_tree" : selectedData,
                "retail_price": parseInt(valueProduct.retail_price),
                "discount": parseInt(valueProduct.discount) / 100,
                "images_product": getImagesArray(imagesItems),
                "dimension": {
                    "width": valueProduct.dimension_width,
                    "length": valueProduct.dimension_length,
                    "height": valueProduct.dimension_height,
                    "weight": valueProduct.dimension_weight
                },
                "SKU" : valueProduct.sku_product,
                "status": "OPEN",
                "pajak_id": selectedTax.id,
                "pajak": {
                    title : selectedTax.label,
                    value : selectedTax.pajak,
                },
                "tax" : {
                    value : selectedTax.pajak,
                    title : selectedTax.label
                },
                "stock": parseInt(valueProduct.stock),
                "minimum_order_quantity": parseInt(valueProduct.minimum_order_quantity) === 0 ? 1 : parseInt(valueProduct.minimum_order_quantity) ,
                "warehouse_id": selectedWarehouse.value,
                "storage": {
                    "rack": valueProduct.storage_rack,
                    "bin": valueProduct.storage_bin,
                    "level": valueProduct.storage_level
                },
                "payment_term" : {
                    name : `${valueProduct.payment_terms} Days`,
                    value : parseInt(valueProduct.payment_terms),
                },
                "company_code" : userCredentials.company_code,
                "country" : valueProduct.product_country,
                "volume" : valueProduct.product_volume,
                "size" : valueProduct.product_size,
                "special_handling" : valueProduct.special_handling
            }
        }
        if(!checkError()) {
            dispatch(updateProducts(data_submit))
            // console.log(data_submit, 'data submit')
        } 
    }

    useEffect(() => {
        if(store_product.success_update) {
            setTimeout(() => {
                window.location.href = "/dashboard/products"
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_product.success_update]);

    useEffect(() => {
        if(store_product.success_remove) {
            setTimeout(() => {
                window.location.href = "/dashboard/products"
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_product.success_remove]);


    // function to proceed data select tax
    const proceedOptions = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element.value * 100, label: element.title, id: element._id, pajak : element.value })
        }
        setOptionsTax(data_options)
    }

    // function to proceed data select warehouse
    const proceedOptionsWarehouse = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        setOptionsWarehouse(data_options)
    }

    const proceedImages = (data: any) => {
        let images = []
        for(let i of data) {
            images.push({ image : i })
        }
        return images
    }

    // useeffect for process data warehouse
    useEffect(() => {
        if(state_warehouse.data.length !== 0) {
            proceedOptionsWarehouse(state_warehouse.data)
        }
        // eslint-disable-next-line
    }, [state_warehouse.data]);

    // useeffect for process data tax
    useEffect(() => {
        if(store_product.tax.length !== 0) {
            proceedOptions(store_product.tax)
        }
        // eslint-disable-next-line
    }, [store_product.tax]);

    // useeffect for get master
    useEffect(() => {
        dispatch(getMasterTax())
        dispatch(getAllWarehouse())
        dispatch(getMasterCategoryList())
        // eslint-disable-next-line
    }, []);



    // useeffect for set default value
    useEffect(() => {
        if(dataproduct === null) {
            window.location.href = '/dashboard/products'
        } else {
            setImagesItems(proceedImages(dataproduct.images_product))
            setSelectedTax({...selectedTax, value : dataproduct.pajak._id, label: dataproduct.pajak.title, pajak: dataproduct.pajak.value })
            setSelectedWarehouse({...selectedWarehouse, value : dataproduct.warehouse_detail._id, label: dataproduct.warehouse_detail.name })
            setValueProduct({...valueProduct, 
                name: dataproduct.name,
                brand: dataproduct.brand,
                sku_product: dataproduct.SKU,
                image : dataproduct.images_product[0],
                description: dataproduct.description,
                short_description: dataproduct.short_description,
                made_date: dataproduct.made_date,
                expired_date: dataproduct.expired_date,
                retail_price: dataproduct.retail_price,
                discount: dataproduct.discount * 100,
                dimension_width : dataproduct.dimension.width,
                dimension_length : dataproduct.dimension.length,
                dimension_height : dataproduct.dimension.height,
                dimension_weight : dataproduct.dimension.weight,
                stock: dataproduct.stock,
                minimum_order_quantity: dataproduct.minimum_order_quantity,
                storage_rack : dataproduct.storage.rack,
                storage_bin : dataproduct.storage.bin,
                storage_level : dataproduct.storage.level,
                category_tree : dataproduct.category_tree,
                payment_terms: dataproduct.payment_term.value,
                category_id : dataproduct.category_id,
                product_country : dataproduct.country,
                product_volume : dataproduct.volume,
                product_size : dataproduct.size,
                special_handling : dataproduct.special_handling
            })
            setDefaultCategory(dataproduct.category_tree)
            setValueDescription(dataproduct.description)
            setLoaded(true)
        }
        // eslint-disable-next-line
    }, [dataproduct]);

    return (
        <Box>
            <Stack mt={4} mb={8}>
                <form onSubmit={onClickSubmit}>
                <Stack mb={2}>
                    <Paper elevation={2}>
                        <Stack p={mobileDisplay? 4 : 1}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Upload Product</Typography></Box>
                            <Grid container spacing={4}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Product Images</Typography>
                                    <Typography variant="subtitle2" fontWeight="300">Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px </Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <ImagesItems 
                                        imagesItems={imagesItems}
                                        setImagesItems={setImagesItems}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Product Information</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Name</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Name"
                                        size="small"
                                        name="name"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.name}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">SKU Product</Typography>
                                </Grid> 
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="SKU"
                                        size="small"
                                        name="sku_product"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.sku_product}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Category</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    { loaded &&  
                                    <NewCategoryTree 
                                        data={store_product.category} 
                                        loading={store_product.loading_category}
                                        dataCategory={dataCategory}
                                        setDataCategory={setDataCategory}
                                        selectedData={selectedData}
                                        setSelectedData={setSelectedData}
                                        setErrorCategory={setErrorCategory}
                                        defaultCategory={defaultCategory}
                                    /> }
                                     { errorCategory ? <div className="error-p"><p>Category is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Brand</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Type Brand Name"
                                        type="text"
                                        size="small"
                                        name="brand"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.brand}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>

                <Stack mb={2} flexDirection="column" alignContent="center" justifyContent="center" >
                    <Paper elevation={2}>
                        <Stack p={4}>
                            <Box pb={2}><Typography variant="h6" fontWeight="600">Product Detail</Typography></Box>
                            <Grid container columnSpacing={3} rowSpacing={2}>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Price</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Price in Rupiah"
                                        size="small"
                                        type="text"
                                        name="retail_price"
                                        onChange={(e) => onchangeValueNumeric(e)}
                                        value={valueProduct.retail_price}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Short Description</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Short Description"
                                        size="small"
                                        name="short_description"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.short_description}
                                        multiline
                                        rows={2}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Long Description</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    
                                    { loaded &&
                                    <>
                                    { mobileDisplay?
                                    <TextAreaEditor 
                                        value={ loaded && valueDescription}
                                        setValue={setValueDescription}
                                        error={errorDescription}
                                        setError={setErrorDescription}
                                        placeholder="You can type long description and even product ingredients in here.."
                                        loaded={loaded}
                                    /> 
                                    :
                                    <TextField 
                                        label="Short Description"
                                        size="small"
                                        name="short_description"
                                        onChange={(e) => setValueDescription(e.target.value)}
                                        value={valueDescription}
                                        multiline
                                        rows={2}
                                        fullWidth
                                    />
                                    }
                                    </>
                                    }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Stock</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Stock"
                                        size="small"
                                        type="text"
                                        name="stock"
                                        onChange={(e) => onchangeValueNumeric(e)}
                                        value={valueProduct.stock}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Made Date</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField
                                        value={moment(valueProduct.made_date).format('YYYY-MM-DD')}
                                        name="made_date"
                                        onChange={(e) => onchangeValue(e)}
                                        type="date"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Expired Date</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField
                                        value={moment(valueProduct.expired_date).format('YYYY-MM-DD')}
                                        name="expired_date"
                                        onChange={(e) => onchangeValue(e)}
                                        type="date"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Product Country</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Product Country"
                                        placeholder="Example: Indonesia"
                                        size="small"
                                        name="product_country"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.product_country}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Product Volume</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Product Volume"
                                        placeholder='Example: 1100ml'
                                        size="small"
                                        name="product_volume"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.product_volume}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Product Size</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Product Size"
                                        placeholder='Example: 100 grams'
                                        size="small"
                                        name="product_size"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.product_size}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Special Handling</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Special Handling"
                                        placeholder='Example: Stored in -4 celcius in Freezer'
                                        size="small"
                                        name="special_handling"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.special_handling}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Discount</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Discount in %"
                                        size="small"
                                        type="text"
                                        name="discount"
                                        onChange={(e) => onchangeValueNumeric(e)}
                                        value={valueProduct.discount}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Minimum Order</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Minimum Order"
                                        size="small"
                                        type="text"
                                        name="minimum_order_quantity"
                                        onChange={(e) => onchangeValueNumeric(e)}
                                        value={valueProduct.minimum_order_quantity}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Tax</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <Select
                                        placeholder="Select Tax"
                                        value={selectedTax}
                                        isSearchable={true}
                                        options={optionsTax && optionsTax}
                                        onChange={handleChangeTax}
                                        id="select-style-mes-product"
                                    />
                                    { errorTax ? <div className="error-p"><p>Tax is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Dimension</Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Width in cm"
                                        size="small"
                                        type="number"
                                        name="dimension_width"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.dimension_width}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Length in cm"
                                        size="small"
                                        type="number"
                                        name="dimension_length"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.dimension_length}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Height in cm"
                                        size="small"
                                        type="number"
                                        name="dimension_height"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.dimension_height}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Weight in kg"
                                        size="small"
                                        type="number"
                                        name="dimension_weight"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.dimension_weight}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Warehouse</Typography>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <Select
                                        placeholder="Select Warehouse"
                                        value={selectedWarehouse}
                                        isSearchable={true}
                                        options={optionsWarehouse && optionsWarehouse}
                                        onChange={handleChangeWarehouse}
                                        id="select-style-warehouse-product"
                                    />
                                    { errorWarehouse ? <div className="error-p"><p>Warehouse is required</p></div> : null }
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Storage</Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Rack"
                                        size="small"
                                        type="text"
                                        name="storage_rack"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.storage_rack}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Bin"
                                        size="small"
                                        type="text"
                                        name="storage_bin"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.storage_bin}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Level"
                                        size="small"
                                        type="text"
                                        name="storage_level"
                                        onChange={(e) => onchangeValue(e)}
                                        value={valueProduct.storage_level}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}/>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Typography variant="body1" fontWeight="500">Payment Terms</Typography>
                                </Grid>
                                <Grid item xl={2} lg={2} xs={12}>
                                    <TextField 
                                        label="Payment terms by days"
                                        size="small"
                                        type="text"
                                        name="payment_terms"
                                        onChange={(e) => onchangeValueNumeric(e)}
                                        value={valueProduct.payment_terms}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                
                            </Grid>
                        </Stack>
                    </Paper> 
                </Stack>
                <Stack >
                    { mobileDisplay?
                    <Stack pt={4} flexDirection="row" justifyContent="space-between">
                        <Button 
                            variant='outlined' color="error" 
                            onClick={() => dispatch(removeProduct(dataproduct._id))}
                        >
                            { store_product.loading_remove ? <span>Loading.. <CircularProgress color='inherit' size={20}/></span> : "Remove Product" } 
                        </Button>
                        <Button variant='contained' color="primary" type="submit" disabled={store_product.loading_update ? true : false}>
                           { store_product.loading_update ? <span>Loading... <CircularProgress color='inherit' size={20}/></span> : "Save Product" } 
                        </Button>
                    </Stack>
                    :
                    <Stack pt={4} flexDirection="row" justifyContent="space-between">
                        <Button 
                            variant='outlined' color="error" size='small'
                            onClick={() => dispatch(removeProduct(dataproduct._id))}
                        >
                            { store_product.loading_remove ? <span>Loading.. <CircularProgress color='inherit' size={12}/></span> : "Remove Product" } 
                        </Button>
                        <Button variant='contained' color="primary" type="submit" size='small' disabled={store_product.loading_update ? true : false}>
                           { store_product.loading_update ? <span>Loading... <CircularProgress color='inherit' size={12}/></span> : "Save Product" } 
                        </Button>
                    </Stack>
                    }
                </Stack>
                </form>
            </Stack>
        </Box>
    )
}

export default DetailUpdateProduct;
