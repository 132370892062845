import { Stack, Box, Skeleton, Button, TextField, Chip } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import { getDataInvoice, getInvoicesBySearch } from '../reducers/invoiceReducers';
import CloseIcon from '@mui/icons-material/Close';


const SearchDataInvoice : React.FC<any> = ({ 
    data, progressPending, setLoading
 }) => {

    const dispatch = useDispatch()

    const [dateSearch, setDateSearch] = useState(false);

    const [dataSearch, setDataSearch] = useState({
        searchTopic : "",
        searchValue: "",
        rangeTopic : "",
        rangeFrom: "",
        rangeTo: "",
        search : false
    });

    const optionSearch = [
        // { label : "Reset", value : "reset" },
        { label : "Buyer", value : "buyer._id" },
        { label : "Invoice Number", value : "invoice_number" },
        { label : "Due Date Range", value : "due_date" },
        { label : "Status", value : "status.name" }
    ]

    const [optionsValue, setoptionsValue] = useState<any>([]);
    const [selectedSearch, setSelectedSearch] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);


    const proceedOptions = (value : any, data: any) => {
        let data_options = []
        if(value === 'buyer._id') {
            for(let ele of data) {
                data_options.push({ value: ele.buyer._id, label: ele.buyer.name })
            }
        } else if(value === "due_date") {
            setDataSearch({...dataSearch, 
                rangeTopic: value
            })
        } else if(value === "status.name") {
            for(let ele of data) {
                data_options.push({ value: ele.lastStatus, label: ele.lastStatus })
            }
        } else if(value === "total") {
            for(let element of data) {
                data_options.push({ value: element.total, label: `Rp. ${element.total.toLocaleString()}` })
            }
        } else {
            for(let element of data) {
                data_options.push({ value: element[value], label: element[value] })
            }
        }
        const uniqueValuesSet = new Set();
        const filteredArr = data_options.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.value);
            uniqueValuesSet.add(obj.value);
            return !isPresentInSet;
        });
        setoptionsValue(filteredArr)
    }

    const handleChangeSearch = (value: any) : void => {
        proceedOptions(value.value, data)
        setSelectedSearch(value)
        setSelectedValue([])
        if(value.value === "due_date") {
            setDateSearch(true)
            setDataSearch({...dataSearch, 
                searchTopic: "",
                rangeTopic: value.value,
                rangeFrom: "",
                rangeTo: "",
                search : false
            })
        } else {
            setDateSearch(false)
            setDataSearch({...dataSearch, 
                searchTopic: value.value,
                rangeTopic: "",
                rangeFrom: "",
                rangeTo: "",
                search : false
            })

        }
    }

    const handleChangeValue = (value: any) : void => {
        setDateSearch(false)
        setSelectedValue(value)
        setDataSearch({...dataSearch, 
            searchValue: value.value,
            search : true
        })
    }

    const onClickSearch = () => {
        setLoading(true)
        let search = {
            searchTopic: dataSearch.searchTopic,
            searchValue: dataSearch.searchValue,
            rangeTopic: dataSearch.rangeTopic,
            rangeFrom: dataSearch.rangeFrom,
            rangeTo: dataSearch.rangeTo,
            dateSearch : dateSearch
        }
        dispatch(getInvoicesBySearch(search))
    }

    const onClickReset = () => {
        setLoading(true)
        setSelectedSearch([])
        setSelectedValue([])
        setDataSearch({...dataSearch, 
            searchTopic: "",
            searchValue: "",
            rangeFrom: "",
            rangeTo: "",
            rangeTopic: "",
            search : false
        })
        dispatch(getDataInvoice())
    }

    const inActiveButton = () => {
        let active = true
        if(dateSearch) {
            if(dataSearch.rangeTo !== "" && dataSearch.rangeFrom !== "") {
                active = false
            }
        } else {
            active = !dataSearch.search
        }
        return active
    }

    return (
        <Stack>
            { !progressPending ? 
             <Stack>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Stack flexDirection="row">
                        <Box mr={1} sx={{ minWidth: 250 }}>
                            <Select
                                placeholder="Search By"
                                value={selectedSearch}
                                options={optionSearch}
                                onChange={handleChangeSearch}
                                id={`select-style-parent-module-1`}
                            />
                        </Box>
                        { dataSearch.rangeTopic === "due_date" ? 
                        <Stack flexDirection="row">
                            <Box ml={1}>
                                <TextField 
                                    type="date"
                                    size="small"
                                    onChange={(e) => setDataSearch({...dataSearch, rangeFrom: e.target.value})}
                                    value={dataSearch.rangeFrom}
                                />
                            </Box>  
                            <Box pl={1} pt={1}>to</Box>
                            <Box ml={1}>
                                <TextField 
                                    type="date"
                                    size="small"
                                    value={dataSearch.rangeTo}
                                    onChange={(e) => setDataSearch({...dataSearch, rangeTo: e.target.value})}
                                />
                            </Box>
                        </Stack>  
                        :
                        <Box ml={1} sx={{ minWidth: 250 }}>
                            <Select
                                placeholder="Select Value"
                                value={selectedValue}
                                isSearchable={true}
                                options={optionsValue}
                                onChange={handleChangeValue}
                                id={`select-style-parent-module-1`}
                            />
                        </Box>
                        }
                        <Box ml={2} sx={{ minWidth: 250 }}>
                            <Button 
                                color="primary" variant="outlined"
                                disabled={inActiveButton()}
                                onClick={onClickSearch}
                            >Search</Button>
                        </Box>
                    </Stack>  
                    <Stack>
                        <Box>
                            <Button 
                                color="error" 
                                variant="contained"
                                disabled={selectedSearch.length === 0 ? true : false}
                                onClick={onClickReset}
                            >Clear Search</Button>
                        </Box>
                    </Stack>  
                </Stack> 
                { dataSearch.searchValue === "" ? null :
                <Stack pt={2} flexDirection="row" alignItems="center">
                    <Box>
                        <Chip 
                            label={
                            <Stack flexDirection="row" alignItems="center" >
                                <Box pr={1}>{selectedValue.label}</Box>
                                <Box 
                                    sx={{ cursor: 'pointer', paddingTop: '4px' }} 
                                    // onClick={onClickRemoveArea}
                                >
                                    <CloseIcon sx={{ color: 'red'}}/>
                                </Box>
                            </Stack>
                            } 
                            variant="outlined" 
                            color="primary"
                        /> 
                    </Box>
                </Stack> }
            </Stack> :
            <Stack flexDirection="row" rowGap={3}>
                <Box mr={1}><Skeleton width={250} height={70}/></Box>
                <Box ml={1}><Skeleton width={250} height={70}/></Box>
                <Box ml={2}><Skeleton width={120} height={70}/></Box>
            </Stack>
            }
        </Stack>
    )
}

export default SearchDataInvoice
