import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getAllVendorCompany, getAllVendorCompanyBySearch, loginAsVendorCompany } from './reducers/selectCompanyReducers';

const initialState: any = {
  data : [],
  loading: false,
  login_as_vendor : false,
  loading_as_vendor: false
};

export const getAllVendorLists = createSlice({
  name: 'selec-company',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get vendor list
      .addCase(getAllVendorCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVendorCompany.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;

      })
      .addCase(getAllVendorCompany.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get vendor list by searxh
      .addCase(getAllVendorCompanyBySearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVendorCompanyBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;

      })
      .addCase(getAllVendorCompanyBySearch.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      // login as vendor
      .addCase(loginAsVendorCompany.pending, (state) => {
        state.loading_as_vendor = true;
      })
      .addCase(loginAsVendorCompany.fulfilled, (state, action:any) => {
        state.loading_as_vendor = false;
        state.login_as_vendor = action.payload.data;

      })
      .addCase(loginAsVendorCompany.rejected, (state, action : any) => {
        state.loading_as_vendor = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});


export default getAllVendorLists.reducer;
