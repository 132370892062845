import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
import { fetchParentModules, fetchRolesCustom, postRolesCustom, removeRolesCustom, updateRolesCustom } from './reducers/customRolesReducers'

const initialState: any = {
  data: [],
  loading : false,
  data_module_parent: [],
  loading_module_parent : false,
  loading_create : false,
  create : false,
  loading_update : false,
  update : false,
  loading_remove : false,
  remove : false
};

export const rolesCustomSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // get role Custom
    [fetchRolesCustom.pending.type] : (state) => {
        state.loading = true
        state.create = false
        state.update = false
        state.remove = false
    },
    [fetchRolesCustom.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [fetchRolesCustom.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },

     // get parent modules 
     [fetchParentModules.pending.type] : (state) => {
        state.loading_module_parent = true
    },
    [fetchParentModules.fulfilled.type] : (state, action) => {
        state.loading_module_parent = false
        state.data_module_parent = action.payload.data
    },
    [fetchParentModules.rejected.type] : (state, action) => {
        state.loading_module_parent = false
        swal('Error', `${action.payload}`, 'error')
    },

    // post role Custom
    [postRolesCustom.pending.type] : (state) => {
        state.loading_create = true
    },
    [postRolesCustom.fulfilled.type] : (state) => {
        state.loading_create = false
        state.create = true
        swal('Success', "Success created role", 'success')
    },
    [postRolesCustom.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload}`, 'error')
    },

     // update role Custom
    [updateRolesCustom.pending.type] : (state) => {
        state.loading_update = true
    },
    [updateRolesCustom.fulfilled.type] : (state, action) => {
        state.loading_update = false
        state.update = true
        swal('Success', "Success updated role", 'success')
    },
    [updateRolesCustom.rejected.type] : (state, action) => {
        state.loading_update = false
        swal('Error', `${action.payload}`, 'error')
    },

     // remove role Custom
    [removeRolesCustom.pending.type] : (state) => {
        state.loading_remove = true
    },
    [removeRolesCustom.fulfilled.type] : (state) => {
        state.loading_remove = false
        state.remove = true
        swal('Success', "Success removed role", 'success')
    },
    [removeRolesCustom.rejected.type] : (state, action) => {
        state.loading_remove = false
        swal('Error', `${action.payload}`, 'error')
    }
  }
});

export default rolesCustomSlice.reducer;
