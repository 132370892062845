import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useMediaQuery, Box } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";
/* istanbul ignore file */

interface IBreadCrumbs {
    current : string
    isPage : boolean
    page ?: string
    link ?: string
}

const BreadCrumbs = ({ current, page, link, isPage } : IBreadCrumbs) => {
  const mobileDisplay = useMediaQuery('(min-width:480px)')
  const history = useHistory()
  
  return (
    <div>
      { mobileDisplay?
      <div role="presentation"  >
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 14 }}>
          <Link underline="hover" color="inherit" href="/dashboard">
            Dashboard
          </Link>
          { 
          /* istanbul ignore next */
          isPage ? 
          <Link
            underline="hover"
            color="inherit"
            href={link}
          >
            {page}
          </Link> : null }
          <Typography color="text.primary" variant='body2'>{current}</Typography>
        </Breadcrumbs>
      </div>
      :
      <Box display="flex" flexDirection="row">
          <ArrowBackIcon onClick={() => history.go(-1)} sx={{ marginRight: '12px', cursor:'pointer'}} color="primary"/>
          <span style={{ marginLeft:'-8px', fontSize:'16px'}} onClick={() => history.go(-1)}>Back</span>
      </Box>
      }
    </div>
  );
}

export default BreadCrumbs;