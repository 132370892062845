import BreadCrumbs from "../../../components/BreadCrumbs";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import DropzoneComponent from "./components/DropZone";
import UploadedImage from "./components/UploadedImage";
import AWS from 'aws-sdk'
import { setConfig, setBlob } from "../media_center/mediaCenterTypes";
import { RootState } from "../../../app/store";
import s3Client from "./configS3";
import { ListObjectsCommand } from "@aws-sdk/client-s3";
import swal from "sweetalert";
import { v4 as uuid } from "uuid";


const Config : setConfig ={
  digitalOceanSpaces: process.env.REACT_APP_S3_API_URL,
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME
}

function MediaCenter() {
  const [multipleFiles, setMultipleFiles] = useState<any[]>([])
  const [dataDigitalocean, setDataDigitalocean] = useState<any>([])
  const [isDelete,setIsDelete] = useState<number>(0)
  const [isUpload,setIsUpload] = useState<number>(0)
  const [isFilter, setIsFilter] = useState<boolean>(false)

  const random = uuid()

  const [loadingData, setLoadingData] = useState(true);
  const [successUploaded, setSuccessUploaded] = useState(false);
  
  const auth:any = useSelector((state : RootState) => state.login )
  const vendorName = auth.data.fullname
  const vendorCode = auth.data.company_code

  const bucketParams = {
    Bucket : "mpindo",
    Prefix : `vendor/${vendorName}-${vendorCode}`
  }
  
  useEffect(() =>{
    setTimeout(async() => {
      try {
        const data = await s3Client.send(new ListObjectsCommand(bucketParams))
        let arrData = await data?.Contents?.map((e:any,i:number) => e)  
        setDataDigitalocean([arrData])
        setLoadingData(false)
      } catch (error) {
        setLoadingData(false)
        swal("Error","Error fetching data" + error, "error")
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isUpload,isDelete])
  
  const handleUpload = async() =>{
    setSuccessUploaded(false)
    const spacesEndpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
    const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
    });
    let blob : setBlob | any = multipleFiles   
    for(let i=0; i< blob.length; i++){         
      const params = {
         Body: blob[i][0], 
         Bucket: `${Config.bucketName}`, 
         Key:  "vendor/"+vendorName+'-'+vendorCode+blob[i][0].name+random,
      };    
       
      await s3.putObject(params)
       .on('build', (request : any) => {
         request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`;
         request.httpRequest.headers['Content-Length'] = blob[i][0].size;
         request.httpRequest.headers['Content-Type'] = blob[i][0].type;
         request.httpRequest.headers['x-amz-acl'] = 'public-read';
      })
      .send((err : any) => {
        if (err) return  swal("Error", err, "error");
          swal("Success","upload success", "success")
          setSuccessUploaded(true)
          setIsFilter(false)
          setIsUpload((render:number) => render + 1)
      });
    }
  }  
  
  return (
    <Box sx={{width : '100%'}}>
      <BreadCrumbs current="Image Media Center" isPage={false}/>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt : 2, mb : 2 }}>
        <h2>Image Media Center</h2>
      </Stack>
      <DropzoneComponent multipleFiles={multipleFiles} setMultipleFiles={setMultipleFiles} handleSubmit={handleUpload} successUploaded={successUploaded}/>
      <UploadedImage loadingData={loadingData} uploadImage={dataDigitalocean} setIsDelete={setIsDelete} setIsFilter={setIsFilter} isFilter={isFilter} isDelete={isDelete}/>
    </Box>
  )
}

export default MediaCenter;