import React, { useEffect } from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Button,
    TextField,
    Stack,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDataPurchaseOrders, getPurchaseOrdersStatus, postPickPurchaseOrders } from '../reducers/purchaseOrdersReducers';
import { RootState } from '../../../../app/store';

const styleTableRow = () => {
  return {fontSize: '12px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const TableItemUpdate : React.FC<any> = ({ 
  data, 
  onChangeQuantity, 
  code_po, 
  code_package, 
  product_id, 
  onChangePrice,  
  onChangeNote, 
  lastStatus,
  vendor,
  buyer
}) => {  
  const dispatch = useDispatch()
  const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)

  console.log(data, 'dataaa bro')
  const onChangeQty = (row : any, qty : any) => {
    console.log(qty, 'quantity')

    const originalQty = row.origin_quantity
    const newQty = qty ? parseFloat(qty) : 0

    console.log(newQty, 'newQty')
    let optionSameQty = 
      {
        name : "Approved",
        timestamp : new Date()
      }
    let optionDifQty = 
      {
        name : "Waiting Approval",
        timestamp : new Date()
      }
    
    let compareToOriginalQty = originalQty === (newQty) ? optionSameQty : optionDifQty

    let copy_array = [...data]
    let origin_quantity = row.origin_quantity === undefined ? row.quantity : row.origin_quantity
    if((newQty) >= 0 ) {
      const newData = copy_array.map((obj : any) => {
        if(obj._id === row._id)
           return {
             ...obj,
             quantity: (newQty),
             origin_quantity : origin_quantity,
             sub_total_before_tax: Math.floor((newQty) * row.discount_price),
             sub_total: (row.single_tax_price * (newQty)) + (Math.floor((newQty) * row.discount_price)),
             statuses : [
               {
                 ...compareToOriginalQty
               }
             ]
           }
        return obj
      });
      onChangeQuantity(newData, code_package)
    }
  }

  const onChangeNoteText = (row : any, note : any) => {
    let copy_array = [...data]
      const newData = copy_array.map((obj : any) => {
        if(obj._id === row._id)
           return {
             ...obj,
             note: note,
           }
        return obj
      });
      onChangeNote(newData, code_package)
  }

  const onChangePriceUpdate = (row : any, price : any) => {    
    let price_value : any = price === "" ? 0 : parseInt(price)
    let copy_array = [...data]
    let discount_price = row.discount * parseInt(price_value)
    let single_tax_price = Math.floor(row.tax.value * (parseInt(price_value) - discount_price))
    const newData = copy_array.map((obj : any) => {
      if(row.retail_price === price)return null
      if(obj._id === row._id)
          return {
            ...obj,
            retail_price: price_value,
            discount_price: Math.floor((parseInt(price_value) - discount_price)),
            sub_total: (single_tax_price * row.quantity) + (Math.floor(row.quantity * (parseInt(price_value) - discount_price))),
            sub_total_before_tax: Math.floor(row.quantity * (parseInt(price_value) - discount_price)),
            single_tax_price : single_tax_price,
            statuses : [
              {
                name : "Waiting Approval",
                timestamp : new Date()
              }
            ]
          }
      return obj
    });
    onChangePrice(newData, code_package)
  }

  const onSubmitPickPack = () => {
    let original_item_pick : any = localStorage.getItem(`${code_package}`)
    let parse_data = JSON.parse(original_item_pick)
    let data_change = data

    let final_item = []

    for(let original of parse_data) {
      for(let change of data_change) {
        if(original._id === change._id) {
          final_item.push({
            brand: change.brand,
            dimension: change.dimension,
            discount: change.discount,
            discount_price: Math.floor(change.discount_price),
            images_product: change.images_product,
            name: change.name,
            note: change.note,
            payment_term: change.payment_term,
            quantity: change.quantity,
            retail_price: change.retail_price,
            sku: change.sku,
            tax: change.tax,
            slug: change.slug,
            reported_times: change.reported_times,
            sub_total_before_tax: change.sub_total_before_tax,
            single_tax_price: change.single_tax_price,
            statuses: change.statuses,
            storage: change.storage,
            sub_products: change.sub_products,
            sub_total: change.sub_total,
            vendorId: change.vendorId,
            vendor_name: change.vendor_name,
            warehouse: change.warehouse,
            _id: change._id,
            productId: change.productId,
            down_payment_setting: change.down_payment_setting,
            retail_price_original : parseInt(original.retail_price),
            discount_price_original : Math.floor(parseInt(original.discount_price)),
            quantity_original : parseInt(original.quantity),
            sub_total_original : Math.floor(parseInt(original.sub_total)),
          })
        }
      }
    }
    
    const data_send = {
      code_po : code_po,
      vendor : vendor,
      buyer : buyer,
      id : product_id,
      items : final_item,
      lastStatus: lastStatus,
    }
    
    dispatch(postPickPurchaseOrders(data_send))
  }

  useEffect(() => {
    if(store_purchaseorders.pick) {
      setTimeout(() => {
          const search = {
            searchTopic : ['lastStatus'], 
            searchValue: ['New'],
            rangeTopic : "",
            rangeFrom: "",
            rangeTo: "",
            search : false,
            limit : "20",
            skip : "0"
          }
          dispatch(getDataPurchaseOrders(search))
          dispatch(getPurchaseOrdersStatus())
        }, 1000);
    } 
    // eslint-disable-next-line
  }, [store_purchaseorders.pick]);

  function sumSubPrice (data:any) {
    const totalPriceVendor = data.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceVendor;
  }

  return (
    <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700, width: "60px"}} sx={styleTableRow()}>No</TableCell>
                    <TableCell style={{fontWeight: 700,  width: "150px"}} sx={styleTableRow()}>Name</TableCell>
                    <TableCell style={{fontWeight: 700,}} sx={styleTableRow()}>Retail Price</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Disc%</TableCell>
                    <TableCell style={{fontWeight: 700, width: "110px" }} sx={styleTableRow()}>Final Price</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Qty</TableCell>
                    <TableCell style={{fontWeight: 700}} sx={styleTableRow()}>Ppn</TableCell>
                    <TableCell style={{fontWeight: 700, width: "120px"}} sx={styleTableRow()}>Sub Total</TableCell>
                    <TableCell style={{fontWeight: 700,}} sx={styleTableRow()}>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { data.map((row :any, i : any) => (
                <TableRow key={i} >
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    <TextField
                      label="Price"
                      value={row.retail_price}
                      onChange={(e) => onChangePriceUpdate(row, e.target.value)}
                      type="text"
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    {Math.floor(row.discount * 100)}%
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    RP. {Math.floor(row.discount_price).toLocaleString()}
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    <TextField
                      label="Quantity"
                      value={parseFloat(row.quantity)}
                      onChange={(e) => onChangeQty(row, e.target.value)}
                      type="number"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{step: "any"}}
                    />
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    {row.tax.title}
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    RP. {Math.floor(row.sub_total_before_tax).toLocaleString()}
                  </TableCell>
                  <TableCell sx={styleTableColumn()} component="th" scope="row">
                    <TextField
                      label="Note"
                      value={row.note}
                      onChange={(e) => onChangeNoteText(row, e.target.value)}
                      type="text"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
        <Stack flexDirection="row" justifyContent="space-between" >
          <Box>
              <Stack flexDirection="row" sx={{ p: 2 }}>
                <Box fontSize="13px">
                  Sub Total + PPN :
                </Box>
                <Box>
                  <Box pl={2}><h5>Rp. {sumSubPrice(data).toLocaleString()}</h5></Box>
                </Box>
              </Stack>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={() => onSubmitPickPack()}>
              { store_purchaseorders.loading_pick ? 
                  <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                  "Submit Pick List"
              }
            </Button>
          </Box>
        </Stack>
    </Box>
  );
}

export default TableItemUpdate