import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getDataInvoice, getDataPackageDelivered, getInvoicesBySearch, getNewInvoiceNumber, getShippingCost, postCreateEFaktur, postNewInvoice } from './reducers/invoiceReducers';

const initialState: any = {
  data: [], 
  loaded : false,
  loading : true,
  data_package : [],
  loading_package : true,
  post_invoice : false,
  loading_post_invoice : false,
  invoice_number : null,
  loading_invoice_number : false,
  create_efaktur : false,
  loading_create_efaktur : false,
  shipping_cost : [],
  loading_shipping_cost : false,
  error_shipping_cost : null
};

export const getInvoiceSlice = createSlice({
  name: 'invoices',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder 
      // get invoices
      .addCase(getDataInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataInvoice.fulfilled, (state, action:any) => {
        state.loaded = true
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getDataInvoice.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get invoices by search
      .addCase(getInvoicesBySearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoicesBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getInvoicesBySearch.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get invoice number
      .addCase(getNewInvoiceNumber.pending, (state) => {
        state.loading_invoice_number = true;
      })
      .addCase(getNewInvoiceNumber.fulfilled, (state, action:any) => {
        state.loading_invoice_number = false;
        state.invoice_number = action.payload.data;
      })
      .addCase(getNewInvoiceNumber.rejected, (state, action : any) => {
        state.loading_invoice_number = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post invoices
      .addCase(postNewInvoice.pending, (state) => {
        state.loading_post_invoice = true;
      })
      .addCase(postNewInvoice.fulfilled, (state, action:any) => {
        state.loading_post_invoice = false;
        state.post_invoice = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postNewInvoice.rejected, (state, action : any) => {
        state.loading_post_invoice = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get delivered pacakage
      .addCase(getDataPackageDelivered.pending, (state) => {
        state.loading_package = true;
      })
      .addCase(getDataPackageDelivered.fulfilled, (state, action:any) => {
        state.loading_package = false;
        state.data_package = action.payload.data;
      })
      .addCase(getDataPackageDelivered.rejected, (state, action : any) => {
        state.loading_package = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post efaktur
      .addCase(postCreateEFaktur.pending, (state) => {
        state.loading_create_efaktur = true;
        state.create_efaktur = false
      })
      .addCase(postCreateEFaktur.fulfilled, (state, action:any) => {
        state.loading_create_efaktur = false;
        state.create_efaktur = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postCreateEFaktur.rejected, (state, action : any) => {
        state.loading_create_efaktur = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      //shipping cost
      .addCase(getShippingCost.pending, (state, action : any) => {
        state.loading_shipping_cost = true;
      })
      .addCase(getShippingCost.fulfilled, (state, action : any) => {
        state.loading_shipping_cost = false;
        state.shipping_cost = action.payload.data;
      })
      .addCase(getShippingCost.rejected, (state, action : any) => {
        state.loading_shipping_cost = false;
        state.error_shipping_cost = action.payload
      })
  },
});

export default getInvoiceSlice.reducer;
