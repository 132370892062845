import { useState, useEffect } from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box,
    Button,
    Stack,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { remove } from 'lodash'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import Axios from 'axios'
import swal from 'sweetalert';
import { RootState } from '../../../../app/store';
import { fetchParentModules } from '../reducers/customRolesReducers';


const ModuleItems : React.FC<any> = ({
    moduleItems,
    setModuleItems
}) => {

    const dispatch = useDispatch()

    const store_custom_role = useSelector((state : RootState) => state.custom_roles)

    const [open, setOpen] = useState(false);
    const [indexErrorSelected, setIndexErrorSelected] = useState<any>(null);
    const [errorSelected, setErrorSelected] = useState({
        error : false,
        message : ""
    });

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(true);
    };

    const getFeaturesByid = async (id: string) => {
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/feature?flag=VENDOR&parent_module_id=${id}`)
            if(response.data.errors === null) { 
                return response.data.data
            } else {
                swal("Error", `${response.data.message}`, 'error' )
            }
        } catch (error) {
            swal("Error", `${error}`, 'error' )
            handleClose()
        }
    }

    const [optionsParent, setOptionsParent] = useState<any[]>([]);
    const [errorParent, setErrorParent] = useState<boolean>(false);

    const proceedOptionsFeatures = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsChild = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsParent = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name, childs : element.childs })
        }
        const filter = data_options.filter(element => element.label !== "Dashboard")
        setOptionsParent(filter)
    }

    const checkSelectedParent = (value : string, items :any) => {
        let error = false

        for(let i of items) {
            if(i.selectedParent.label === value) {
                error = true
            }
        }
        return error
    }

    const handleChangeParent = async (value: any, index : any) => {
        setErrorSelected({...errorSelected, error : false, message : "" })
        setIndexErrorSelected(null)
        if(checkSelectedParent(value.label, moduleItems)) {
            setErrorSelected({...errorSelected, error : true, message : `${value.label} is already selected!` })
            setIndexErrorSelected(index)
        } else {
            setErrorParent(false)
            let copy_array = [...moduleItems]
            handleToggle()
            const data = await getFeaturesByid(value.value)
            if(data) {
                if(value.childs.length !== 0) {
                    const newData = copy_array.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            selectedParent: value,
                            has_child_module : true,
                            optionFeatures : proceedOptionsFeatures(data),
                            selectedFeatures : proceedOptionsFeatures(data),
                            optionsChild : proceedOptionsChild(value.childs),
                            selectedChild: proceedOptionsChild(value.childs)
                        }
                        return obj
                    });
                    setModuleItems(newData)
                    handleClose()
                } else {
                    const newData = copy_array.map((obj : any, i : any) => {
                        if(i === index)
                        return {
                            ...obj,
                            selectedParent: value,
                            optionFeatures : proceedOptionsFeatures(data),
                            selectedFeatures : proceedOptionsFeatures(data)
                        }
                        return obj
                    });
                    setModuleItems(newData)
                    handleClose()
                }
            }
        }

    }

    const handleChangeChild = (value: any, index : any) : void => {
        setErrorParent(false)
        let copy_array = [...moduleItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                selectedChild: value,
            }
            return obj
        });
        setModuleItems(newData)
    }

    const handleChangeFeatures = (value: any, index : any) : void => {
        setErrorParent(false)
        let copy_array = [...moduleItems]
        const newData = copy_array.map((obj : any, i : any) => {
            if(i === index)
            return {
                ...obj,
                selectedFeatures: value,
            }
            return obj
        });
        setModuleItems(newData)
    }

    const moveArray = (from:any, to:any, arr:any) => {
        const newArr = [...arr];
        const item = newArr.splice(from, 1)[0];
        newArr.splice(to, 0, item);
        setModuleItems(newArr)
    }

    const onClickAddNewField = () => {
        let copy_item = [...moduleItems]
        let new_object = {
            selectedChild : [],
            selectedParent : [],
            selectedFeatures: [],
            optionFeatures : [],
            optionChild : [],
            has_child_module : false,
            flag: "BUYER"
        }
        copy_item.push(new_object)
        setModuleItems(copy_item)
    }

    const onClickRemoveItem = (value : any, row: any) => {
        let copy_item = [...moduleItems]
        const items_remove = remove(copy_item, function(obj : any, index : any) {
            return index !== value
        });
        setModuleItems(items_remove)
        let array_option = [...optionsParent]
        array_option.push(row)
        setOptionsParent(array_option)
    }

    useEffect(() => {
        if(store_custom_role.data_module_parent.length !== 0) {
            proceedOptionsParent(store_custom_role.data_module_parent)
        }
        // eslint-disable-next-line
    }, [store_custom_role.data_module_parent]);

    useEffect(() => {
        dispatch(fetchParentModules())
        // eslint-disable-next-line
    },  []);


  return (
    <Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Stack>
          <Box mb={2}>
            <Button 
                variant="outlined" color="error"
                size="small"
                onClick={() => onClickAddNewField()}
            >
              Add More Module
            </Button>
          </Box>
        </Stack>
        <TableContainer component={Paper} style={{ minHeight: 500 }} >
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700, width: '250px' }}>Parent Module</TableCell>
                    <TableCell style={{fontWeight: 700,width: '250px' }}>Child Module</TableCell>
                    <TableCell style={{fontWeight: 700,}}>Features</TableCell>
                    <TableCell style={{fontWeight: 700, width: '50px' }}>Sort</TableCell>
                    <TableCell style={{fontWeight: 700,}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { moduleItems.map((row :any, i : any) => (
                <TableRow key={i} >
                    <TableCell component="th" scope="row">
                        <Box>
                            <Select
                                placeholder="Select Parent Module"
                                value={row.selectedParent}
                                isSearchable={true}
                                options={optionsParent && optionsParent}
                                onChange={(e) => handleChangeParent(e, i)}
                                id={`select-style-parent-module-${i}`}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                            />
                            { errorParent ? <div className="error-p"><p>Parent Module required</p></div> : null }
                        </Box> 
                        <Box pt={1}>
                            { errorSelected.error && indexErrorSelected === i ? <div className="error-p"><p>{errorSelected.message}</p></div> : null }
                        </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        { row.has_child_module ?
                        <Box>
                            <Select
                                placeholder="Select Child Module"
                                value={row.selectedChild}
                                isSearchable={true}
                                isMulti
                                options={row.optionChild}
                                onChange={(e) => handleChangeChild(e, i)}
                                id={`select-style-parent-module-${i}`}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                            />
                            { errorParent ? <div className="error-p"><p>Child Module is required</p></div> : null }
                        </Box>  : "Parent doesn't have child modules" }
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Box>
                            <Select
                                placeholder="Select Features"
                                value={row.selectedFeatures}
                                isSearchable={true}
                                isMulti
                                options={row.optionFeatures}
                                onChange={(e) => handleChangeFeatures(e, i)}
                                id={`select-style-parent-module-${i}`}
                                isDisabled={row.selectedParent.label === "Dashboard" ? true : false}
                            />
                            { errorParent ? <div className="error-p"><p>Feature is required</p></div> : null }
                        </Box> 
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Stack flexDirection="row" >
                            <Box
                                sx={{cursor: 'pointer', mr: 1}}
                                onClick={() => moveArray(i, i-1, moduleItems)}
                            >
                                <ArrowCircleUpIcon color="success" />
                            </Box> 
                            <Box
                                sx={{cursor: 'pointer'}}
                                onClick={() => moveArray(i, i+1, moduleItems)}
                            >
                                <ArrowCircleDownIcon color="info"/>
                            </Box> 
                        </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button 
                            color="error" onClick={() => onClickRemoveItem(i, row.selectedParent)}
                            disabled={row.selectedParent.label === "Dashboard" ? true : false}
                        >Remove</Button>
                    </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}

export default ModuleItems