import { useEffect, useState } from 'react'; 
import { 
    Stack,
    Button,
    Box,
    Dialog,
    Grid
} from '@mui/material';
import { TableColumn } from 'react-data-table-component'; 
import DataTableBase from '../../../../../components/TableData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import moment from 'moment'
import { confirmPayment, getDataInvoice, postCreateEFaktur,getShippingCost  } from '../reducers/invoiceReducers';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import SearchDataInvoice from './SearchDataInvoice';
import { userCredentials } from '../../../../../utilities/config';
// @ts-ignore
import swal from '@sweetalert/with-react'
import { getTokenByVendor } from '../../../setting/reducers/settingReducers';

const TableListInvoice = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const store_invoice = useSelector((state : RootState) => state.invoices)
    
    const store_setting = useSelector((state : RootState) => state.setting)

    const [loading, setLoading] = useState(true);

    const [detailInvoice, setDetailInvoice] = useState<any>({
        open : false,
        data : {}
    });


    const [detailTransaction, setDetailTransaction] = useState<any>({
        open : false,
        data : {}
    });

    const [detailProof, setDetailProof] = useState<any>({
        open : false,
        img : ""
    });

    useEffect(() => {
        dispatch(getDataInvoice())
        dispatch(getTokenByVendor())
        dispatch(getShippingCost ())
        // eslint-disable-next-line
    }, []);

    const onClickProceed = (row : any) => {
        let data_invoice : any = {
            invoice_number : row.invoice_number,
            items : row.items,
            reference_document : row.reference_document,
            buyer : row.buyer,
            vendor : row.vendor,
            down_payment: row.down_payment === undefined ? 0 : row.down_payment,
            status : row.lastStatus,
            infoPayment : row.infoPayment && row.lastStatus !== "Paid" ? row.infoPayment : [],
            from : 'invoice',
            shipping_costs : row.shipping_costs
        }        
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=summary`, 
            state : {
                data_invoice,
                name : 'detail'
            }
        })
    }

    const onClickCreateEFaktur = (row : any) => {
        if(store_setting.token_loaded) {
            if(store_setting.token.length !== 0) {
                if(store_setting.token[0].options[0].system) {
                    let body = {
                        document_number : null,
                        id: row._id
                    }
                    dispatch(postCreateEFaktur(body))
                } else {
                    Swal.fire({
                        title: 'Input Document Number',
                        input: 'text',
                        inputAttributes: {
                          autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Submit',
                        showLoaderOnConfirm: true,
                        preConfirm: (document_number) => {
                            let body = {
                                document_number : document_number,
                                id: row._id
                            }
                            return dispatch(postCreateEFaktur(body))
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                      }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close()
                        }
                    })
                }
            } else {
                swal('Error', 'Please setting your Online Pajak Package First!', 'error')
            }
        }

        
       
    }

    const checkDataLocal = (row:any) => {
        let data = false
        let array_exist = localStorage.getItem('faktur')
        let data_faktur = array_exist === null ? [] : JSON.parse(array_exist)

        for(let k of data_faktur) {
            if(k._id === row._id) {
                data = true
            } 
        } 

        return data
    }

    const [loadingBtn, setLoadingBtn] = useState(false);

    const onClickConfirmPayment =  () => {
        setLoadingBtn(true)
        let data_send = {
            code : detailInvoice.data.infoPayment[0].code,
            user : userCredentials.fullname
        } 
        setTimeout(async() => {
            const response : any = await dispatch(confirmPayment(data_send))
            if(response.payload.data) {
                setLoadingBtn(false)
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
        }, 1000);
    }


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'BUYER',
            selector: row => row.buyer.name,
            width: '200px'
        },
        {
            name: 'INVOICE NUMBER',
            selector: row => row.invoice_number,
            width: '200px'
        },
        {
            name: 'TOTAL',
            width: '200px',
            cell: (row) =>(
                <>
                    { row.grand_total ===0?
                    <div style={{ color:'red'}}>Rp. {row.grand_total.toLocaleString()}</div>
                    :
                    <div>Rp. {row.grand_total.toLocaleString()}</div>
                    }
                </>
            )
        },
        {
            name: 'DUE DATE',
            cell: (row) => (
                <div>{moment(row.due_date).format('ll')}</div>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <div>{row.lastStatus === undefined ? "Waiting Payment" : row.lastStatus}</div>
            )
        },
        {
            name: 'CONFIRMATION PAYMENT',
            width: '200px',
            cell: (row, i) => (
                <Stack direction="row" spacing={1}>
                    <Box>
                        { row.lastStatus === "Review Payment" ||  row.lastStatus === "Ready to Payment"  ? 
                        <Button 
                            variant="contained" 
                            color="success" 
                            size="small" 
                            onClick={() => {
                                setDetailInvoice({...detailInvoice, open: true, data : row})
                            }}
                            disabled={row.lastStatus !== "Review Payment" ? true : false}
                        >
                            REVIEW PAYMENT
                        </Button> : null }

                        { row.lastStatus === "Paid" ? 
                        <Button 
                            variant="outlined" 
                            color="error" 
                            size="small" 
                            onClick={() => {
                                setDetailTransaction({...detailTransaction, open: true, data : row})
                            }}
                        >
                            Transaction
                        </Button> : null }
                    </Box>
                </Stack>
            ),
        },
        {
            name: 'ACTION',
            width: '350px',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                onClickProceed(row)
                            }}
                        >   
                            Detail Invoice
                        </Button>
                    </Box> 
                    <Box>
                        { checkDataLocal(row) ? 
                        <Button 
                            variant="contained" 
                            color="success" 
                            size="small" 
                            fullWidth
                            disabled
                        >    
                            E-Faktur Created
                        </Button> : 
                        <Button 
                            variant="contained" 
                            color="success" 
                            size="small" 
                            fullWidth
                            disabled={row.lastStatus === "Paid" ? false : true}
                            onClick={() => {
                                onClickCreateEFaktur(row)
                            }}
                        >    
                            Create E-Faktur
                        </Button> }
                    </Box> 
                </Stack>
            ),
        },
    ];

    return (
        <div>

            <Box pb={2}>
                <SearchDataInvoice 
                    data={store_invoice.data}
                    progressPending={store_invoice.loading}
                    setLoading={setLoading}
                    loading={loading}
                />
            </Box>
            <DataTableBase 
                columns={columns} 
                data={store_invoice.data}
                progressPending={store_invoice.loading}
                pagination
                noDataComponent="You dont have any Invoice Yet!"
            />

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={detailInvoice.open}
                onClose={() => setDetailInvoice({...detailInvoice, open : false, data : {}})}
            >
                <Box p={3}>
                    { detailInvoice.data._id === undefined ? null : 
                    <Grid container flexDirection="column" alignItems="center" justifyContent="center" >
                        <Box pb={2} pt={2} fontSize={25} color="#ff9f43">Are you sure want to confirm this payment?</Box>
                        <Box pb={1} fontWeight="bold">Amount : Rp. {detailInvoice.data.grand_total.toLocaleString()}</Box>
                        <Box pb={1}>Payment Proof : </Box>
                        <Box onClick={() => setDetailProof({...detailProof, open: true, img : detailInvoice.data.infoPayment[0].imageUrl }) } sx={{textAlign: 'center'}}>
                            { detailInvoice.data.infoPayment[0].imageUrl === undefined ? 'Buyer is not yet upload proof payment' :
                            <img 
                                id="img-review-proof" 
                                alt="proof" 
                                src={detailInvoice.data.infoPayment[0].imageUrl} 
                                width="50%" height="50%" 
                            /> }
                        </Box>
                    </Grid> }
                    <Box pt={2} pb={1} />
                    <Grid container flexDirection="row" justifyContent="center" >
                        <Button 
                            variant='contained' color="primary" 
                            onClick={() => onClickConfirmPayment()}
                            disabled={loadingBtn}
                        >
                            { loadingBtn ? "Loading.."  : " Confirm Payment"}
                        </Button>
                        <Box pr={1} pl={1}/>
                        <Button 
                            variant='contained' color="error" 
                            onClick={() => setDetailInvoice({...detailInvoice, open : false, data : {}})}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Box>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={detailProof.open}
                onClose={() => setDetailProof({...detailProof, open : false, img : ""})}
            >
                { detailProof.img === "" ? null : 
                <Box p={3}>
                    <img 
                        id="img-review-proof" 
                        alt="proof" 
                        src={detailProof.img} 
                        width="100%" height="100%" 
                    />
                    <Box pt={2} pb={1} />
                    <Grid container flexDirection="row" justifyContent="center" >
                        <Box pr={1} pl={1}/>
                        <Button 
                            variant='contained' color="primary" 
                            onClick={() => setDetailProof({...detailProof, open : false, img : ""})}
                        >
                            OK
                        </Button>
                    </Grid>
                </Box> }
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={detailTransaction.open}
                onClose={() => setDetailTransaction({...detailTransaction, open : false, data : {}})}
            >
                <Box p={3}>
                    { detailTransaction.data._id === undefined ? null : 
                    <Grid container flexDirection="column" alignItems="center" justifyContent="center" >
                        <Box pb={1} fontWeight="bold">Amount Paid: Rp. {detailTransaction.data.grand_total.toLocaleString()}</Box>
                        <Box pb={1} fontWeight="bold">Date Paid: {moment(detailTransaction.data.paymentRecords.datePaid).format('lll')}</Box>
                        <Box pb={1} fontWeight="bold">Payment Method: {detailTransaction.data.paymentRecords.paymentMethod}</Box>
                        <Box pb={1} fontWeight="bold">Confirm By: {detailTransaction.data.paymentRecords.paidBy}</Box>
                        <Box pb={1}>Payment Proof : </Box>
                        <Box onClick={() => setDetailProof({...detailProof, open: true, img : detailTransaction.data.infoPayment[0].imageUrl }) } sx={{textAlign: 'center'}}>
                            { detailTransaction.data.infoPayment[0].imageUrl === undefined ? 'Buyer is not yet upload proof payment' :
                            <img 
                                id="img-review-proof" 
                                alt="proof" 
                                src={detailTransaction.data.infoPayment[0].imageUrl} 
                                width="50%" height="50%" 
                            /> }
                        </Box>
                    </Grid> }
                    <Box pt={2} pb={1} />
                    <Grid container flexDirection="row" justifyContent="center" >
                        <Button 
                            variant='contained' color="primary" 
                            onClick={() => setDetailTransaction({...detailTransaction, open : false, data : {}})}
                        >
                            OK
                        </Button>
                    </Grid>
                </Box>
            </Dialog>

        </div>
    )
}

export default TableListInvoice
