import { createAsyncThunk } from '@reduxjs/toolkit';
import crypto from 'crypto-js'; 
import Axios from 'axios'
import {
    InputState
} from '../loginTypes'
import { getAllModulesMenu, getAllModulesPath } from '../../../../utilities/setModulesPath';
import jwt_decode from "jwt-decode";

export const loginAction = createAsyncThunk(
    'auth/login',
    async (value : InputState , { rejectWithValue }) => {
 
        const body = {
            'grant_type': 'password',
            'username': value.email,
            'password': value.password,
            'client_id': `${process.env.REACT_APP_CLIENT_AUTH}`,
            'connection': `${process.env.REACT_APP_CONNECTION_AUTH}`,
            'scope': 'openid',  
        }

        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH}/oauth/token`, body)
            if(response.data) {
                const decoded : any = jwt_decode(response.data.id_token)               
                const decode_token = decoded['https://vendor.mpindo.id/profile']
                const headers = { 'Authorization' : `Bearer ${response.data.id_token}` }
                try {
                    const profil : any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/user-vendor/setting`, {headers})
                    if(profil.data.errors === null) {
                        let data = {
                            access_token : response.data.access_token,
                            id_token : response.data.id_token, 
                            expires_in : response.data.expires_in,
                            email : decode_token.email,
                            fullname : decode_token.fullname, 
                            features : decode_token.features, 
                            modules : decode_token.modules, 
                            avatar : decode_token.avatar,
                            auth_id : decode_token.auth_id,
                            first_time : decode_token.isFirstTime,
                            vendor_id : decode_token.vendor_id, 
                            company_code : decode_token.company_code,
                            vendor_name : decode_token.vendor_name,
                            // login_start_date : new Date(),
                            login: true,
                            created_at : decode_token.created_at,
                            company_detail : profil.data.data.company_detail,
                            role : profil.data.data.role, 
                            modules_path : getAllModulesPath(decode_token.isFirstTime, profil.data.data.company_detail.verification_status, profil.data.data.role),
                            modules_menu : getAllModulesMenu(decode_token.isFirstTime, profil.data.data.company_detail.verification_status, profil.data.data.role),
                        }
                        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                        localStorage.setItem('_?credentials', saveToLocalStorage)
                        return data
                    } else {
                        return rejectWithValue(profil.data.message)
                    }
                } catch (err : any) {
                    return rejectWithValue(err.response.data.message)
                }
            } else {
                return rejectWithValue(response.data.message.error_description)
            }
          } catch (err : any) {
            return rejectWithValue(err.response.data.error_description)
        }
    }
  );

