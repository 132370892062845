import { createSlice } from '@reduxjs/toolkit';
import { createNewChat } from './reducers/chatReducers';
import swal from 'sweetalert'
// import { getAllUsersTeams } from './reducers/productsReducers';

const initialState: any = {
  chat : false,
  loading: false
};

export const chatReducers = createSlice({
  name: 'products',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get product by slug
      .addCase(createNewChat.pending, (state) => {
        state.chat = false
        state.loading = true;
      })
      .addCase(createNewChat.fulfilled, (state, action:any) => { 
        state.loading = false;
        state.chat = true
      })
      .addCase(createNewChat.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

  },
});


export default chatReducers.reducer;
