import React, { useEffect, useState } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    CircularProgress
} from '@mui/material'
import SnackBarMessage from '../../../../components/SnackBarSuccess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableItem from './TableItem';
import moment from 'moment'
import TableItemUpdate from './TableItemUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { 
    getDataPackageDeliveredSearch,
    getDataPurchaseOrders,
    getNewInvoiceNumberOder,
    getPurchaseOrdersBySearchLoadMore,
    getPurchaseOrdersStatus,
    postDeliveryNotePurchaseOrders,
    postAddShippingCost
} from '../reducers/purchaseOrdersReducers';
import swal from 'sweetalert';
import { userCredentials } from '../../../../utilities/config';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { createNewChat, getUsersForChat } from '../../chat/reducers/chatReducers';
import { getAllUsersTeams } from '../../teams/reducers/teamsReducers';
import PrintDocument from '../../../../components/PrintOrders';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../../components/LoaderProgress';
import {
    pdf,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';


const CardOrder : React.FC<any> = ({ 
    status,
    data, 
    stateLocation,
    dataSearch
}) => {    
    const history = useHistory()
    const dispatch = useDispatch()
    const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)
    
    const store_chat = useSelector((state : RootState) => state.chat)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [dataOrder, setDataOrder] = useState<any>([]);
    const [hasMore,setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [expanded, setExpanded] = useState<any>(false);
    const [loadingCreateInvoice, setLoadingCreateInvoice] = useState({
        idx : null,
        loading: false
    });
    const [shipCost, setShipCost] = useState<number>(0)

    const [handleSnackbarMessage, setSnackbarMessage] = useState<boolean>(false)
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [idxItem, setIdxItem] = useState();


    const handleChangeAccordion =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchMoreData = async () => {
        const body = {
            searchTopic : dataSearch.searchTopic,
            searchValue: dataSearch.searchValue,
            rangeTopic : dataSearch.rangeTopic,
            rangeFrom: dataSearch.rangeFrom,
            rangeTo: dataSearch.rangeTo,
            limit: dataSearch.limit,
            skip: page + 1,
            search : dataSearch.search,
        }
        const newdata : any = await dispatch(getPurchaseOrdersBySearchLoadMore(body))
        if(newdata.type === "purchase-orders-search-loadmore/get/fulfilled"){
            setPage(page + 1)
            let array = [...dataOrder, ...newdata.payload.data.data]
            if(newdata.payload.data.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataOrder(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    const functionChangeQuantity = (newItems : any, id_package : string) => {        
        const newData = dataOrder.map((obj : any) => {
          if(obj.code_package === id_package)
             return {
               ...obj,
               items: newItems
             }
          return obj
        });
        setDataOrder(newData)
      }
  
      const functionChangeNote = (newItems : any, id_package : string) => {
        const newData = dataOrder.map((obj : any) => {
          if(obj.code_package === id_package)
             return {
               ...obj,
               items: newItems
             }
          return obj
        });
        setDataOrder(newData)
      }
  
      const functionChangePrice = (newItems : any, id_package : string) => {
        const newData = dataOrder.map((obj : any) => {
          if(obj.code_package === id_package)
             return {
               ...obj,
               items: newItems
             }
          return obj
        });
        setDataOrder(newData)
      }

    const onChangeQuantity = (newData:any, id_package : string) => {
        functionChangeQuantity(newData, id_package)
    }

    const onChangePrice = (newData:any, id_package : string) => {
        functionChangePrice(newData, id_package)
    }

    const onChangeNote = (newData:any, id_package : string) => {
        functionChangeNote(newData, id_package)
    }

    const functionChangeUpdate = (id_package : string, update : string, items : any) => {
        if(update === "false") {
          localStorage.setItem('default_items', JSON.stringify(items))
          const newData = dataOrder.map((obj : any) => {
            if(obj.code_package === id_package)
               return {
                 ...obj,
                 update: "true",
               }
            return obj
          });
          setDataOrder(newData)
        } else {
          const newData = dataOrder.map((obj : any) => {
            let check_local : any = localStorage.getItem('default_items')
            let default_items = check_local !== null ? JSON.parse(check_local) : []
            if(obj.code_package === id_package)
               return {
                 ...obj,
                 update: "false",
                 items : default_items
               }
            return obj
          });
          setDataOrder(newData)
        }
    }

    const changeTableUpdate = (id_package : string, update : string, items : any) => {
        if(update === "false") {
            localStorage.setItem(`${id_package}`, JSON.stringify(items))
        } 
        else {
            localStorage.removeItem(`${id_package}`)
        }
        functionChangeUpdate(id_package, update, items)
    }


    const getAllItems = (array : any) => {
        let all_items = []
        for(let element of array) {
            for(let ele of element.items) {
                 all_items.push(ele)
            }
        }
        return all_items
    }

    const sumDownPaymentDN = (data : any) => {
        const totalPriceALl = data.reduce((total : any, data : any) => {
            return total + data.down_payment
          }, 0)
          return totalPriceALl;
    }

    const getAllReference = (array : any) => {
        let reference_items = []
        for(let element of array) {
            reference_items.push({
                code_package: element.reference_doc.code_package,
                code_po: element.reference_doc.code_po,
                date_order: element.reference_doc.date_order,
                packageId: element.reference_doc.packageId,
                payment_term: element.reference_doc.payment_term,
                DeliveryNoteId : element._id
            })
        }
        
        return reference_items
    }

    const onClickProceed = (invoice_number :string, items : any, shipping_cost:number) => {        
        let data_invoice : any = {
            invoice_number : invoice_number,
            items : getAllItems(items),
            reference_document : getAllReference(items),
            buyer : items[0].buyer,
            vendor : items[0].vendor,
            shipping_address : items[0].delivery.shipping_address,
            down_payment: sumDownPaymentDN(items),
            infoPayment : items[0].infoPayment && items[0].lastStatus !== "Paid" ? items[0].infoPayment : [],
            shipping_costs : shipping_cost,
            from : 'order'
        }
        
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=summary`, 
            state : {
                data_invoice,
                name : 'create'
            }
        })
    }

    const clickPrintPickList = (params : any) => {
        axios({
            url: `${process.env.REACT_APP_API_SERVER}/fulfillment/generatePDF/${params._id}`, 
            responseType: 'blob', 
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${params.code_package}.pdf`); 
            document.body.appendChild(link);
            link.click();
        });
    }

    function sumDownPayment (data : any) {
        let down_payment_setting = (data[0].down_payment_setting / 100)
        const downpayment = data.reduce((total : any, data : any) => {
          return total + data.sub_total
        }, 0)
        return Math.floor(downpayment * down_payment_setting);
    }

    function checkItemReadytoPack(items:any) {
        let pack = false
        for(let k of items.items) {
            if(k.lastStatus === "Waiting Approval") {
                pack = false
            } else {
                pack = true
            }
        }
        return pack
    }

    const addShippingCost = (id:any,items:any) =>{                
        Swal.fire({
            title: "Shipping Cost",
            input: 'text',
            inputAttributes: {
                placeholder : 'Shipping cost',
            },
            cancelButtonColor: 'red',
            confirmButtonColor: '#3085d6',
            showCancelButton: true ,
            preConfirm: (result) =>{
                let value = parseInt(result)
                if (value < 0 || typeof value === 'string' || isNaN(result) === true) {         
                   return Swal.showValidationMessage('Invalid input, Input should have greater than 0') 
                }
            }     
        }).then((result) => {
            if (result.value) {
                let deliveryCost = parseInt(result.value)
                onSubmitPickPackAddShippingCost(items,deliveryCost)
            }
        });
    }

    const onSubmitPickPackAddShippingCost = (items:any,deliveryCost:number) => {
        setShipCost(deliveryCost)
        let arrData = items.items
        let final_item = []
            for(let item of arrData){
              final_item.push({
                brand: item.brand,
                dimension: item.dimension,
                discount: item.discount,
                discount_price: Math.floor(item.discount_price),
                images_product: item.images_product,
                name: item.name,
                note: item.note,
                payment_term: item.payment_term,
                quantity: item.quantity,
                retail_price: item.retail_price,
                sku: item.sku,
                tax: item.tax,
                slug: item.slug,
                reported_times: item.reported_times,
                sub_total_before_tax: item.sub_total_before_tax,
                single_tax_price: item.single_tax_price,
                statuses: item.statuses,
                storage: item.storage,
                sub_products: item.sub_products,
                sub_total: item.sub_total,
                vendorId: item.vendorId,
                vendor_name: item.vendor_name,
                warehouse: item.warehouse,
                _id: item._id,
                productId: item.productId,
                down_payment_setting: item.down_payment_setting,
                retail_price_original : parseInt(item.retail_price),
                discount_price_original : Math.floor(parseInt(item.discount_price)),
                quantity_original : parseInt(item.quantity),
                sub_total_original : Math.floor(parseInt(item.sub_total)),
              })
            }

        const data_send = {
          code_po : items.code_po,
          vendor : items.vendor,
          buyer : items.buyer,
          id : items._id,   
          items : final_item,
          lastStatus: items.lastStatus,
          shipping_cost: deliveryCost,
        }
        newShippingCost(deliveryCost,items)
        
        dispatch(postAddShippingCost(data_send))
        setSnackbarMessage(true)
      }

      function newShippingCost(deliveryCost:any,items:any){
        let arrDatas = dataOrder.map((value:any,i:number) =>{     
            if(value._id === items._id){
                return{
                    ...value,
                    shipping_cost : deliveryCost
                }
            }
            return value
        })
        setDataOrder(arrDatas)
      }
      
    const onClickCreateDN = (items : any, id:number) => {
        const data_send = {
            buyer : items.buyer,
            vendor : items.vendor,
            delivery : {
                code : "dncode-dummy",
                despatch_date : new Date(),
                shipping_address : items.address,
            },
            reference_doc : {
                "packageId": items._id,
                "code_po": items.code_po,
                "code_package": items.code_package,
                "date_order": new Date(),
                "payment_term" : {
                    name : items.payment_terms,
                    value : parseInt(items.payment_terms.slice(0, -4))
                },
            },
            date: new Date(),
            createdBy : userCredentials.fullname,
            items : items.items,
            down_payment: sumDownPayment(items.items),
            shipping_cost: shipCost,
        }
        if(items.shipping_cost <= 0){
            Swal.fire({
                icon:'warning',
                text: 'Are you sure you want to proceed with Rp.0 Shipping Cost?',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: 'red', 
                showCancelButton: true,      
            }).then((e) =>{ 
                if(e.isConfirmed){
                    dispatch(postDeliveryNotePurchaseOrders(data_send))
                }
            })
        }else{
            return dispatch(postDeliveryNotePurchaseOrders(data_send))
        }
    }

    const onClickCreateChat = async (row : any, i : any) => {
        setLoadingMessage(true)
        setIdxItem(i)
        const user_vendor : any = await dispatch(getUsersForChat(row.buyer._id))

        if(user_vendor.type === "chat-user/get/fulfilled") {
            let data_response = user_vendor.payload.data.data
            let user_names = []
            for(let k of data_response) {
                user_names.push(`${k.fullname} - ${k.company_code}`)
            }
            for(let l of state_teams.data) {
                user_names.push(`${l.fullname} - ${l.company_code}`)
            }
            let body = {
                username: `${userCredentials.fullname} - ${userCredentials.company_code}`,
                secret : userCredentials.vendor_id,
                title : `${row.buyer.name} & ${userCredentials.vendor_name}`,
                usernames : user_names
            }
            dispatch(createNewChat(body))
        } 
    }

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const onClickPrint = async (val:any) => {
        const doc = <PrintDocument data={val} items={val.items} />;
        const asPdf : any = pdf(); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `Purchase Order ${val.buyer.name} - ${val.code_package}.pdf`);
    }

    const handleReceivedPrice = (data:any) =>{
        let receivedTotalPrice : number = data.grn_items?.reduce((total:any, current:any) =>{
            return total + current.sub_total_received
        },0)
        return receivedTotalPrice
    }

    const handleTotalTax = (data:any) =>{
        let totalPrice : number = data.grn_items?.reduce((total:any, current:any) =>{
            return total + current.sub_total_tax
        },0)
        return totalPrice
    }

    const onClickCreateInvoice = async (val:any, idx:any) => {
        setLoadingCreateInvoice({...loadingCreateInvoice, loading: true, idx})
        const data_query = {
            searchTopic: 'reference_doc.code_package',
            searchValue: val.code_package
        }
        const invoicenumber : any = await dispatch(getNewInvoiceNumberOder())
        if(invoicenumber.type === "invoice-number-order/get/fulfilled") {
            if(invoicenumber.payload.data) {
                const datadn : any = await dispatch(getDataPackageDeliveredSearch(data_query)) 
                if(datadn.type === "package-delivery/get/fulfilled") {
                    if(datadn.payload.data.length !== 0) {
                        onClickProceed(invoicenumber.payload.data, datadn.payload.data, val.shipping_cost)
                        setLoadingCreateInvoice({...loadingCreateInvoice, loading: false, idx})
                    } else {
                        swal("Error", `Data delivery note not found` , "error")
                    }
                } else {
                    swal("Error", `${datadn.payload.message}` , "error")
                }
            } else {
                swal("Error", `Invoice number not found` , "error")
            }
        } else {
            swal("Error", `${invoicenumber.payload.message}` , "error")
        }
    }

    useEffect(() => {
        if(store_chat.chat) {
            setLoadingMessage(false)
            window.location.href = '/dashboard/message' 
        }
    }, [store_chat.chat]);

    useEffect(() => {
        if(store_purchaseorders.pack) {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
    }, [store_purchaseorders.pack]);

    useEffect(() => {
        if(store_purchaseorders.delivery) {
            setTimeout(() => {
                const search = {
                    searchTopic : ['lastStatus'], 
                    searchValue: ['Pick'],
                    rangeTopic : "",
                    rangeFrom: "",
                    rangeTo: "",
                    limit : "20",
                    skip : "0",
                    search : false
                }
                dispatch(getDataPurchaseOrders(search))
                dispatch(getPurchaseOrdersStatus())
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_purchaseorders.delivery]);

    useEffect(() => {
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setDataOrder(data)
        if(data.length < dataSearch.limit) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if(stateLocation.length !== 0) {
            setExpanded("0")
        } else {
            setExpanded(false)
        }
        // eslint-disable-next-line
    }, [stateLocation]);    

    return (
    <Stack>
        { status === "Delivered" ? 
        <Stack flexDirection="row">
            <Button 
                variant="outlined" 
                color="primary"
                size='small'
                onClick={() => {
                    history.push({
                        pathname: "/dashboard/finance/invoice",
                        search: `?page=create`, 
                    })
                }}
            >
                View All GRN
            </Button>
        </Stack> : null }
        <div>
        {/* body table */}
        <div>
          <InfiniteScroll
            dataLength={dataOrder.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoaderProgress/>}
            scrollableTarget="scrollableDiv"
            endMessage={
                <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={5} mb={5}>
                    <Box>
                        <p>No more order ...</p>
                    </Box>
                    <Box pt={1}>
                        <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                            <p>Back to Top</p>
                        </Button>
                    </Box>
                </Stack>
              }
          >
            { dataOrder?.map((val : any, i : any) => (
                <Box mb={2} mt={2} key={i}  >
                    <Paper elevation={2} sx={{ borderLeft: '3px solid #0091d6', borderRight: '3px solid #0091d6' }}>
                        <Paper sx={{ borderTop: '3px solid #0091d6' }}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Box fontWeight="bold" pl={2} pt={1} fontSize={14}> Package ID : {val.code_package}</Box>
                                    <Box pl={2} pb={1} fontSize={12} > Base On Order ID : {val.code_po}</Box>
                                </Grid>
                                <Grid item>
                                    <Box fontWeight="bold" pt={1} pr={2} fontSize={14}> Order Date : {moment(val.date).format('lll')}</Box>
                                    <Box pt={1} pb={1}>
                                        { status === "Delivered" ? 
                                        <Button 
                                            variant='contained' size="small" 
                                            color='primary'
                                            sx={{mr:2}}
                                            onClick={() => onClickCreateInvoice(val, i)}
                                            disabled={val.grn.length && val.grn[0].lastStatusInvoice && val.grn[0].lastStatusInvoice === "Invoicing" ? true : false}
                                        >
                                            { val.grn.length && val.grn[0].lastStatusInvoice && val.grn[0].lastStatusInvoice === "Invoicing" ? 
                                            <p>Invoice Created</p>
                                            : 
                                            <p> { loadingCreateInvoice.loading && loadingCreateInvoice.idx === i ? "Loading" : "Create Invoice" }</p>
                                            }
                                        </Button> : null }
                                        <Button 
                                            variant='outlined' size="small" 
                                            onClick={() => onClickPrint(val)}
                                        >
                                            Print Order
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Box p={2}>
                            <Grid container spacing={3} >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                        <Box pr={1} fontSize={14}>{val.buyer.name} </Box>
                                        {/* <Button 
                                            size="small" color="primary" variant="outlined"
                                            onClick={() => onClickCreateChat(val, i)}
                                            disabled={loadingMessage ? true : false}
                                        >
                                            { loadingMessage && idxItem === i ? "Loading" : "Contact Buyer" }
                                        </Button> */}
                                    </Box> 
                                    <Box pt={1} fontWeight="normal" fontSize={12}>
                                        <Box style={{width: '80%'}} >{val.buyer.phone}</Box>
                                        <Box style={{width: '80%'}}>{val.buyer.address}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" pt={1} fontSize={14}> Payment Terms : {val?.payment_terms}</Box>
                                    <Box fontWeight="bold" pt={1} fontSize={14}> Package ID : {val?.code_package}</Box>
                                    <Box fontWeight="bold" pt={3} fontSize={14}> { val?.lastStatus !== 'New'? `Shipping Cost : RP.${(val?.shipping_cost && (val?.shipping_cost).toLocaleString()) || "N/A"}` : null}</Box>
                                    <Box fontWeight="bold" pt={1} fontSize={14}> 
                                        Total Price : Rp. {(status === 'Delivered'? handleReceivedPrice(val) + parseInt(val.shipping_cost) + handleTotalTax(val) : parseInt(val.grand_total) + parseInt(val.shipping_cost)).toLocaleString()}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={2} >
                            <Paper elevation={3} >
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === `${i}`} onChange={handleChangeAccordion(`${i}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }}/>}
                                        aria-controls="panel1a-content"
                                        id={`panel1a-header-${val._id}`}
                                        sx={{ backgroundColor: '#0091d6' }}
                                    >
                                        <Stack flexDirection="row" alignItems="center">
                                            <Box fontWeight="bold" fontSize={14} color="#fff">
                                                Click here to {expanded === `${i}` ? "close" : "view"} items ({val.items?.length})
                                            </Box>
                                            <Box pl={1}>
                                                <ExpandMoreIcon sx={{ color: '#fff', marginTop: '4px' }}/>
                                            </Box>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack flexDirection="row" sx={{ mb:2, mt: 2 }}>
                                            <Box>
                                                { val.lastStatus === "New" ? 
                                                <Button 
                                                    size="small" 
                                                    color={val.update === "true" ? "error" : "primary"}
                                                    variant="contained"
                                                    onClick={() => changeTableUpdate(val.code_package, val.update, val.items)}
                                                >
                                                    { val.update === "false" ? "Update Pack List" : "Cancel" }
                                                </Button> :
                                                val.lastStatus === "Pick" ?
                                                <>
                                                <Button 
                                                    size="small" 
                                                    color={"primary"}
                                                    variant="contained"
                                                    onClick={() => onClickCreateDN(val,val.items[0]._id)}
                                                    disabled={checkItemReadytoPack(val) ? false : true}
                                                >
                                                    { store_purchaseorders.loading_delivery ? 
                                                        <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                                                        "Submit Pack List"
                                                    }
                                                </Button>
                                                <Button
                                                 size="small" 
                                                 color={"primary"}
                                                 variant="outlined"
                                                 sx={{ ml : 2}}
                                                 onClick={() =>addShippingCost(val.items[0]?._id,val)
                                                }
                                                >
                                                    Add Shipping Cost
                                                </Button>
                                                </>
                                                : null
                                                }
                                            </Box>
                                            <Box ml={2}>
                                            { val.lastStatus !== "New" ? null :
                                                <Button size="small" color="primary" variant="outlined"
                                                    onClick={() => clickPrintPickList(val)}
                                                >
                                                    Print Pick List
                                                </Button> 
                                            }
                                            </Box>
                                        </Stack>
                                        <Box>
                                            {  val.update === "false" ? 
                                            <TableItem 
                                                data={val.lastStatus === "Delivered" ? val.grn_items : val.items} 
                                                dataDelivered={val.grn_items}
                                                status={val.lastStatus}
                                                datas = { val }
                                                arrData={[dataOrder[i]]}
                                            /> 
                                            :
                                            <TableItemUpdate 
                                                onChangePrice={onChangePrice}
                                                onChangeQuantity={onChangeQuantity}
                                                onChangeNote={onChangeNote}
                                                data={val.items}
                                                code_package={val.code_package}
                                                code_po={val.code_po}
                                                product_id={val._id}
                                                lastStatus={val.lastStatus}
                                                vendor={val.vendor}
                                                buyer={val.buyer}
                                            />
                                            }
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Box>

                    </Paper>
                </Box>
            ))
            }
          </InfiniteScroll>
        </div>
        </div>
        <SnackBarMessage load={handleSnackbarMessage} setShippingCostMessage={setSnackbarMessage} message="Success add Shipping Cost" />
    </Stack>
    )
}

export default CardOrder
