import { Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../app/store'
import { getBuyerListsData } from '../buyer_lists/reducers/buyerListsReducer'
import { getDataInvoice } from '../finance/invoice/reducers/invoiceReducers'
import { getPurchaseOrdersData, getPurchaseOrdersStatus, getPurchaseOrdersWaitingDP } from '../orders/reducers/purchaseOrdersReducers'
import { getAllProduct } from '../products/reducers/productsReducers'
import {userCredentials} from './../../../utilities/config'
import CardStatistic from './components/CardStatistic'
import NewestOrders from './components/NewestOrders'
import UnverifiedWelcome from './components/UnverifiedWelcome'
import  find  from 'lodash/find';
import receivable from '../../../assets/img/dashboard/receiver.png'
import box from '../../../assets/img/dashboard/box.png'
import deal from '../../../assets/img/dashboard/deal.png'
import sales from '../../../assets/img/dashboard/sales.png'
import { useMediaQuery } from '@mui/material'


const array_status = [
    { label: "Ready to Process", value: "New" },
    { label: "Pick & Pack", value: "Pick" },
    { label: "Ready to Ship", value: "Ready to Ship" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    { label: "Paid", value: "Paid" },
    { label: "Canceled", value: "Cancel" },
]

function HomeDashboard() {

    const dispatch = useDispatch()
    const state_products = useSelector((state : RootState) => state.products)
    const store_buyerlists = useSelector((state : RootState) => state.buyer_lists)
    const store_invoice = useSelector((state : RootState) => state.invoices)
    const { data, data_status, data_dp, loading_dp, loading } = useSelector((state : RootState) => state.purchase_orders)

    const [totalPayable, setTotalPayable] = useState<any>(null);
    const [totalPurchases, setTotalPurchases] = useState<any>(null);

    const username = userCredentials === null ? "" : userCredentials.fullname
    const company = userCredentials === null ? "" : userCredentials.vendor_name
    const mobileDisplay = useMediaQuery('(min-width: 480px)')

    useEffect(() => {
        dispatch(getAllProduct())
        dispatch(getBuyerListsData())
        dispatch(getPurchaseOrdersData()) 
        dispatch(getPurchaseOrdersWaitingDP())
        dispatch(getDataInvoice())
        dispatch(getPurchaseOrdersStatus())
        // eslint-disable-next-line
    }, []);


    function sumTotalPayable (data : any) {
        return data.reduce((total : any, data : any) => {
          return total + data.grand_total
        }, 0)
    }

    const getStatusTotalPayable = (data:any) => {
        let filter = data.filter((key : any) => key.lastStatus !== "Paid")
        return sumTotalPayable(filter)
    }

    const getStatusTotalPurchases = (data:any) => {
        let filter = data.filter((key : any) => key.lastStatus === "Paid")
        return sumTotalPayable(filter)
    }

    function getDataListStatus(data:any, status :string) {
        let count = 0
        let order_status = find(data, { "_id": status })
        if(order_status !== undefined) {
          count = order_status.count
        } 
        return count
    }

    useEffect(() => {
        if(store_invoice.loaded) { 
            setTotalPayable(getStatusTotalPayable(store_invoice.data))
            setTotalPurchases(getStatusTotalPurchases(store_invoice.data))
        }
        // eslint-disable-next-line
    }, [store_invoice.loading]);

    return (
        <Box>
        { userCredentials.company_status === "UNVERIFIED" ? 
        <UnverifiedWelcome/>
        :
        <Box sx={mobileDisplay? {pl:2, pr:2} : {pl:1, pr:1}}>
            {/* { mobileDisplay? null : <Box sx={{ marginTop : '-15px', marginLeft : '-10px', marginBottom:'10px'}}><ArrowBackIcon color="primary" fontSize='medium'/></Box>} */}
           <h3>Welcome back, {username}</h3>
           <Box fontWeight="400">{company}</Box>

            <Stack sx={{pt:3}} >
                <Grid container spacing={3}>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/finance/invoice">
                        <CardStatistic 
                            label="Account Receivable"
                            value={totalPayable}
                            loading={store_invoice.loading}
                            currency={true}
                            background="#fff"
                            src={receivable}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/finance/invoice">
                        <CardStatistic 
                            label="Total Sales"
                            value={totalPurchases}
                            loading={store_invoice.loading}
                            currency={true}
                            background="#fff"
                            src={sales}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/products">
                        <CardStatistic 
                            label="Total Products"
                            value={state_products?.data.length}
                            currency={false}
                            loading={state_products?.loading}
                            background="#fff"
                            src={box}
                        />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link to="/dashboard/buyer-list">
                        <CardStatistic 
                            label="Prefered Buyer"
                            value={store_buyerlists?.data.length}
                            currency={false}
                            loading={store_buyerlists?.loading}
                            background="#fff"
                            src={deal}
                        />
                        </Link>
                    </Grid>
                </Grid>
            </Stack>

            <Stack sx={{pt:5}} >
                <Grid container spacing={4}>
                    <Grid item xl={8} lg={8} sm={6} xs={12}>
                        <NewestOrders 
                            data={data.slice(0,6)}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} sm={6} xs={12} mb={3}>
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} sm={12} xs={12}>
                                <Box fontWeight="500" fontSize={21}>
                                    Status Orders
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} sm={6} xs={12}>
                                <Link to="/dashboard/orders?status=Waiting DP">
                                <CardStatistic 
                                    label="Waiting DP"
                                    currency={false}
                                    value={data_dp.length}
                                    loading={loading_dp}
                                    background={data_dp.length ? "#ee4054" : "#fff"}
                                    color={data_dp.length ? "#fff" : "#000"}
                                    status={true}
                                />
                                </Link>
                            </Grid>
                            { array_status.map((val, idx) => (
                                <Grid item xl={6} lg={6} sm={6} xs={12} key={idx}>
                                    <Link to={`/dashboard/orders?status=${val.value}`}>
                                    <CardStatistic 
                                        label={val.label}
                                        currency={false}
                                        value={getDataListStatus(data_status && data_status, val.value)}
                                        loading={loading}
                                        background={getDataListStatus(data_status && data_status, val.value) > 0 ? "#ee4054" : "#fff"}
                                        color={getDataListStatus(data_status && data_status, val.value) > 0 ? "#fff" : "#000"}
                                        status={true}
                                    />
                                    </Link>
                                </Grid>
                            )) }
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </Box> }
    </Box>
    )
}

export default HomeDashboard
