import { Box, Stack, TextField, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableColumn } from 'react-data-table-component';
import { RootState } from '../../../app/store'
import DataTableBase from '../../../components/TableData'
import {userCredentials} from '../../../utilities/config'
import {  getAllVendorCompany, getAllVendorCompanyBySearch, loginAsVendorCompany } from './reducers/selectCompanyReducers'

function SelectCompany() {

    const dispatch = useDispatch()
    const store_vendor = useSelector((state : RootState) => state.all_vendor)

    // console.log(store_vendor, 'store buyerr')

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [indexUser, setIndexUser] = useState<any>(""); 

    useEffect(() => {
        dispatch(getAllVendorCompany())
        // eslint-disable-next-line
    }, []);

    const [searchCompany, setSearchCompany] = useState("");

    const onClickSearch = () => {
        if(searchCompany !== "") {
            let query = {
                vendor_name : searchCompany
            }
            dispatch(getAllVendorCompanyBySearch(query))
        }
    }

    useEffect(() => {
        if(store_vendor.login_as_vendor) {
            window.location.href = "/dashboard"
        }
        // eslint-disable-next-line
    }, [store_vendor.login_as_vendor]);


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'COMPANY NAME',
            selector: row => row.vendor_name,
        },
        {
            name: 'Owner Name',
            selector: row => row.fullname,
        },
        {
            name: 'Owner Email',
            selector: row => row.email,
        },
        {
            name: 'ACTION',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                setIndexUser(i)
                                dispatch(loginAsVendorCompany(row.auth_id))
                            }}
                            disabled={store_vendor.loading_as_vendor ? true : false}
                        >   
                            {store_vendor.loading_as_vendor && i === indexUser ? "Loading.." : "Login"}
                        </Button>
                    </Box> 
                </Stack>
            ),
        },
    ];
    return (
        <div>
           <div className="section-top">
                <h3>Welcome Super Admin - {userCredentials.fullname}</h3>
                <p>Please select company</p>
           </div>

           <Stack pt={3} pl={3} pr={3} direction="row">
               <Box sx={{width: 500, mr: 1}}>
               <TextField 
                    label="Search Company"
                    size="small"
                    fullWidth
                    value={searchCompany}
                    onChange={(e) => setSearchCompany(e.target.value)}
                />
               </Box>
               <Box>
                    <Button 
                        variant='contained'
                        disabled={searchCompany === "" ? true : false}
                        onClick={onClickSearch}
                    >
                       {store_vendor.loading ? "Loading.." : "Search"}
                   </Button>
               </Box>
               <Box ml={1}>
                    <Button 
                        variant='outlined'
                        onClick={() => {
                            setSearchCompany("")
                            dispatch(getAllVendorCompany())
                        }}
                    >
                       Clear
                   </Button>
               </Box>
            </Stack>

            <Stack p={3}>
                <DataTableBase 
                    columns={columns}
                    data={store_vendor.data}
                    progressPending={store_vendor.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Company Not Found"
                />
            </Stack>
        </div>
    )
}

export default SelectCompany
