import React from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box, 
    Stack,
    Button
} from '@mui/material';
import { useHistory } from 'react-router-dom';

const styleTableRow = () => {
  return {fontSize: '12px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const TableItemDownPayment : React.FC<any> = ({ data, status }) => {

  const history = useHistory()

  function sumSubPrice (data:any) {
    const totalPriceVendor = data.items.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceVendor;
  }

  return (
    <>
      { data.length === 0  ? `You dont have any ${status} package yet!` :
      <Box>
      { data.map((element:any, i:any) => (
          <Box mb={2} key={i}>
            <Box>
              <Paper elevation={3}>
                <Stack flexDirection="row" justifyContent="space-between" sx={{ p: 2 }}>
                  <Box>
                    <h5>Package ID: {element._id}</h5>
                    <h5>Payment Terms : {element.payment_terms}</h5>
                  </Box>
                  <Box>
                    { element.lastStatus === "Shipped" ? 
                    <Button variant="contained" color="info" size="small">
                      Create Good Receipt Note
                    </Button> : null }
                  </Box>
                </Stack>
              </Paper>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="simple table"  > 
                  <TableHead>
                    <TableRow >
                        <TableCell width="20px" sx={styleTableRow()}>No</TableCell>
                        <TableCell width="350px" sx={styleTableRow()}>Name</TableCell>
                        <TableCell width="150px" sx={styleTableRow()}>Retail Price</TableCell>
                        <TableCell width="100px" sx={styleTableRow()}>Disc%</TableCell>
                        <TableCell width="250px" sx={styleTableRow()}>Final Price</TableCell>
                        <TableCell width="250px" sx={styleTableRow()}>Ppn</TableCell> 
                        <TableCell width="80px" sx={styleTableRow()}>Qty</TableCell>
                        <TableCell width="180px" sx={styleTableRow()}>Sub Total</TableCell>
                    </TableRow>
                  </TableHead>  
                  <TableBody >
                  { element.items.map((row :any, i : any) => (
                    <TableRow key={i} >
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {i + 1}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        <Box 
                          sx={{ cursor: 'pointer', color: 'blue' }}
                          onClick={() => {
                            history.push({
                              pathname : `/dashboard/products`,
                              search: `?page=detail`, 
                              state : {
                                  product : row,
                                  slug : `${row.slug}`,
                              }
                            })
                          }}
                        >
                          {row.name}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.retail_price.toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {Math.floor(row.discount * 100)}%
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.discount_price.toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {row.tax ? row.tax.title : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {row.quantity}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.sub_total.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <Paper>
                <Stack flexDirection="row" sx={{ p: 2 }}>
                  <Box fontSize="13px">
                    Sub Total + PPN :
                  </Box>
                  <Box>
                    <Box pl={2}><h5>Rp. {sumSubPrice(element).toLocaleString()}</h5></Box>
                  </Box>
                </Stack>
              </Paper>
            </Box>
        </Box>
      )) }
      </Box>
      }
    </>
    )
}

export default TableItemDownPayment