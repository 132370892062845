import { Stack, Box, Button } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../app/store';
import BreadCrumbs from '../../../../../components/BreadCrumbs'
import TableListPackage from '../components/TableListPackage'
import { getNewInvoiceNumber } from '../reducers/invoiceReducers';

function CreateInvoicePage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const store_invoice = useSelector((state : RootState) => state.invoices)

    const [selectedRows, setSelectedRows] = useState([]);

    const getAllItems = (array : any) => {
        let all_items = []
        for(let element of array) {
            for(let ele of element.items) {
                 all_items.push(ele)
            }
        }
        return all_items
    }

    const sumDownPayment = (data : any) => {
        const totalPriceALl = data.reduce((total : any, data : any) => {
            return total + data.down_payment
          }, 0)
          return totalPriceALl;
    }

    const getAllReference = (array : any) => {
        let reference_items = []
        for(let element of array) {
            reference_items.push({
                code_package: element.reference_doc.code_package,
                code_po: element.reference_doc.code_po,
                date_order: element.reference_doc.date_order,
                packageId: element.reference_doc.packageId,
                payment_term: element.reference_doc.payment_term,
                DeliveryNoteId : element._id
            })
        }
        return reference_items
    }

    const onClickProceed = (items : any) => {
        let data_invoice : any = {
            invoice_number : store_invoice.invoice_number,
            items : getAllItems(items),
            reference_document : getAllReference(items),
            buyer : items[0].buyer,
            vendor : items[0].vendor,
            shipping_address : items[0].delivery.shipping_address,
            down_payment: sumDownPayment(items),
            infoPayment : items[0].infoPayment && items[0].lastStatus !== "Paid" ? items[0].infoPayment : [],
            from : 'invoice',
            shipping_costs : items[0].shipping_cost
        }
        
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=summary`, 
            state : {
                data_invoice,
                name : 'create'
            }
        })
    }

    useEffect(() => {
        if(store_invoice.invoice_number !== null) {
            onClickProceed(selectedRows)
        }
        // eslint-disable-next-line
    }, [store_invoice.invoice_number]);
    
    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={true}
                page="Invoice"
                link="/dashboard/finance/invoice"
                current="Create Invoice"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Lists of Package Delivered</h3>
                </Box>
                <Box>
                    <Button 
                        disabled={selectedRows.length === 0 ? true : false}
                        variant="contained" color="primary" size="small"
                        onClick={() => dispatch(getNewInvoiceNumber())}
                    >
                        {store_invoice.loading_invoice_number ? "Loading.." : "Create Invoice"}
                    </Button>
                </Box>
            </Stack>

           <Stack pt={3}>
                <TableListPackage
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
           </Stack>
        </Box>
    )
}

export default CreateInvoicePage
