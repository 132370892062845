import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { updateSettingPlatform, getDataSetting, getTokenByVendor, postTokenByVendor, updateTokenByVendor } from './reducers/settingReducers';

const initialState: any = {
  data: [], 
  loading : true,
  update : false,
  loading_update : false,
  token : [],
  token_loaded : false,
  loading_token : false,
  post_token : false,
  loading_post_token : false,
  update_token : false,
  loading_update_token : false
};

export const getCompanyProfile = createSlice({
  name: 'setting',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder 
      // get setting
      .addCase(getDataSetting.pending, (state) => {
        state.loading = true;
        state.update = false;
      })
      .addCase(getDataSetting.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getDataSetting.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // update setting
      .addCase(updateSettingPlatform.pending, (state) => {
        state.loading_update = true;
      })
      .addCase(updateSettingPlatform.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.update = action.payload.data;
      })
      .addCase(updateSettingPlatform.rejected, (state, action : any) => {
        state.loading_update = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // get token
       .addCase(getTokenByVendor.pending, (state) => {
        state.loading_token = true;
        state.post_token = false;
        state.token_loaded = false
      })
      .addCase(getTokenByVendor.fulfilled, (state, action:any) => {
        state.loading_token = false;
        state.token_loaded = true
        state.token = action.payload.data;
      })
      .addCase(getTokenByVendor.rejected, (state, action : any) => {
        state.loading_token = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // post token
       .addCase(postTokenByVendor.pending, (state) => {
        state.loading_post_token = true;
        state.post_token = false;
      })
      .addCase(postTokenByVendor.fulfilled, (state, action:any) => {
        state.loading_post_token = false;
        state.post_token = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postTokenByVendor.rejected, (state, action : any) => {
        state.loading_post_token = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // update token
      .addCase(updateTokenByVendor.pending, (state) => {
        state.loading_update_token = true;
        state.update_token = false;
      })
      .addCase(updateTokenByVendor.fulfilled, (state, action:any) => {
        state.loading_update_token = false;
        state.update_token = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(updateTokenByVendor.rejected, (state, action : any) => {
        state.loading_update_token = false;
        swal("Error", `${action.payload}`, 'error')
      })

  },
});

export default getCompanyProfile.reducer;
