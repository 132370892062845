import { Paper } from '@mui/material';
import DataTable, { TableProps } from "react-data-table-component";

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
    
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', 
                minWidth:'12px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                minWidth:'12px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', 
                paddingRight: '8px',
                minWidth:'12px'
                
            },
        },
    };
    return (
        <Paper variant="outlined" square>
            <DataTable
                pagination
                dense
                customStyles={customStyles}
                {...props}   
            />
        </Paper>
    );
}

export default DataTableBase;