import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

// 
export const getDataInvoice = createAsyncThunk(
    'invoice/get',
    async (_, { rejectWithValue }) => { 
        try { 
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Vendor?id=${userCredentials.vendor_id}`, {headers})
            
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getInvoicesBySearch = createAsyncThunk(
    'invoice-search/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Vendor`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                    },
                    headers
                })
                
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Vendor`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue
                    },
                    headers
                })
                
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

// 
export const getDataPackageDelivered = createAsyncThunk(
    'package/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/delivery-note/ReadyInvoice?id=${userCredentials.vendor_id}`, {headers})
            
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const postNewInvoice = createAsyncThunk(
    'invoice/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice`, body, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getNewInvoiceNumber = createAsyncThunk(
    'invoice-number/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Code?code=${userCredentials.company_code}`, {headers})
            
            return {data : response.data, message : "success"}
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const postCreateEFaktur = createAsyncThunk(
    'e-faktur/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/invoice/manual/vendor/send`, body, {headers})
            if(response.data.success) {
                let message = response.data.success.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const confirmPayment = createAsyncThunk(
    'confirm-payment/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/confirm`, body)
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const getDataInvoiceByCode = createAsyncThunk(
    'invoice/getbycode',
    async (code:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Vendor`, {
                params : {
                    id : userCredentials.vendor_id,
                    searchTopic: 'invoice_number',
                    searchValue: `${code}`
                },
                headers
            })
            
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getShippingCost = createAsyncThunk(
    'getShipping-cost',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                params : {
                    id : userCredentials.vendor_id,
                    skip : "0",
                    limit : "100000"
                }, 
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);