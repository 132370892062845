import { useState } from 'react';
import { Box, Stack, TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
// import { RootState } from '../../../../app/store';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import moment from 'moment';
import Select from 'react-select'
import { downloadReports } from '../charts/reducers/chartsReducers';


const ChartsDownload = () => {

    const dispatch = useDispatch()

    // const state_reports = useSelector((state : RootState) => state.chart_report)

    // console.log(state_reports, 'state reportss')
    const optionSearch = [
        { label : "Today", value : "today" },
        { label : "This Month", value : "this_month" },
        { label : "Last Month", value : "last_month" },
        { label : "Custom", value : "custom" }
    ]

    const [selectedSearch, setSelectedSearch] = useState<any>([ ]);
    
    const [dataSearch, setDataSearch] = useState({ 
        rangeFrom: "",
        rangeTo: "",
    });

    const handleChangeSearch = (value: any) : void => {
        setSelectedSearch(value)
    }

    const onClickSearch = () => {
        if(selectedSearch.value === "this_month") {
            const range = {
                from : moment().startOf('month').format('YYYY-MM-DD'),
                to : moment().endOf('month').format('YYYY-MM-DD')
            }
            dispatch(downloadReports(range))
        } else if (selectedSearch.value === "last_month") {
            const range = {
                from : moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                to : moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            }
            dispatch(downloadReports(range))
        } else if (selectedSearch.value === "custom") {
            const range = {
                from : dataSearch.rangeFrom,
                to : dataSearch.rangeTo
            }
            dispatch(downloadReports(range))
        }  else {
            const range = {
                from : moment().format('YYYY-MM-DD'),
                to : moment().format('YYYY-MM-DD')
            }
            dispatch(downloadReports(range))
        }
    }



    return (
        <Box sx={{pt:2, pl:3, pr:3, mb: 5}}>
            <BreadCrumbs
                isPage={false}
                current="Report Download"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Report Download</h3>
                </Box>
            </Stack>

            <Stack flexDirection="row">
                <Box mt={1} sx={{ minWidth: 250 }}>
                    <Select
                        placeholder="Download Filter"
                        value={selectedSearch}
                        options={optionSearch}
                        onChange={handleChangeSearch}
                        id={`select-style-searchby`}
                    />
                </Box>
                { selectedSearch.length !== 0 && selectedSearch.value === 'custom' ? 
                <>
                <Box ml={2} mt={1}>
                    <TextField 
                        type="date"
                        size="small"
                        onChange={(e) => setDataSearch({...dataSearch, rangeFrom: e.target.value})}
                        value={dataSearch.rangeFrom}
                        sx={{ backgroundColor: '#fff' }}
                    />
                </Box>  
                <Box pl={1} pt={2} pr={1}>to</Box>
                <Box mt={1}>
                    <TextField 
                        type="date"
                        size="small"
                        variant="outlined"
                        value={dataSearch.rangeTo}
                        onChange={(e) => setDataSearch({...dataSearch, rangeTo: e.target.value})}
                        sx={{ backgroundColor: '#fff' }}
                    />
                </Box>
                
                <Box mt={1} ml={2} sx={{ minWidth: 250 }}>
                    <Button 
                        color="primary" variant="contained"
                        disabled={dataSearch.rangeFrom !== "" && dataSearch.rangeTo !== ""  ? false : true}
                        onClick={onClickSearch}
                    >Download Excel</Button>
                </Box>
                </> : 
                <Box mt={1} ml={2} sx={{ minWidth: 250 }}>
                    <Button 
                        color="primary" variant="contained"
                        disabled={selectedSearch.length === 0  ? true : false}
                        onClick={onClickSearch}
                    >Download Excel</Button>
                </Box>
                 }
            </Stack>  
        </Box>
    )
}

export default ChartsDownload
