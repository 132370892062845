import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CreateInvoicePage from './create/CreateInvoicePage';
import InvoiceSummary from './create/InvoiceSummary';
import InvoicesPage from './InvoicesPage';


function MainInvoicePage() {
    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')
 
    const switchPage = () => {
        if (active_page === 'create') {
            return <CreateInvoicePage/>
        } else if (active_page === 'summary') {
            return <InvoiceSummary />
        } else {
            return <InvoicesPage/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainInvoicePage
