import { useEffect, useState } from "react";
import { Card, Box, Stack, Button, TextField, CircularProgress, Checkbox } from "@mui/material";
import Select from 'react-select'
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import  s3Client  from "../configS3"
import swal from "sweetalert";
import EmptyData from "../../../../components/EmptyData";
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import moment from "moment";


const optionSearch = [
    { label : "Latest", value : "Latest" },
    { label : "Oldest", value : "Oldest" },
    { label : "Custom", value : "Custom" }
]


const digitalOceanSpaces =  `${process.env.REACT_APP_S3_API_URL}/`

const UploadedImage = ({uploadImage, setIsDelete, setIsFilter, isFilter, isDelete, loadingData } : any) =>{
    const [filterData, setFilterData] = useState<any>([])
    const [loaded, setLoaded] = useState<boolean>(false);

    const [selectedSearch, setSelectedSearch] = useState<any>([]);

    let arrDeletedImages:any[] = []

    function removeItem(index:number,file:any) {
        let findTheIndex:number
        let theData:string = digitalOceanSpaces+file.Key
        findTheIndex = arrDeletedImages.findIndex((element:any) => element === theData)
        arrDeletedImages = arrDeletedImages.filter((item:any,i:number) => i !== findTheIndex);
        let newArrDeletedImages:any[] = []
        for (let index=0; index<arrDeletedImages.length; index++) {           
            newArrDeletedImages.push([arrDeletedImages[index]])           
        }
        return newArrDeletedImages
    }


    const handleFilterDate = (event: any) => {
        setSelectedSearch(event)
        if(event.value === "Latest") {
            let latestDate : any = uploadImage[0].sort((am:any,bm:any) => bm.LastModified.getTime() - am.LastModified.getTime())
            return setFilterData(latestDate)
        } else if(event.value === "Oldest") {
            let oldestDate = uploadImage[0].sort((am:any,bm:any) => am.LastModified.getTime() - bm.LastModified.getTime())
            return setFilterData(oldestDate)
        } 
    };

    const handleFilterCustom = (value : any) => {
        let filter = uploadImage[0].filter((e:any) => moment(e.LastModified).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'))
        setIsFilter(true)
        setFilterData(filter)
    }
    const clickCopyUrl = (file:any, i:number, event:any) =>{
        let arrImages:any[] = []
        if(!event.target.checked) {
            arrImages = removeItem(i,file)
        } else {
            arrDeletedImages = [...arrDeletedImages, digitalOceanSpaces+file.Key]
            const filteredImages = arrDeletedImages.filter((e:any) => e.length !== 0 || undefined)
            for (let index=0; index<filteredImages.length; index++) {           
                arrImages.push([filteredImages[index]])           
            }
        }
        copy2DToClipboard(arrImages);
    }
    
    const copy2DToClipboard = (array:any) =>{        
        let csv = '', row, cell;
        for (row = 0; row < array.length; row++) {
        for (cell = 0; cell < array[row].length; cell++) {
            csv += (array[row][cell]+'').replace(/[\n\t]+/g, ' ');
            if (cell+1 < array[row].length) csv += '\t';
        }
        if (row+1 < array.length) csv += '\n';
        }
        copyTextToClipboard(csv);
    }
    
    const copyTextToClipboard = (text:any) =>{
        navigator.clipboard.writeText(text)
    }

    const clickDeleteImage = async(file:any, i:number) =>{
        try {
            const imageName = file.Key
            const trimImageName = imageName.trim()
            const bucketParams = { 
                Bucket: "mpindo",
                Key: trimImageName 
            }
            await s3Client.send(new DeleteObjectCommand(bucketParams));
            swal("Deleted","Success Delete Image", "success")
            setIsDelete((prev : number) => prev + 1)
            setIsFilter(false)
        } catch (error) {
            swal("Error","Error Delete Image", "error")
        }
    }
    
    useEffect(()=>{
        if(!isFilter) {
            if(uploadImage[0] === undefined) {
                setFilterData([])
                setLoaded(true)
            } else {
                setFilterData(uploadImage[0])
                setLoaded(true)
            }
        }
        // eslint-disable-next-line
    },[uploadImage, isDelete, isFilter])

    return(
        <Stack sx={{mt:2}}>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box py={1} fontWeight="500" fontSize={23}>Image Gallery</Box>
                <Stack flexDirection="row">
                    <Box mr={1} sx={{ minWidth: 150 }}>
                        <Select
                            isDisabled={ filterData.length > 1 ? false : true}
                            placeholder="Sort By"
                            value={selectedSearch}
                            options={optionSearch}
                            onChange={handleFilterDate}
                            id={`select-style-parent-module-1`}
                        />
                    </Box>
                    { selectedSearch.length !== 0 && selectedSearch.value === "Custom" && 
                    <Stack flexDirection="row">
                        <Box mr={1}>
                            <TextField 
                                type="date"
                                size="small"
                                sx={{
                                    backgroundColor: "#fff",
                                }}
                                onChange={(e) => handleFilterCustom(e.target.value)}
                            />
                        </Box>
                        <Button 
                            variant="contained" 
                            size="small"
                            color="error"
                            onClick={() => {
                                setIsFilter(!isFilter)
                                setSelectedSearch([])
                            }}
                        >Reset Sort</Button>
                    </Stack> }
                </Stack>
            </Stack>

            <Stack 
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: 3
                }}
            >
                { loadingData ? 
                <Stack flexDirection="column" alignItems="center" sx={{my:5}}>
                    <Box>
                        <CircularProgress color="primary" size={30} /> 
                    </Box>
                    <Box fontWeight="500"> Getting your images..</Box>
                </Stack>
                :
                <>
                { loaded && 
                <Stack>
                    { filterData.length === 0 ? 
                    <Box my={3} fontWeight="500">
                        <EmptyData message={`Data images is not found. `}/> 
                    </Box>
                    : 
                    <Box>
                    <Box 
                        sx={{ 
                            display : 'grid', 
                            gridTemplateColumns : 'repeat(auto-fill  , minmax(110px, 1fr))',
                            alignItems : 'start',
                            padding : '20px',
                            paddingTop : '10px',
                            height : '100%'
                        }}
                    >
                    { filterData?.map((file:any,i:number) =>
                        <Box className="boxDisplayFilterData" key={i}>
                            <Card 
                                sx={{ 
                                    border : '0.5px solid gray', 
                                    width : '100px',
                                    height : '100px', 
                                    boxShadow: '0px 0px 2px 0px gray',
                                    marginTop : '15px',
                                    padding : '3px'
                                }}
                                className="containerDiv"
                            >
                                <img
                                    className="imageMediaCenter"
                                    style={{ width:'100%', height:'100%', objectFit: 'contain', display: 'block'}}
                                    src={`${digitalOceanSpaces}${file.Key}`}
                                    alt={file.Key}
                                />
                                <Box className="overlayediaCenters">
                                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Checkbox
                                                onClick={(event) => clickCopyUrl(file,i,event)}
                                                style={{
                                                    position : 'absolute', 
                                                    top:'0',
                                                    padding : 0
                                                }}
                                            />
                                        </Box>
                                        <Box 
                                            onClick={() => clickDeleteImage(file,i)}
                                        >
                                            <DisabledByDefaultTwoToneIcon 
                                                sx={{
                                                    position : 'absolute', 
                                                    bottom:'0',
                                                    right: 0,
                                                    color:'red',
                                                    fontSize: 20,
                                                    margin : 0.4,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                                
                            </Card>
                        </Box>
                    )}
                    </Box>
                    </Box>
                    }
                </Stack>
                }
                </>
                }
            </Stack>
        </Stack>
    )
}

export default UploadedImage