import { Paper, Box, Skeleton } from '@mui/material'
import React from 'react'
import { useMediaQuery } from '@mui/material'

const CardStatistic : React.FC<any> = ({
    label, value, currency, background, status, loading, color, src
}) => {
    const mobileDisplay = useMediaQuery('(min-width:480px)')
    return (
        <div>
            <Paper elevation={1} sx={{ backgroundColor: background ? background : '#fff', borderRadius: 4 }}>
                { status ?
                <Box pl={2} pr={2} className="card-statistic-status" sx={{ color: color }}>
                    <div>
                        { mobileDisplay?
                        <span>{label}</span>
                        : <h4>{label}</h4>}
                    </div>
                    { loading ?
                    <Skeleton style={mobileDisplay? undefined : { padding:'5px'}} height={ 40 } width={30} /> :
                    <div style={{ padding:'5px'}}>
                        { mobileDisplay? 
                        <h1>{value}</h1>
                        : <span>{value}</span>}
                    </div> }
                </Box>
                :
                <Box p={mobileDisplay? 3 : 2} className="card-statistic">
                    <div>
                        <h4>{label}</h4>
                        { loading ?
                            <Skeleton height={40} width={180} />
                        :
                        <div>
                        {!currency ? 
                            <span>{value}</span> :
                            <span>Rp. {parseInt(value).toLocaleString()}</span>
                        }
                        </div> }
                    </div>
                    <div>
                        <img src={src} alt="icon" style={ mobileDisplay? undefined : { width:'40px'}} />
                    </div>
                </Box>
                }
            </Paper>
        </div>
    )
}

export default CardStatistic
