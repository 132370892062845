import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getDataEFaktur } from './reducers/eFakturReducers';

const initialState: any = {
  data: [], 
  loading : true,
};

export const getEFakturSlice = createSlice({
  name: 'e-faktur',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder 
      // get invoices
      .addCase(getDataEFaktur.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataEFaktur.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getDataEFaktur.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })      
  },
});

export default getEFakturSlice.reducer;
