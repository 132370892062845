import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import swal from 'sweetalert';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

// get status orders
export const getPurchaseOrdersStatus = createAsyncThunk(
    'purchase-orders-status/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/status/${userCredentials.vendor_id}`, {
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);

// get all data orders for searching dynamic 
export const getPurchaseOrdersData = createAsyncThunk(
    'purchase-orders/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                params : {
                    id : userCredentials.vendor_id,
                    skip : "0",
                    limit : "100000"
                }, 
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);

// get data by search 
export const getPurchaseOrdersBySearch = createAsyncThunk(
    'purchase-orders-search/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

// get data by load more
export const getPurchaseOrdersBySearchLoadMore = createAsyncThunk(
    'purchase-orders-search-loadmore/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

// get all data ordersss
export const getDataPurchaseOrders = createAsyncThunk(
    'purchase-orders-data/get',
    async (search : any, { rejectWithValue }) => {
        try {        
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                }) 
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

// get data down payment by seacth and load more
export const getDataPurchaseOrdersDP = createAsyncThunk(
    'purchase-orders-data-dp/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/Orders`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/Orders`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

// get all data down payment 
export const getPurchaseOrdersWaitingDP = createAsyncThunk(
    'purchase-orders/get-dp',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/Orders`, {
                params : {
                    id : userCredentials.vendor_id,
                    skip : "0",
                    limit : "1000"
                },
                headers
            }) 
            if(response.data.errors === null) {
                return {data : response.data.data.data, count_dp : response.data.data.count, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getPurchaseOrdersWaitingDPLoadMore = createAsyncThunk(
    'purchase-orders-dp-loadmore/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/Orders`, {
                    params : {
                        id : userCredentials.vendor_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/fulfillment/Orders`, {
                    params : {
                        id : userCredentials.vendor_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);
 

export const postPickPurchaseOrders = createAsyncThunk(
    'purchase-orders/pick',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/fulfillment/Pick`, value, {headers})            
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postAddShippingCost = createAsyncThunk(
    'purchase-orders/shipping-cost',
    async (value : any, { rejectWithValue }) => { 
        try {              
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/fulfillment/Pick`, value, {headers})            
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                swal('Error',response.data, 'error')
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            swal('Error',err, 'error')
            return rejectWithValue(err)
        }
    }
);

export const postPackPurchaseOrders = createAsyncThunk(
    'purchase-orders/pack',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/fulfillment/Pack`, value, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postDeliveryNotePurchaseOrders = createAsyncThunk(
    'purchase-orders/delivery-note',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/delivery-note`, value, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postConfirmationDP = createAsyncThunk(
    'purchase-orders/confirm-dp',
    async (value : any, { rejectWithValue }) => { 
        try {
            const body = {
                "id": value.id,
                "nominal": value.nominal,
                "name": userCredentials.fullname
            }
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/fulfillment/Approval`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getDataPackageDeliveredSearch = createAsyncThunk(
    'package-delivery/get',
    async (search:any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/delivery-note/ReadyInvoice`, {
                params : {
                    id : userCredentials.vendor_id,
                    searchTopic: search.searchTopic,
                    searchValue: search.searchValue
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const getNewInvoiceNumberOder = createAsyncThunk(
    'invoice-number-order/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Code?code=${userCredentials.company_code}`, {headers})
            return {data : response.data, message : "success"}
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);