import React, { useState } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Chip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableItem from './TableItem';
import moment from 'moment'
import { useDispatch } from 'react-redux';
// import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { approveDeliveryNote, rejectDeliveryNote } from '../reducers/deliveryNoteReducers';

const CardOrder : React.FC<any> = ({ data, refetch, setRefetch }) => {
    const dispatch = useDispatch()

    // const store_deliverynote = useSelector((state : RootState) => state.delivery_note)

    // console.log(store_deliverynote, 'store_deliverynote')

    // const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChangeAccordion =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    function sumTotalPriceQty (data : any) {
        return data.reduce((total : any, data : any) => {
          return total + data.sub_total
        }, 0)
    }

    function sumTotalPriceReceived (data : any) {
        return data.reduce((total : any, data : any) => {
          return total + data.sub_total_received
        }, 0)
    }

    function checkStatusItem(params:any) {
        let check = false
        for(let ele of params) {
            if(ele.quantity === ele.received) {
                check = false
            } else {
                check = true
            }
        }
        return check
    }

    const onClickApprove = (val : any) => {
        Swal.fire({
            title: 'Confirmation!',
            text: "Please make sure you already check item received is correct?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approve!',
            cancelButtonText: 'Check Again',
            showLoaderOnConfirm: true,
            preConfirm: () => {
               return dispatch(approveDeliveryNote(val._id))
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Approved!',
                    'GRN Success Approved.',
                    'success'
                )
                setRefetch(!refetch)
            }
        })
    }

    const onClickReject = (val : any) => {
        Swal.fire({
            title: 'Confirmation!',
            text: "Are you sure want to reject this GRN? This document will be back send to Buyer for revision.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Reject!',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            preConfirm: () => {
               return dispatch(rejectDeliveryNote(val._id))
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Rejected!',
                    'GRN Success Rejected.',
                    'success'
                )
                setRefetch(!refetch)
            }
        })
    }

    return (
    <Stack>
        { data.length === 0 ? "Data not found" :
            <Box>
                { data.map((val : any, i : any) => (
                    <Box mb={4} mt={4} key={i} >
                        <Paper elevation={3} sx={{ backgroundColor: '#fff'}}>
                            <Paper sx={{ backgroundColor: '#fff'}}>
                                <Grid container spacing={2} justifyContent="space-between">
                                    <Grid item>
                                        <Box fontWeight="bold" pl={2} fontSize={14}> GRN Code : {val.received.code}</Box>
                                        <Box fontWeight="normal" pl={2} fontSize={13}> Date : {moment(val.reference_doc.date_order).format('ll')} - Base on Order ID : {val.reference_doc.code_po}</Box>
                                    </Grid>
                                    <Grid item>
                                        { val.lastStatus === "Good Receive Note Submit" ?
                                        <Stack flexDirection="row">
                                            <Box pr={2}>
                                                <Button 
                                                    variant="contained" color="primary" size="small"
                                                    onClick={() => onClickApprove(val)}
                                                >
                                                    Approve
                                                </Button>
                                            </Box>
                                            <Box pr={2}>
                                                <Button 
                                                    variant="contained" color="error" size="small"
                                                    onClick={() => onClickReject(val)}
                                                >
                                                    Reject
                                                </Button>
                                            </Box>
                                        </Stack>
                                        : val.lastStatus === "Ready to Invoice" ?
                                        <Stack pr={2}>
                                            <Chip label="Approved" color="info" />
                                        </Stack> 
                                         : val.lastStatus === "Invoicing" ?
                                         <Stack pr={2}>
                                            <Chip label="Invoice Created" color="success" />
                                        </Stack> 
                                         :
                                        <Stack pr={2}>
                                            <Chip label="Waiting Buyer Revision" color="warning" />
                                        </Stack>
                                        }
                                    </Grid> 
                                </Grid>
                            
                            </Paper>
                            <Box p={2}>
                                <Grid container spacing={3} >
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                            <Box pr={1} fontSize={13}>{val.buyer.name} </Box>
                                            <Button size="small" color="primary" variant="outlined">
                                                Message
                                            </Button>
                                        </Box> 
                                        <Box pt={1} fontWeight="normal" fontSize={14}>
                                            <Box style={{width: '80%'}} fontSize={12}>{val.buyer.phone}</Box>
                                            <Box style={{width: '80%'}}>{val.buyer.address}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Box fontWeight="bold" pt={1} fontSize={13}> 
                                            Total Price + PPN : Rp. {(sumTotalPriceQty(val.items)).toLocaleString()}
                                        </Box>
                                        { checkStatusItem(val.items) ? 
                                        <Box fontWeight="bold" pt={1} fontSize={13} color="red"> 
                                            Total Price (GRN) + PPN : Rp. {(sumTotalPriceReceived(val.items)).toLocaleString()}
                                        </Box> : null }
                                    </Grid>
                                </Grid>
                                
                            </Box>

                            <Box pt={2} >
                                <Paper elevation={3} sx={{ backgroundColor: '#fff'}}>
                                    <Accordion sx={{ backgroundColor: '#fff'}}  expanded={expanded === `${i}`} onChange={handleChangeAccordion(`${i}`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id={`panel1a-header-${val._id}`}
                                        >
                                            <Box fontWeight="bold" fontSize={14} >
                                                Detail All Item ({val.items?.length})
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box p={1}>
                                                <TableItem 
                                                    data={val.items} 
                                                    dataOrders={val}
                                                    status="hello"
                                                /> 
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Paper>
                            </Box>

                        </Paper>
                    </Box>
                ))}
            </Box>
        }
    </Stack>
    )
}

export default CardOrder
