import { 
    Box,
    Stack,
    Skeleton,
} from '@mui/material';
import { useState } from 'react';
import swal from 'sweetalert';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { v4 as uuid } from 'uuid';


const Input = styled('input')({
  display: 'none',
});

const UploadImage : React.FC<any> = ({
    urlImage,
    setUrlImage,
    errorImage
}) => {

    const [loading, setLoading] = useState(false);

    const onChangeValueFile = (e : any) => {
        if(e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
            setLoading(true)
            const random = uuid();
            setTimeout(() => {
                var S3 = require("aws-sdk/clients/s3");
                const s3bucket = new S3({
                    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
                });
            
                if (e.target.files && e.target.files[0]) {
                const blob = e.target.files[0]
                const file_name = blob.name.replace(/\s/g, "")
                const params = { Body: blob, 
                                Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                                Key: 'vendor/' + random + file_name
                                // Key: 'vendor/' + file_name
                                };
                s3bucket.putObject(params)
                .on('build', (request : any) => {
                    request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                    request.httpRequest.headers['Content-Length'] = blob.size;
                    request.httpRequest.headers['Content-Type'] = blob.type;
                    request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                    request.httpRequest.headers['x-amz-acl'] = 'public-read';
                })
                .send((err : any, data : any) => {
                    if (err){  
                        swal(`${err}`, `${err.stack}`, 'error')
                        setLoading(false)
                    } 
                    else {      
                        const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${random}${file_name}`
                        setUrlImage(imageUrl)
                        setLoading(false)
                    }}
                )} 
            }, 500);
        } else {
            setLoading(false)
            swal("Error", "File type must be JPG, JPEG or PNG", "error")
        }
    }

  return (
    <Stack flexDirection="row" alignItems="bottom">
        <Box>
            { loading ?
            <Skeleton 
                variant="rectangular" 
                width={50} height={50} 
                sx={{ borderRadius: '4px' }}
            /> :
            <Box>
                { urlImage === "" || urlImage === undefined ?
                <Box>
                    
                    <label htmlFor="icon-button-files" style={{ border: '1px solid #ccc', padding: '10px', }}>
                        <Input accept="image/*" id="icon-button-files" type="file"  onChange={(e) => onChangeValueFile(e)} />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                        </IconButton>
                    </label>
                    { errorImage ?
                    <Box pt={1} sx={{color:'red'}}><p>Image is required!</p></Box> : null }
                </Box>
                :
                <Stack flexDirection="column"  >
                    <Box 
                        component="img"
                        src={urlImage}
                        sx={{
                            width: '100px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            padding: '5px'
                        }}
                    />
                    <Box
                        sx={{
                            color: 'blue',
                            cursor: 'pointer',
                            fontSize: 12,
                            pt: 1
                        }}
                        onClick={() => setUrlImage("")}
                    >Change</Box>
                </Stack> }
            </Box> 
            }
        </Box>
    </Stack>
  );
}

export default UploadImage