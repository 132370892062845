import { Button, Stack, TextField, Box, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getBuyerListsData, postPreferredBuyerList, removeBuyerLists, updatePreferredBuyerList } from '../reducers/buyerListsReducer';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../../utilities/config';
import ProductsField from './ProductsField';
import { getAllProduct } from '../../products/reducers/productsReducers';
import swal from 'sweetalert';
import { getDataSetting } from '../../setting/reducers/settingReducers';

const default_item = [{
    product_id : "",
    product_name : "",
    discount : ""
}]

const DialogField : React.FC<any> = ({
    open,
    setOpen,
    dataBuyer,
    name,
    valueUpdateBuyer
}) => {

    const dispatch = useDispatch()

    const state_products = useSelector((state : RootState) => state.products)
    const store_buyerlists = useSelector((state : RootState) => state.buyer_lists)
    const store_setting = useSelector((state : RootState) => state.setting)

     

    const [productItems, setProductItems] = useState<any>([]);
    const [dataProducts, setDataProducts] = useState<any>([]);
    const [addProduct, setAddProduct] = useState<any>(false);
    const [hideHelper, setHideHelper] = useState({
        name : "",
        hide : false
    });


    const [valueBuyer, setvalueBuyer] = useState({
        payment_term : "",
        initial_discount : ""
    }); 

    const onChangeValue = (e : any) => {
        const { name, value } = e.target
        let value_number = value === "" ? 0 : parseInt(value)
        setHideHelper({...hideHelper, name : name, hide : true })
        if(name === "initial_discount" && value_number < 101) {
            setvalueBuyer({...valueBuyer, [name] : value_number})
        } else if(name === "payment_term") {
            setvalueBuyer({...valueBuyer, [name] : value_number})
        }
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        let body_send = {
            "vendor_id": userCredentials.vendor_id,
            "buyer_id": dataBuyer._id,
            "payment_term": {
                "name" : `${valueBuyer.payment_term} Days`,
                "value" : parseInt(valueBuyer.payment_term)
            },
            "initial_discount": parseInt(valueBuyer.initial_discount),
            "product" : addProduct ? productItems : [],
            "status" : "Active"
        }
        if(name === "Create New Preferred Buyer") {
            dispatch(postPreferredBuyerList(body_send))
        } else {
            dispatch(updatePreferredBuyerList(body_send))
        }
    }

    const onAddProduct = () => {
        dispatch(getAllProduct())
    }

    const onClickRemove = () => {
        let remove_buyer = {
            buyer_id : dataBuyer._id,
            vendor_id: userCredentials.vendor_id,
        }
        dispatch(removeBuyerLists(remove_buyer))
    }

    useEffect(() => {
        dispatch(getDataSetting())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!store_setting.loading) {
            if(store_setting.data !== null) {
                setvalueBuyer({...valueBuyer, 
                    payment_term : store_setting.data.setting[0].payment_term.value,
                    initial_discount : store_setting.data.setting[0].discount,
                })
            }
        }
        // eslint-disable-next-line
    }, [store_setting.loading]);

    useEffect(() => {
        if(store_buyerlists.post_buyer) {
            dispatch(getBuyerListsData())
            swal('Success', 'Success Add Preferred Buyer', 'success')
            handleClose()
        }
        // eslint-disable-next-line
    }, [store_buyerlists.post_buyer]);

    useEffect(() => {
        if(store_buyerlists.update_buyer) {
            dispatch(getBuyerListsData())
            swal('Success', 'Success Update Preferred Buyer', 'success')
            handleClose()
        }
        // eslint-disable-next-line
    }, [store_buyerlists.update_buyer]);

    useEffect(() => {
        if(store_buyerlists.remove_buyer) {
            dispatch(getBuyerListsData())
            swal('Success', 'Success remove Preferred Buyer', 'success')
            handleClose()
        }
        // eslint-disable-next-line
    }, [store_buyerlists.remove_buyer]);

    useEffect(() => {
        if(state_products.loaded) {
            setDataProducts(state_products.data)
            if(valueUpdateBuyer !== undefined) {
                setAddProduct(true)
                setProductItems(valueUpdateBuyer.products)
            } else {
                setProductItems(default_item)
            }
        }
        // eslint-disable-next-line
    }, [state_products.loaded]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(valueUpdateBuyer !== undefined) {
            setvalueBuyer({...valueBuyer, 
                payment_term : valueUpdateBuyer.payment_term,
                initial_discount : valueUpdateBuyer.initial_discount,
            })
            setProductItems(valueUpdateBuyer.products)
            onAddProduct() 
        }
        // eslint-disable-next-line
    }, [valueUpdateBuyer]);

    return (
        <div>
            <Dialog open={open} maxWidth="lg" fullWidth={true}  >
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>{name}</DialogTitle>
                    <DialogContent>
                            <Stack>
                                { dataBuyer.length !== 0 ? 
                                    <Stack>
                                        <Box>Company Name : {dataBuyer.legal_name}</Box>
                                        <Box>Company Code : {dataBuyer.company_code}</Box>
                                        <Box>Address : {dataBuyer.address}</Box>
                                    </Stack>
                                    : null
                                }
                            </Stack>
                            <Stack mt={2}>
                                <TextField
                                    margin="dense"
                                    onChange={onChangeValue}
                                    value={valueBuyer.initial_discount}
                                    name="initial_discount"
                                    helperText="By Default discount value will be setup as Standart Company Parameter"
                                    label="Discount in %"
                                    type="text"
                                    fullWidth
                                    size='small'
                                    required
                                />
                                <TextField
                                    margin="dense"
                                    onChange={onChangeValue}
                                    value={valueBuyer.payment_term}
                                    name="payment_term"
                                    helperText="By Default Payment Term value will be setup as Standart Company Parameter"
                                    label="Payment Terms in Days"
                                    type="text"
                                    fullWidth
                                    size='small'
                                    required
                                />
                            </Stack>
                            <Stack mt={3}>
                                <Paper>
                                    <Stack p={3}>
                                        <Stack flexDirection="row" justifyContent="space-between" alignContent="center">
                                            <Box>Product Discount for this Buyer</Box>
                                            <Box>
                                                { !addProduct ? 
                                                <Button 
                                                    variant='outlined'
                                                    color='primary'
                                                    onClick={() => {
                                                        setAddProduct(true)
                                                        onAddProduct()
                                                    }}
                                                    size="small"
                                                >
                                                    { state_products.loading ? "Loading.." : 'Add Product' }
                                                </Button>
                                                :
                                                <>
                                                { name === "Create New Preferred Buyer" ? 
                                                <Button 
                                                    variant='outlined'
                                                    color='error'
                                                    onClick={() => {
                                                        setAddProduct(false)
                                                    }}
                                                    size="small"
                                                >Cancel</Button>
                                                : null }
                                                </>
                                                }
                                            </Box>
                                            
                                        </Stack>

                                        { addProduct ? 
                                        <Stack pt={2}>
                                            <ProductsField 
                                                productItems={productItems}
                                                setProductItems={setProductItems}
                                                dataProducts={dataProducts}
                                            />
                                        </Stack> 
                                        : null }
                                    </Stack> 
                                </Paper>
                            </Stack>
                    </DialogContent>
                    <Stack>
                        <Stack p={3} flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Button onClick={onClickRemove} color="error" variant='outlined'>
                                    { store_buyerlists.loading_update_buyer ? "Loading.." : " Delete This Buyer" } 
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={handleClose} color="error">Cancel</Button>
                                { name === "Create New Preferred Buyer" ?
                                <Button variant="contained" type="submit">
                                    { store_buyerlists.loading_post_buyer ? "Loading.." : "Submit" } 
                                </Button>
                                :
                                <Button variant="contained" type="submit">
                                    { store_buyerlists.loading_update_buyer ? "Loading.." : "Save" } 
                                </Button>
                                }
                            </Box>
                        </Stack>
                    </Stack>
                </form>
            </Dialog>
        </div>
    )
}

export default DialogField
