import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system'
import moment from 'moment';
import { useState, useEffect } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import DataTableBase from '../../../../components/TableData'
import { userCredentials } from '../../../../utilities/config';
import { createNewChat, getUsersForChat } from '../../chat/reducers/chatReducers';
import { getAllUsersTeams } from '../../teams/reducers/teamsReducers';
import {  Skeleton } from '@mui/material'

const NewestOrders : React.FC<any> = ({ data, loading }) => {
    const dispatch = useDispatch()
    const state_teams = useSelector((state : RootState) => state.users_team)
    const store_chat = useSelector((state : RootState) => state.chat)

    const [idxItem, setIdxItem] = useState();
    const [loadingMessage, setLoadingMessage] = useState(false);

    const onClickCreateChat = async (row : any, i : any) => {
        setLoadingMessage(true)
        setIdxItem(i)

        const user_vendor : any = await dispatch(getUsersForChat(row.buyer._id))

        if(user_vendor.type === "chat-user/get/fulfilled") {
            let data_response = user_vendor.payload.data.data
            let user_names = []
            for(let k of data_response) {
                user_names.push(`${k.fullname} - ${k.company_code}`)
            }
            for(let l of state_teams.data) {
                user_names.push(`${l.fullname} - ${l.company_code}`)
            }
            let body = {
                username: `${userCredentials.fullname} - ${userCredentials.company_code}`,
                secret : userCredentials.vendor_id,
                title : `${row.buyer.name} & ${userCredentials.vendor_name}`,
                usernames : user_names
            }
            dispatch(createNewChat(body))
        } 
    }

    useEffect(() => {
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_chat.chat) {
            setLoadingMessage(false)
            window.location.href = '/dashboard/message'
        }
    }, [store_chat.chat]);


    const columns: TableColumn<any>[] = [
        {
            name: 'No',
            width: '50px', 
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'Date',
            width: '95px',
            cell: (row) => (
                <p>{moment(row.date).format('l')}</p>
            )
        },
        {
            name: 'Buyer',
            selector: row => row.buyer.name,
        },
        {
            name: 'Payment Term',
            cell: (row) => (
                <p>{row.payment_terms ? row.payment_terms : "-"}</p>
            )
        },
        {
            name: 'Item',
            width: '70px',
            selector: row => row.items.length,
        },
        {
            name: 'Total Price',
            width: '150px',
            cell: (row) => (
                <div>Rp. {row.total === undefined ? 0  : row.total.toLocaleString()}</div>
            ),
        },
        {
            name: 'Message',
            width: '150px',
            cell: (row, i) => (
                <Stack direction="row" spacing={2}>
                     { state_teams.loading ? "Loading" : 
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => onClickCreateChat(row, i)}
                        disabled={loadingMessage ? true : false}
                    >
                        { loadingMessage && idxItem === i ? "Loading" : "Message" }
                    </Button> }
                </Stack>
            ),
        },
    ];
    
    return (
        <Box>
            <Stack sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Box fontWeight="500" fontSize={20}>
                    Latest Orders
                </Box>
                <Box pt={1}>
                    <Button href="/dashboard/orders" variant="outlined" size="small">View All</Button>
                </Box>
            </Stack>
            <Box pt={2}>
                { loading?
                <Skeleton width={'100%'} height={370}/>
                :
                <>
                <DataTableBase 
                    columns={columns}
                    data={data}
                    progressPending={loading}
                />
                </>
                }
            </Box>
        </Box>
    )
}

export default NewestOrders
