import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getSearchBuyer = createAsyncThunk(
    'search-buyer/get',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/buyer/detail`, {
                params : {
                    company_code : value
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getBuyerListsData = createAsyncThunk(
    'buyer-lists/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/mybuyer`, {
                params : {
                    vendor_id : userCredentials.vendor_id
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const postPreferredBuyerList = createAsyncThunk(
    'buyer-lists/post',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/mybuyer`, value, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const updatePreferredBuyerList = createAsyncThunk(
    'buyer-lists/update',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/mybuyer`, value, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeBuyerLists = createAsyncThunk(
    'buyer-lists/remove',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/mybuyer`, {
                params : {
                    vendor_id : value.vendor_id,
                    buyer_id : value.buyer_id,
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const uploadBulkPreferedList= createAsyncThunk(
    'product-bulk/post',
    async (value : any, { rejectWithValue }) => { 
        let bodyFormData = new FormData();
            bodyFormData.append('file', value.file[0]);
            bodyFormData.append('initial_discount', value.initial_discount);
            bodyFormData.append('payment_term_value', value.payment_term_value);
            bodyFormData.append('vendor_id', userCredentials.vendor_id);
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/mybuyer/uploadExcel`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization' : `Bearer ${userCredentials.access_token}`
                }
            }) 
            if(response.data) {
                return {data : true, message : "Bulk upload is running in the background, please check later while we are processing the upload."}
            } else {
                return rejectWithValue(response.data.message)
            }
            } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);