import { useEffect, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Badge, Popover, Stack, Divider, Chip } from '@mui/material';
import { userCredentials } from '../utilities/config';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getDataInvoiceByCode } from '../features_app/dashboard/finance/invoice/reducers/invoiceReducers';
import swal from 'sweetalert';
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_API_SOCKET_API}`); 

function NotificationBell() {
  
    const history = useHistory()
    const dispatch = useDispatch()

    const [message, setMessage] = useState<any>([]);
    const [historyNotif, setHistoryNotf] = useState<any>([]);

    const [totalNewMessage, setTotalNewMessage] = useState<any>([]);

    const [openBackdrop, setOpenBackdrop] = useState(false);

    // console.log(historyNotif)
    
    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    };

    const chatUnRead = (row:any) => {
      return row.filter((data:any) => !data.read_by || !data.read_by[userCredentials.auth_id])
    }

    useEffect(() => {
      socket.on('connect', async () => {
        const data = {
          user_id : userCredentials.auth_id,
          user_name : userCredentials.fullname,
          company_id : userCredentials.vendor_id,
          company_name : userCredentials.vendor_name,
          channel : 'VENDOR',
          created_at : userCredentials.created_at,
        }
        socket.emit('login', data)
        socket.on('history', ({notif} : any) => {
          if(notif){ 
            setTotalNewMessage(chatUnRead(notif.data))
            setHistoryNotf(notif.data) 
          } 
        })
        socket.on('notif', (notif : any) => {
          setMessage(notif)
        })
      })
      return () => { socket.disconnect(); };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if(message.message !== undefined) {
        let array_message = [...historyNotif]
        array_message.unshift(message)
        setHistoryNotf(array_message)
        setTotalNewMessage(chatUnRead(array_message))
      }
      // eslint-disable-next-line
    }, [message]);


    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const replaceNotifRead = () => {
      socket.on('notif-update', (notif : any) => {
        const notif_array = [...historyNotif]
        const target = notif_array.find((obj:any) => obj._id === notif._id);
        const source = {...notif};
        Object.assign(target, source);
        const filter : any = notif_array.filter((data:any) => !data.read_by)
        setTotalNewMessage(filter)
        setHistoryNotf(notif_array)
      })
    }

    const onClickReadMessage = (row: any) => {
      socket.emit('read_notif', [`${row._id}`])
      replaceNotifRead()
      
      switch(row.category) {
        case "Orders": { 
          switch(row.status) { 
            case "Waiting Down Payment" : {
              history.push({
                pathname: "/dashboard/orders",
                search: `?status=Waiting DP`, 
                state : {
                  payload : row.payload
                }
              })
              break;
            }
            case "Active" : {
              history.push({
                pathname: "/dashboard/orders",
                search: `?status=New`, 
                state : {
                  payload : row.payload
                }
              })
              break;
            }
            case "Pick" : {
              history.push({
                pathname: "/dashboard/orders",
                search: `?status=Pick`, 
                state : {
                  payload : row.payload
                }
              })
              break;
            }
            case "Delivered" : {
              history.push({
                pathname: "/dashboard/orders",
                search: `?status=Delivered`, 
                state : {
                  payload : row.payload
                }
              })
              break;
            }
            case "Cancel" : {
              history.push({
                pathname: "/dashboard/orders",
                search: `?status=Cancel`, 
                state : {
                  payload : row.payload
                }
              })
              break;
            }
          }
          break;
        }
        case "Invoice": {
          setOpenBackdrop(true);
          setTimeout(async() => {
            try {
              const res : any = await dispatch(getDataInvoiceByCode(row.payload.invoiceNumber))
              if(res.payload.data.length) {
                handleCloseBackdrop()
                const invoice = res.payload.data[0]
                let data_invoice : any = {
                  invoice_number : invoice.invoice_number,
                  items : invoice.items,
                  reference_document : invoice.reference_document,
                  buyer : invoice.buyer,
                  vendor : invoice.vendor,
                  down_payment: invoice.down_payment,
                  status : invoice.lastStatus,
                  direct_payment : invoice.direct_payment,
                  infoPayment : invoice.infoPayment && invoice.lastStatus !== "Paid" ? invoice.infoPayment : [],
                  from : 'notification'
                }
                history.push({
                  pathname: '/dashboard/finance/invoice',
                  search: `?page=summary`, 
                  state : {
                      data_invoice,
                      name : 'detail',
                  }
                })
              } else {
                swal("Error!", `Invoice not found!`, 'error')
                handleCloseBackdrop()
              }
            } catch (error) {
              swal("Error!", `${error}`, 'error')
              handleCloseBackdrop()
            }
          }, 500);
          break;
        }
        case "Product": {
          if(row.link_url !== "") {
            window.location.href = `${row.link_url}`
          }
          break;
        }
        case "Report": {
          if(row.link_url !== "") {
            window.location.href = `${row.link_url}`
          }
          break;
        }
        case "Algolia": {
          switch(row.status) { 
            case "Success" : {
              history.push({
                pathname : `/dashboard/products`,
                search: `?page=detail`, 
                state : {
                    product : null,
                    slug : `${row.payload.slug}`,
                }
              })
              break;
            }
          }
          break;
        }
        default: {
          break;
        }
      }      
      handleClose()
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onClickAllNotification = () => {
      handleClose()
      history.push({ 
          pathname: "/dashboard",
          search: `?page=notification`
      })
    }

  return (
    <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
          onClick={handleCloseBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack flexDirection="row">
          <Box mr={2} sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <Badge 
              badgeContent={totalNewMessage.length === 0 ? "0" : totalNewMessage.length} 
              color={totalNewMessage.length === 0 ? "primary" : "error"}
            >
              <NotificationsIcon />
            </Badge>
          </Box>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ 
            mt : 3 
          }}
        >
          <Stack sx={{ width: 450, fontSize: '13px' }}>
            { historyNotif.length === 0 ? 
              <Box p={3}>
                You dont have any new notification yet.
              </Box>
            : 
            <Box>
                <Stack 
                  flexDirection="column"
                  sx={{
                    maxHeight: 500,
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                  }}
                >
                  { historyNotif.slice(0,15).map((ele : any, i :any) => (
                    <Box key={i} onClick={() => onClickReadMessage(ele)}>
                      <Box p={2} sx={{ backgroundColor: ele.read_by && ele.read_by[userCredentials.auth_id] ? '#fff' : '#b3e5fc', cursor: 'pointer' }}>
                        <Box>{ele.message}</Box>
                        <Stack pt={1} direction="row" alignItems="center">
                            <Box pr={1}><Chip label={ele.category ? ele.category : "Broadcast"} variant='outlined' size="small" /></Box>
                            <Box fontWeight={600} fontSize={13} >{moment(ele.createdAt).fromNow()}</Box>
                        </Stack>
                      </Box>
                      <Box>
                        <Divider sx={{ border: '1px solid #ccc'}} />
                      </Box>
                    </Box>
                  )) }
                </Stack>
                <Stack 
                  flexDirection="row" justifyContent="center" 
                  sx={{backgroundColor: '#e8e6f5', cursor: 'pointer'}}
                  onClick={onClickAllNotification}
                >
                  <Box p={2} >
                    <Box color="#000" fontWeight={500}>View All Notifications</Box>
                  </Box>
                </Stack>
            </Box>
            }
          </Stack>
        </Popover>
    </div>
  )
}

export default NotificationBell