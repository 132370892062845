import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import {  getAllWarehouse, postWarehouse, updateWarehouse } from './reducers/warehouseReducers';

const initialState: any = {
  data: [], 
  loading : false,
  warehouse_post : false,
  loading_warehouse_post : false,
  warehouse_update : false,
  loading_warehouse_update : false,
};

export const stepRegisterVendor = createSlice({
  name: 'teams',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all warehouse
      .addCase(getAllWarehouse.pending, (state) => {
        state.loading = true;
        state.warehouse_post = false;
        state.warehouse_update = false;
      })
      .addCase(getAllWarehouse.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllWarehouse.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post warehouse
      .addCase(postWarehouse.pending, (state) => {
        state.loading_warehouse_post = true;
      })
      .addCase(postWarehouse.fulfilled, (state, action:any) => {
        state.loading_warehouse_post = false;
        state.warehouse_post = action.payload.data;
      })
      .addCase(postWarehouse.rejected, (state, action : any) => {
        state.loading_warehouse_post = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // update warehouse
      .addCase(updateWarehouse.pending, (state) => {
        state.loading_warehouse_update = true;
      })
      .addCase(updateWarehouse.fulfilled, (state, action:any) => {
        state.loading_warehouse_update = false;
        state.warehouse_update = action.payload.data;
      })
      .addCase(updateWarehouse.rejected, (state, action : any) => {
        state.loading_warehouse_update = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});


export default stepRegisterVendor.reducer;
