import { Box, LinearProgress } from '@mui/material'

function LoaderProgress() {
  return (
    <Box pt={2} pb={2}>
        <Box pb={1}>Please wait a second, we are getting your data ...</Box>
        <LinearProgress color="primary" />
    </Box>
  )
}

export default LoaderProgress